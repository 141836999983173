import {
    getUpcomingEventCopy,
    getFollowersCopy,
    defaultDataPointText,
} from '../constants';

const UPCOMING_EVENTS_THRESHOLD = 0;
const FOLLOWERS_THRESHOLD = 4;

const shouldShowDataPoint = (
    customDataPoint,
    numUpcomingEvents,
    numFollowers,
    shouldHideDefaultDataPointMessage = false,
) => {
    const hasCustomDataPoint = !!customDataPoint;
    const hasValidNumUpcomingEvents =
        !!numUpcomingEvents && numUpcomingEvents > UPCOMING_EVENTS_THRESHOLD;
    const hasValidNumFollowers =
        !!numFollowers && numFollowers > FOLLOWERS_THRESHOLD;

    return (
        hasCustomDataPoint ||
        hasValidNumUpcomingEvents ||
        hasValidNumFollowers ||
        !shouldHideDefaultDataPointMessage
    );
};

const getDataPointText = (customDataPoint, numUpcomingEvents, numFollowers) => {
    const dataPointText =
        customDataPoint ||
        (numUpcomingEvents &&
            numUpcomingEvents > UPCOMING_EVENTS_THRESHOLD &&
            getUpcomingEventCopy(numUpcomingEvents)) ||
        (numFollowers &&
            numFollowers > FOLLOWERS_THRESHOLD &&
            getFollowersCopy(numFollowers));

    return dataPointText ? dataPointText : defaultDataPointText.toString();
};

export default {
    shouldShowDataPoint,
    getDataPointText,
};
