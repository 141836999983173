import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';
import { render } from 'react-dom';

import { trackEventFromState } from '@eb/site-analytics';
import '@eb/eds-css/src/eds.scss';
import { HAS_WINDOW } from '@eb/feature-detection';
import logger from '@eb/logger-bugsnag';

import {
    createFollowSharedState,
    FollowMarionette,
    getInitialSharedState,
} from '@eb/follow-organizer-ebui';

const fakeContext = {
    isAuthenticated: window.location.search.indexOf('auth') > 0,
    isGDPRCountry: true,
    userId: '224447613814',
    organizers: ['4'],
    organizerData: {
        '4': {
            id: '4',
            name: 'GrowBrite',
            url: 'http://www.eventbrite.com/o/ORGANIZERProfileURL',
            imageUrl:
                'https://cdn.evbuc.com/images/60224346/157307626896/2/logo.png',
            followedByYou: false,
            numUpcomingEvents: 2,
            customDataPoint: 'test message',
        },
    },
    gaCategory: 'listing',
    gaSettings: {
        guestPartnerId: '123',
        userPartnerId: '456',
        correlationId: '789',
        isActive: true,
        activeScopeUserId: '123',
    },
    loginOptions: {
        // shouldOpenNewTab: true,
        // shouldUseSigninConfirmationRedirect: true,
        // referrer: '/o/23234234/',
    },
};
const followOrganizerContainerClassName = 'js-follow-organizer__container';
const followOrganizerContainerId = 'followOrganizerButtonContainer';
const DEFAULT_BUTTON_STYLE = 'standard';
const DEFAULT_BUTTON_SIZE = 'standard';
const DEFAULT_CARD_SIZE = 'standard';
const DEFAULT_LIST_STYLE = 'grid';

const context = window.__FOLLOW_ORGANIZER_PROPS__ || fakeContext;

class DOMDataFormatError extends Error {
    constructor(message) {
        super(message);
        this.name = 'Follow Organizer: Wrong DOM data format';
        this.message = message;
    }
}

/**
 * Tries to parse a JSON string, returning false if it fails to do it.
 * @param  {String} string          String to parse
 * @return {Object | false}         The parsed object or false if it wasn't parseable
 */
const secureJSONParse = (string) => {
    try {
        const parsedString = JSON.parse(string);

        if (parsedString && typeof parsedString === 'object') {
            return parsedString;
        }
    } catch (err) {
        throw new DOMDataFormatError(`Failed to parse ${string}`);
    }

    return false;
};
const isNotNull = (x) => x !== null;

const renderFollowButtonOnContainer = (
    container,
    props,
    { getState, setState, onChange },
) => {
    const trackGAEvent = (gaProperties) =>
        trackEventFromState({ gaSettings: props.gaSettings }, gaProperties);

    if (container && typeof container === 'object') {
        const {
            style = DEFAULT_BUTTON_STYLE,
            listStyle = DEFAULT_LIST_STYLE,
            size = DEFAULT_BUTTON_SIZE,
            cardSize = DEFAULT_CARD_SIZE,
            gaActionSuffix = '',
            organizerIds = JSON.stringify(props.organizers),
        } = container.dataset;
        const formmatedOrganizerIds = secureJSONParse(organizerIds);

        if (formmatedOrganizerIds) {
            render(
                <FollowMarionette
                    trackGAEvent={trackGAEvent}
                    gaActionSuffix={gaActionSuffix}
                    style={style}
                    listStyle={listStyle}
                    size={size}
                    cardSize={cardSize}
                    getFollowState={getState}
                    setFollowState={setState}
                    onChange={onChange}
                    {...props}
                    organizers={formmatedOrganizerIds}
                />,
                container,
            );
        }
    }
};

const getFollowOrganizerButtonContainers = () => {
    const multipleContainers = document.getElementsByClassName(
        followOrganizerContainerClassName,
    );
    const singleContainer = document.getElementById(followOrganizerContainerId);

    return multipleContainers.length
        ? Array.from(multipleContainers)
        : [singleContainer];
};

const renderButtons = (context, sharedState) => {
    const containers = getFollowOrganizerButtonContainers();
    const hasValidContainers = containers.some(isNotNull);

    if (hasValidContainers) {
        containers.forEach((domContainer) => {
            renderFollowButtonOnContainer(domContainer, context, sharedState);
        });
    }
};

const initFollowOrganizer = (context, sharedState) => {
    const renderButtonsBound = () => {
        renderButtons(context, sharedState);
    };

    renderButtonsBound();

    // Listens for document event for programmatically triggered renders
    document.addEventListener(
        'followOrganizer:render',
        renderButtonsBound,
        false,
    );

    // Exposes a global method to programmatically trigger follow organizer renders
    if (HAS_WINDOW) {
        window.renderFollowOrganizer = () => {
            renderButtonsBound();
        };
    }
};

try {
    initFollowOrganizer(
        context,
        createFollowSharedState(getInitialSharedState(context)),
    );
} catch (err) {
    logger.error(err);
}
