import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { formatUrl } from 'url-lib';
import Cookies from 'cookies-js';

import { getUrlPath } from '@eb/path-utils';
import { setWindowLocation } from '@eb/http';

import { Button } from '@eb/eds-button';
import { STYLE_FILL } from '@eb/eds-button';
import { Avatar } from '@eb/eds-avatar';
import { Modal } from '@eb/eds-modal';

import withLoginModalControls from '../HOC/withLoginModalControls';

import {
    ORGANIZER_COOKIE,
    LOGIN_MODAL_REJECT_GA_ACTION,
    LOGIN_MODAL_ACCEPT_GA_ACTION,
    LOGIN_MODAL_CATEGORY,
    FOLLOW_LOGIN_MODAL_SUBTITLE_TEXT,
    GET_STARTED_TEXT,
    followLoginModalTitleText,
} from '../constants';
import RandomSuperCrop from './RandomSuperCrop';

const ModalContent = ({
    onGetStarted,
    organizerName,
    organizerProfilePicture,
    organizerId,
}) => (
    <section className="eds-g-cell eds-g-cell-1-1 eds-g-cell--has-overflow">
        <div className="eds-align--center-horizontal eds-l-mar-top-8">
            <Avatar
                imageUrl={organizerProfilePicture}
                iconType={<RandomSuperCrop id={organizerId} />}
                backgroundColor="grey-100"
            />
        </div>
        <div className="eds-align--center-horizontal eds-l-mar-top-8">
            <span className="eds-text-hs">
                {followLoginModalTitleText(organizerName)}
            </span>
        </div>
        <div className="eds-align--center-horizontal eds-l-mar-top-8">
            <span>{FOLLOW_LOGIN_MODAL_SUBTITLE_TEXT}</span>
        </div>
        <div className="eds-align--center-horizontal eds-l-mar-top-8">
            <Button style={STYLE_FILL} onClick={onGetStarted}>
                {GET_STARTED_TEXT}
            </Button>
        </div>
    </section>
);

class FollowLoginModal extends Component {
    static propTypes = {
        organizerId: PropTypes.string.isRequired,
        organizerName: PropTypes.string.isRequired,
        onCloseModal: PropTypes.func.isRequired,
        trackGAEvent: PropTypes.func.isRequired,
        organizerProfilePicture: PropTypes.string.isRequired,
        isModalShown: PropTypes.bool.isRequired,
        referrer: PropTypes.string,
        shouldOpenNewTab: PropTypes.bool,
    };

    static defaultProps = {
        referrer: getUrlPath() || '',
        shouldOpenNewTab: false,
    };

    _handleOnGetStarted = () => {
        this.props.trackGAEvent({
            action: LOGIN_MODAL_ACCEPT_GA_ACTION,
            category: LOGIN_MODAL_CATEGORY,
            label: this.props.organizerName,
        });
        this._saveOrganizerInfoInCookie();
        this._redirectToSignIn();
    };

    _redirectToSignIn = () => {
        const { referrer, shouldOpenNewTab } = this.props;
        // then redirect to sign-in page, stld activates the "sticky TLD" feature
        // of the sign-in flow that will not redirect to the user's preferred TLD.
        const formattedUrl = formatUrl('/signin/', { referrer, stld: 1 });

        if (shouldOpenNewTab) {
            return window.open(formattedUrl);
        }

        return setWindowLocation(formattedUrl);
    };

    _saveOrganizerInfoInCookie = () => {
        const { organizerId } = this.props;

        Cookies.set(ORGANIZER_COOKIE, organizerId, {
            path: getUrlPath(),
            secure: true,
            expires: 300,
        });
    };

    _handleOnCloseModal = () => {
        this.props.trackGAEvent({
            action: LOGIN_MODAL_REJECT_GA_ACTION,
            category: LOGIN_MODAL_CATEGORY,
            label: this.props.organizerName,
        });
        this.props.onCloseModal();
    };

    render() {
        const {
            organizerName,
            organizerProfilePicture,
            isModalShown,
            organizerId,
        } = this.props;

        return (
            <Modal isShown={isModalShown} onClose={this._handleOnCloseModal}>
                <ModalContent
                    onGetStarted={this._handleOnGetStarted}
                    organizerName={organizerName}
                    organizerProfilePicture={organizerProfilePicture}
                    organizerId={organizerId}
                />
            </Modal>
        );
    }
}

export default withLoginModalControls(FollowLoginModal);
