import { Schema } from 'normalizr';

import { transformUtil } from '@eb/transformation-utils';
import { sdkRequest } from '@eb/http';
import { formatUrl } from 'url-lib';

const ORGANIZERS_URL = '/api/v3/organizers/';
const organizerUrl = (organizerId) =>
    `/api/v3/destination/organizers/${organizerId}/`;
const followOrganizerUrl = (organizerId) =>
    `/api/v3/destination/organizers/${organizerId}/follow/`;
const unfollowOrganizerUrl = (organizerId) =>
    `/api/v3/destination/organizers/${organizerId}/unfollow/`;

const _validateFollowed = (response) => {
    if (response.followedByYou) {
        return response;
    }

    return Promise.reject();
};

const _validateUnfollowed = (response) => {
    if (!response.followedByYou) {
        return response;
    }

    return Promise.reject();
};

const _transformResponse = (response) => {
    const schema = new Schema('results');

    const transformedContent = transformUtil({
        response,
        schema,
    });

    return transformedContent.entities.results[transformedContent.result];
};

export const followOrganizer = (organizerId) =>
    sdkRequest(followOrganizerUrl(organizerId), { method: 'POST' })
        .then(_transformResponse)
        .then(_validateFollowed);

export const unfollowOrganizer = (organizerId) =>
    sdkRequest(unfollowOrganizerUrl(organizerId), { method: 'POST' })
        .then(_transformResponse)
        .then(_validateUnfollowed);

export const getOrganizer = (organizerId) =>
    sdkRequest(
        formatUrl(organizerUrl(organizerId), {
            'expand.destination_profile': 'image',
        }),
    ).then(_transformResponse);

export const getOrganizers = (organizerIds) =>
    sdkRequest(
        formatUrl(ORGANIZERS_URL, {
            ids: organizerIds,
            'expand.organizer': 'follow_status',
        }),
    );
