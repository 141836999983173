export const FOLLOW_ORGANIZER_ACTION = 'FollowOrganizer';
export const FOLLOW_ORGANIZER_ATTEMPT_ACTION = 'FollowAttempt';
export const DISMISS_ORGANIZER_ACTION = 'OrganizerProfileCardDismissed';
export const UNFOLLOW_ORGANIZER_ACTION = 'UnfollowOrganizer';
export const VIEW_ORGANIZER_ACTION = 'OrganizerProfileCardClicked';
export const SHOW_GDPR_MODAL = 'GDPRModalShown';
export const GDPR_ACCEPT_GA_ACTION = 'FollowOrganizerGDPRAccept';
export const GDPR_REJECT_GA_ACTION = 'FollowOrganizerGDPRReject';
export const LOGIN_MODAL_REJECT_GA_ACTION = 'AbandonFollow';
export const LOGIN_MODAL_ACCEPT_GA_ACTION = 'GetStarted';
export const LOGIN_MODAL_CATEGORY = 'follow-prompt-modal';

export const actionToGAActionMap = {
    followAttempt: FOLLOW_ORGANIZER_ATTEMPT_ACTION,
    unfollow: UNFOLLOW_ORGANIZER_ACTION,
    follow: FOLLOW_ORGANIZER_ACTION,
    gdprModal: SHOW_GDPR_MODAL,
    dismissOrganizer: DISMISS_ORGANIZER_ACTION,
    viewOrganizer: VIEW_ORGANIZER_ACTION,
};
