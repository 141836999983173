import values from 'lodash/values';
import get from 'lodash/get';

import { createSelector } from '../utils';

const _getCollectionImageUrl = (collection) => {
    let imageUrl;

    imageUrl = get(collection, ['image', 'url']);

    if ('imageLarge' in collection) {
        imageUrl = get(collection, ['imageLarge', 'url']);
    }

    return imageUrl;
};

const getCollections = ({ collections }) => collections;

const getCollectionEntities = ({ entities }) => values(entities).reverse();

const getMyCollections = (collections) =>
    collections.filter(({ canEdit }) => canEdit);

const getFollowedCollections = (collections) =>
    collections.filter(({ followStatus = {} }) => !!followStatus.followedByYou);

const formatCollections = (collections) =>
    collections.map((collection = {}) => ({
        name: collection.name,
        eventCount: collection.eventCount,
        id: collection.id,
        href: `/c/${collection.id}`,
        imageUrl: _getCollectionImageUrl(collection),
        followedByYou: get(
            collection,
            ['followStatus', 'followedByYou'],
            false,
        ),
    }));

export const getMyFormattedCollection = (state) =>
    createSelector(
        getCollections,
        getCollectionEntities,
        getMyCollections,
        formatCollections,
    )(state);

export const getFollowedFormattedCollections = (state) =>
    createSelector(
        getCollections,
        getCollectionEntities,
        getFollowedCollections,
        formatCollections,
    )(state);

export const getFormattedCollections = (state) =>
    createSelector(
        getCollections,
        getCollectionEntities,
        formatCollections,
    )(state);
