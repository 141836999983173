import PropTypes from 'prop-types';
import _ from 'lodash';
import { COLORS_PROP_TYPE } from '@eb/eds-color';
import { SVG_PROP_TYPE } from '@eb/eds-vector-image';

import { SIZES_JSON } from '@eb/eds-iconography';

import { Check } from '@eb/eds-iconography';
import { CheckChunky } from '@eb/eds-iconography';

export const SIZES = _.chain(Object.keys(SIZES_JSON))
    .filter((size) => size.startsWith('icon-') && !size.includes('halo'))
    .map((size) => size.replace('icon-', ''))
    .value();

export const ICON_TYPE_PROP_TYPE = SVG_PROP_TYPE;
export const ICON_SIZE_PROP_TYPE = PropTypes.oneOf(SIZES);
export const COLOR_PROP_TYPE = COLORS_PROP_TYPE;

export const STROKE_COLOR_ICONS = [Check, CheckChunky];
