import {
    saveEvent as saveEventApi,
    unsaveEvent as unsaveEventApi,
} from './api';

import {
    SET_EVENT_SAVED_STATE,
    VIEW_EVENT,
    WILL_SHARE_EVENT,
    DID_SHARE_EVENT,
    SET_EVENTS,
} from './actionTypes';

import {
    BOOKMARK_GA_ACTION,
    UNBOOKMARK_GA_ACTION,
    VIEW_EVENT_GA_ACTION,
    SHARE_ATTEMPT_GA_ACTION,
    SHARE_GA_ACTION,
    SHARE_TYPE_GA_MAP,
} from '../../constants';

const trackViewEvent = (category) => (eventId) => ({
    type: VIEW_EVENT,
    payload: {},
    meta: {
        analytics: {
            category,
            action: VIEW_EVENT_GA_ACTION,
            label: eventId,
        },
    },
});
const trackWillShareEvent = (category) => () => ({
    type: WILL_SHARE_EVENT,
    payload: {},
    meta: {
        analytics: {
            category,
            action: SHARE_ATTEMPT_GA_ACTION,
        },
    },
});

const trackDidShareEvent = (category) => (shareType) => ({
    type: DID_SHARE_EVENT,
    payload: {},
    meta: {
        analytics: {
            category,
            action: SHARE_GA_ACTION,
            label: SHARE_TYPE_GA_MAP[shareType],
        },
    },
});

export const setEvents = (data) => ({
    type: SET_EVENTS,
    payload: data,
});

export const setLikeState = (eventId, saveState) => ({
    type: SET_EVENT_SAVED_STATE,
    payload: {
        eventId,
        saveState,
    },
});

export const setLikeStateWithTracking = (eventId, saveState, category) => ({
    type: SET_EVENT_SAVED_STATE,
    payload: {
        eventId,
        saveState,
    },
    meta: {
        analytics: {
            category,
            action: saveState ? BOOKMARK_GA_ACTION : UNBOOKMARK_GA_ACTION,
        },
    },
});

export const clickOnLikeToggle = (category) => (eventId, shouldLike) => (
    dispatch,
) => {
    let likeApiCall = unsaveEventApi;

    if (shouldLike) {
        likeApiCall = saveEventApi;
    }

    let action = setLikeStateWithTracking;

    if (!category) {
        action = setLikeState;
    }

    return likeApiCall(eventId).then(() =>
        dispatch(action(eventId, shouldLike, category)),
    );
};

export const clickOnViewEvent = trackViewEvent;
export const clickOnWillShareEvent = trackWillShareEvent;
export const clickOnDidShareEvent = trackDidShareEvent;
