import * as React from 'react';

const ClockSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 24 24" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 3c5 0 9 4 9 9s-4 9-9 9-9-4-9-9 4-9 9-9m0-1C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2"
        />
        <path fillRule="evenodd" clipRule="evenodd" d="M11.5 12.5V5h1v7.5" />
        <path fillRule="evenodd" clipRule="evenodd" d="M11.5 11.5H17v1h-5.5" />
    </svg>
);

ClockSvg.displayName = 'ClockSvg';
export default ClockSvg;
