import { HAS_WINDOW } from './has-window';

const MIN_DEVICE_MEDIA_QUERY =
    '(-webkit-min-device-pixel-ratio: 1.5), (min-device-pixel-ratio: 1.5), (min-resolution: 144dpi), (min-resolution: 1.5dppx)';

/**
 * Safely, determine if the device has a retina screen
 */
export const hasRetinaScreen = (): boolean =>
    HAS_WINDOW && window.matchMedia(MIN_DEVICE_MEDIA_QUERY).matches;
