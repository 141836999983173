import React from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import pick from 'lodash/pick';

import { VectorImage } from '@eb/eds-vector-image';

import { VECTOR_IMAGE_ILLUSTRATION } from '@eb/eds-vector-image';
import { ILLUSTRATION_TYPE_PROP_TYPE } from './constants';

export default class Illustration extends React.PureComponent {
    static propTypes = {
        // all props from VectorImage
        ...omit(VectorImage.propTypes, ['svg', 'type']),
        /**
         * The type of icon that you want to display e.g. camera
         */
        type: ILLUSTRATION_TYPE_PROP_TYPE.isRequired,
        /**
         * The specified width of the illustration
         */
        width: PropTypes.string.isRequired,
        /**
         * The specified height of the illustration
         */
        height: PropTypes.string.isRequired,
    };

    render() {
        const { type } = this.props;
        const vectorImageProps = pick(
            this.props,
            ...Object.keys(VectorImage.propTypes),
        );

        return (
            <VectorImage
                {...vectorImageProps}
                type={VECTOR_IMAGE_ILLUSTRATION}
                svg={type}
            />
        );
    }
}
