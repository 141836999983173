var interpolate = require('@eb/interpolate-lazy-string').default;
var getWindowObject = require('@eb/feature-detection').getWindowObject;

module.exports = function (msgid, interpolation) {
    var globalGettext = getWindowObject('gettext');

    // For the meantime in core, we want to use the gettext function
    // that's on window
    if (globalGettext) {
        return globalGettext(msgid, interpolation);
    }

    return interpolation
        ? interpolate(msgid, interpolation, !Array.isArray(interpolation))
        : msgid;
};
