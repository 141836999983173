import { isEmpty, noop } from 'lodash';
import { formatUrl } from 'url-lib';

import gettext from '@eb/gettext';

/*
Not a true selector, but can be used in conjunction with others to return
a list of event objects configured to be passed directly into the
EventCard as defined in EDS.

Example of usage can be found in the likes or following reducers
of the userProfile application

https://github.com/eventbrite/core/blob/master/js/src/userProfile/redux/likes/selectors.js#L38
*/

const getTicketAvailabilityData = (ticketAvailability) => {
    let isFree;

    let currency;

    let minPriceValue;

    if (ticketAvailability) {
        isFree = ticketAvailability.isFree;

        if (ticketAvailability.minimumTicketPrice) {
            const { minimumTicketPrice } = ticketAvailability;

            currency = minimumTicketPrice.currency;
            minPriceValue = minimumTicketPrice.value;
        }
    }

    return { isFree, currency, minPriceValue };
};

const getVenueData = (venue) => {
    let city;

    let venueName;

    if (venue) {
        venueName = venue.name;

        const { address } = venue;

        if (address) {
            city = address.city;
        }
    }

    return { city, venueName };
};

const getImageData = (image) => {
    let edgeColor;

    let imageUrl;

    if (image) {
        if (image.url) {
            imageUrl = image.url;
        }

        if (image.edgeColor) {
            edgeColor = image.edgeColor;
        }
    }

    return { edgeColor, imageUrl };
};

const getSeriesData = (series) => {
    let seriesUrl = null;

    let repeatingInstanceCount = null;

    if (series) {
        const {
            url,
            counts: { currentFuture },
        } = series;

        seriesUrl = url;
        //Because search only returns events in current_future,
        //the returned count will include the passed in event so we
        //need to subtract the event count by one
        repeatingInstanceCount = currentFuture - 1;
    }

    return { seriesUrl, repeatingInstanceCount };
};

const _getUrlWithTrackingParams = (url, affCode) =>
    formatUrl(url, {
        aff: affCode,
    });

/*
 * @param events - [{eventObject}, ...]
 *
 * @param currencyFormat - string
 *
 * @param shareOptions - {}
 *
 * @param affCode - string (app specific)
 *      - if passed in attached to url
 *
 * @param configureShareOptionsForApp - string
 *      - app specific function to configure shareOptions
 *        to the particular application
 *.     - eg. https://github.com/eventbrite/core/blob/master/js/src/userProfile/utils.js#L3
 *
 * Util that given the above parameters will return a list of events in the
 * expected shape for EDS event card
 */
export const formatEventDataForDisplay = ({
    events,
    currencyFormat = '',
    shareOptions = {},
    affCode = '',
    configureShareOptionsForApp = noop,
}) => {
    let parsedEvents = [];

    if (!isEmpty(events)) {
        parsedEvents = events.map(
            ({
                id,
                url,
                name,
                startDate,
                startTime,
                ticketsBy,
                image,
                ticketAvailability,
                primaryVenue,
                saves: { savedByYou } = {},
                eventCollections = [],
                customDescription,
                customTitle,
                customImageId,
                customImage,
                series,
                isOnlineEvent,
                timezone,
            }) => {
                let minPrice;

                let locationInfo;
                const { venueName, city } = getVenueData(primaryVenue);
                const {
                    isFree,
                    currency,
                    minPriceValue,
                } = getTicketAvailabilityData(ticketAvailability);
                const { imageUrl, edgeColor } = getImageData(image);
                const parsedShareOptions = configureShareOptionsForApp(
                    { url, id },
                    shareOptions,
                );

                if (currency && currencyFormat && minPriceValue) {
                    minPrice = {
                        currency,
                        currencyFormat,
                        minPriceValue,
                    };
                }

                if (venueName && city) {
                    //Translators: Slim's Cool Place, San Francisco
                    //Translators: Blondie's Bar, Los Angeles
                    locationInfo = gettext('%(venueName)s, %(city)s', {
                        venueName,
                        city,
                    });
                }

                const seriesData = getSeriesData(series);

                let { seriesUrl } = seriesData;

                const { repeatingInstanceCount } = seriesData;

                if (seriesUrl) {
                    seriesUrl = _getUrlWithTrackingParams(seriesUrl, affCode);
                }

                return {
                    id,
                    minPrice,
                    imageUrl,
                    edgeColor,
                    locationInfo,
                    startTime,
                    startDate,
                    isFree,
                    ticketsBy,
                    name,
                    savedByYou,
                    eventCollections,
                    shareOptions: parsedShareOptions,
                    url: _getUrlWithTrackingParams(url, affCode),
                    seriesUrl,
                    repeatingInstanceCount,
                    customDescription,
                    customTitle,
                    customImageId,
                    customImage,
                    isOnlineEvent,
                    timezone,
                };
            },
        );
    }

    return parsedEvents;
};
