var interpolate = require('@eb/interpolate-lazy-string').default;
var getWindowObject = require('@eb/feature-detection').getWindowObject;

module.exports = function (singular, plural, count, interpolation) {
    var globalNGettext = getWindowObject('ngettext'),
        translation;

    // For the meantime in core, we want to use the ngettext function
    // that's on window
    if (globalNGettext) {
        return globalNGettext(singular, plural, count, interpolation);
    }

    translation = Number(count) === 1 ? singular : plural;

    return interpolation
        ? interpolate(translation, interpolation, !Array.isArray(interpolation))
        : translation;
};
