import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import isPlainObject from 'lodash/isPlainObject';

const isValidString = (string) =>
    Boolean(string && string.trim && string.trim());

const validateRequiredAndCamel = (value, name) => {
    if (!isValidString(value) || value[0] !== value[0].toUpperCase()) {
        throw new Error(
            `${name} is required and has to be in CamelCase format.`,
        );
    }

    return value;
};

const validateIsOneOf = (value, name, values) => {
    if (values.indexOf(value) === -1) {
        throw new Error(`${name} should be one of: "${values.join('", "')}"`);
    }

    return value;
};

const validateProperty = (property, name) => {
    if (!isValidString(property.type)) {
        throw new Error(`${name}.type is required.`);
    }
    if (property.value == null) {
        throw new Error(`${name}.value is required.`);
    }

    validateIsOneOf(property.type, `${name}.type`, [
        'label',
        'metric',
        'dimension',
    ]);

    return property;
};

const mapProperties = (properties) => {
    if (isPlainObject(properties)) {
        Object.keys(properties).forEach((name) =>
            validateProperty(properties[name], name),
        );
    } else if (properties) {
        throw new Error('Properties must be a plain object.');
    }

    const { dimension, label, metric } = groupBy(
        mapValues(properties, (obj, key) => ({ name: key, ...obj })),
        'type',
    );

    const attributes = {};

    if (dimension) {
        attributes.dimensions = dimension.reduce((dimensions, dimension) => {
            // eslint-disable-next-line no-param-reassign
            dimensions[dimension.name] = dimension.value;
            return dimensions;
        }, {});
    }

    if (metric) {
        attributes.metrics = metric.reduce((metrics, metric) => {
            // eslint-disable-next-line no-param-reassign
            metrics[metric.name] = metric.value;
            return metrics;
        }, {});
    }

    if (label) {
        if (label.length > 1) {
            throw new Error('Can only have 1 property of type "label"');
        }
        attributes.label = label[0].value;
    }

    return attributes;
};

/**
 * The Analytics Model Schema helper is a function that allows to define
 * GA actions following EB's Object+Action+properties conventions.
 */
export const Analytics = {
    /**
     * Analytics.event()
     * Use to define a GA event by providing the object action and properties
     * following EB's Object+Action convention. Defined on:
     * https://docs.google.com/spreadsheets/d/1L8EDa1_i8iZyC6g_0ElvTXCoPiKfELTgl06qtbrJc-g/edit#gid=745728795
     *
     * IMPORTANT: This function does NOT perform the tracking, it's a utility to map
     * the Analytics model into a dispatchable GA custom event declaration.
     * You can pass what this function returns to any of our tracking functions like
     * the analytics redux middleware or trackAnalyticsEvent().
     *
     * @param {String} params.object The Object being tracked (Required)
     * @param {String} params.action The Action performed on the Object (Required)
     * @param {String} params.properties A map of property definitions to track
     * @param {String} params.properties[prop].type How this property is going to be tracked: "label", "dimension" or "metric". (Required if property defined)
     * @param {String} params.properties[prop].value The value to track for this property. (Required if property defined)
     */
    event: ({ object, action, properties }) => ({
        action: `${validateRequiredAndCamel(
            object,
            'object',
        )}${validateRequiredAndCamel(action, 'action')}`,
        ...mapProperties(properties),
    }),
};
