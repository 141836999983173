import React from 'react';
import classNames from 'classnames';

import { Button } from '@eb/eds-button';
import { SIZE_BLOCK, STYLE_LINK } from '@eb/eds-button';
import { Divider } from '@eb/eds-divider';
import { IconButton } from '@eb/eds-icon-button';
import { NotificationBar } from '@eb/eds-notification';

import { MODAL_BODY_PROP_TYPES, MODAL_TITLE_ID } from './constants';

const _getNotificationClasses = (notificationOptions) => {
    const {
        isClosing,
        shouldFloatAboveContent,
        shouldAnimate,
        //Modal default behavior has been
        //to display notifications full width
        //at all breakpoints. This continues
        //that pattern but allows for user
        //to configure otherwise. Particularly
        //when using a ComplexModal on full
        //screen experiences.
        fullWidth = true,
        ...prunedOptions
    } = notificationOptions;

    const classes = classNames('eds-modal__notification', {
        'eds-modal__notification--is-closing': isClosing,
        'eds-modal__notification--abs': shouldFloatAboveContent,
        'eds-modal__notification--animate': shouldAnimate,
        'eds-g-cell-md-10-12': !fullWidth,
        'eds-g-offset-md-1-12': !fullWidth,
        'eds-g-cell-12-12': fullWidth,
    });

    return { classes, prunedOptions };
};

const _getNotifications = (notificationOptions) => {
    let notifications = null;

    if (notificationOptions) {
        const notificationOptionsList = Array.isArray(notificationOptions)
            ? notificationOptions
            : [notificationOptions];

        notifications = notificationOptionsList.map((notificationOption) => {
            const { classes, prunedOptions } = _getNotificationClasses(
                notificationOption,
            );
            let key = notificationOption.children;

            if (
                notificationOption.children &&
                notificationOption.children.key
            ) {
                key = notificationOption.children.key;
            }

            return (
                <span className={classes} key={key}>
                    <NotificationBar {...prunedOptions} />
                </span>
            );
        });
    }

    return notifications;
};

const Header = ({
    headerActionIconType,
    headerActionTitle,
    onHeaderAction,
    title,
    secondaryTitle,
}) => {
    let component = null;

    if (title) {
        component = (
            <header className="eds-modal__header eds-align--center-vertical">
                <HeaderAction
                    headerActionIconType={headerActionIconType}
                    headerActionTitle={headerActionTitle}
                    onHeaderAction={onHeaderAction}
                />
                <TitleHeader title={title} secondaryTitle={secondaryTitle} />
            </header>
        );
    }

    return component;
};

const HeaderAction = ({
    headerActionIconType,
    headerActionTitle,
    onHeaderAction,
}) => {
    let component = null;

    if (headerActionIconType) {
        component = (
            <div className="eds-modal__secondary-header-action">
                <IconButton
                    data-automation="modal-header-action-button"
                    data-spec="secondary-header-action"
                    onClick={onHeaderAction}
                    iconType={headerActionIconType}
                    title={headerActionTitle}
                />
            </div>
        );
    }

    return component;
};

const TitleHeader = ({ title, secondaryTitle }) => {
    let component = null;
    let secondary = null;

    if (secondaryTitle) {
        secondary = <span className="eds-align--center">{secondaryTitle}</span>;
    }

    if (title) {
        component = (
            <div className="eds-modal__title-container eds-modal__title-spacing">
                <h2
                    id={MODAL_TITLE_ID}
                    className="eds-text-bl--fixed eds-text--truncate eds-modal__title eds-text-color--ui-800"
                    data-spec="eds-modal__title"
                >
                    {title}
                </h2>
                <span className="eds-text-bs--fixed eds-text-color--ui-600">
                    {secondary}
                </span>
                <div className="eds-modal__title-spacing">
                    <Divider />
                </div>
            </div>
        );
    }

    return component;
};

const Illustration = ({ illustration }) => {
    let component = null;

    if (illustration) {
        component = (
            <div className="eds-modal__illustration">{illustration}</div>
        );
    }

    return component;
};

const PrimaryButton = ({
    onPrimaryAction,
    primaryText,
    primaryType,
    primarySize,
    primaryIsLoading,
    primaryIsDisabled,
}) => {
    let component = null;
    const automationSelector = 'eds-modal__primary-button';

    if (primaryText) {
        component = (
            <Button
                onClick={onPrimaryAction}
                style={primaryType}
                disabled={primaryIsDisabled}
                data-spec={automationSelector}
                data-automation={automationSelector}
                isLoading={primaryIsLoading}
                size={primarySize}
            >
                {primaryText}
            </Button>
        );
    }

    return component;
};

const SecondaryButton = ({
    onSecondaryAction,
    secondaryText,
    secondaryType,
    secondaryIsDisabled,
}) => {
    let component = null;

    if (secondaryText) {
        component = (
            <div className="eds-l-mar-right-4">
                <Button
                    onClick={onSecondaryAction}
                    style={secondaryType}
                    disabled={secondaryIsDisabled}
                    data-spec="eds-modal__secondary-button"
                >
                    {secondaryText}
                </Button>
            </div>
        );
    }

    return component;
};

const TertiaryButton = ({ onTertiaryAction, tertiaryNode }) => {
    let component = null;

    if (tertiaryNode) {
        component = onTertiaryAction ? (
            <Button
                onClick={onTertiaryAction}
                style="none"
                __containerClassName="eds-modal__button-bar__tertiary"
            >
                {tertiaryNode}
            </Button>
        ) : (
            <div className="eds-modal__button-bar__tertiary">
                {tertiaryNode}
            </div>
        );
    }

    return component;
};

/**
 * When there is a message to display in the button bar. returns the requisite markup.
 * When there is no message, returns null.
 *
 * @param {String} message - If null or undefined, the component is null
 * @param {Boolean} hasBlockButton
 * @param {String[]} customClassNames- Custom class names to add to the root node of this component (optional, defaults to [])
 */
const ButtonBarMessage = ({
    message,
    hasBlockButton,
    customClassnames = [],
}) => {
    let component = null;
    let messageClasses;

    if (message) {
        messageClasses = classNames(
            'eds-modal__message eds-text-bs eds-text-color--ui-500',
            ...customClassnames,
            {
                'eds-modal__message--right': !hasBlockButton,
            },
        );

        component = <div className={messageClasses}>{message}</div>;
    }

    return component;
};

const ButtonBar = ({
    primaryType,
    primaryText,
    primaryIsDisabled,
    primaryIsLoading,
    primarySize,
    onPrimaryAction,
    secondaryType,
    secondaryText,
    secondaryIsDisabled,
    onSecondaryAction,
    title,
    tertiaryNode,
    onTertiaryAction,
    buttonBarMessage,
    shouldShowFooter,
    __footerContainerClassName,
}) => {
    let component = null;
    const hasBlockButton =
        primarySize === SIZE_BLOCK && !secondaryText && !tertiaryNode;

    const footerClasses = classNames(
        'eds-modal__footer',
        {
            'eds-modal__footer--block': hasBlockButton,
        },
        __footerContainerClassName,
    );

    const buttonBarClasses = classNames('eds-modal__button-bar', {
        'eds-modal__button-bar--with-title': title,
        'eds-modal__button-bar--with-tertiary': tertiaryNode,
    });

    const primarySecondaryClasses = classNames(
        'eds-modal__button-bar__primary-secondary',
        {
            'eds-align--right': !hasBlockButton,
            'eds-modal__button-bar--block': hasBlockButton,
        },
    );

    if (primaryText || shouldShowFooter) {
        component = (
            <div className="eds-modal__footer-background">
                <div className={footerClasses} data-spec="eds-modal__footer">
                    <ButtonBarMessage
                        customClassnames={[
                            'eds-show-down-sw',
                            'eds-l-pad-top-4',
                            'eds-align--center',
                        ]}
                        message={buttonBarMessage}
                    />
                    <nav className={buttonBarClasses}>
                        <div className={primarySecondaryClasses}>
                            <SecondaryButton
                                onSecondaryAction={onSecondaryAction}
                                secondaryText={secondaryText}
                                secondaryType={secondaryType}
                                secondaryIsDisabled={secondaryIsDisabled}
                            />
                            <PrimaryButton
                                onPrimaryAction={onPrimaryAction}
                                primaryText={primaryText}
                                primaryType={primaryType}
                                primarySize={primarySize}
                                primaryIsDisabled={primaryIsDisabled}
                                primaryIsLoading={primaryIsLoading}
                            />
                        </div>
                        <TertiaryButton
                            onTertiaryAction={onTertiaryAction}
                            tertiaryNode={tertiaryNode}
                        />
                        <ButtonBarMessage
                            customClassnames={[
                                'eds-align--center-vertical',
                                'eds-show-up-mn',
                            ]}
                            hasBlockButton={hasBlockButton}
                            message={buttonBarMessage}
                        />
                    </nav>
                </div>
            </div>
        );
    }

    return component;
};

export default class ModalBody extends React.PureComponent {
    static propTypes = MODAL_BODY_PROP_TYPES;

    static defaultProps = {
        primaryType: STYLE_LINK,
        secondaryType: STYLE_LINK,
    };

    render() {
        const {
            children,
            title,
            secondaryTitle,
            illustration,
            headerActionIconType,
            headerActionTitle,
            onHeaderAction,
            primaryType,
            primaryText,
            primarySize,
            primaryIsDisabled,
            primaryIsLoading,
            onPrimaryAction,
            secondaryType,
            secondaryText,
            secondaryIsDisabled,
            onSecondaryAction,
            tertiaryNode,
            notificationOptions,
            onTertiaryAction,
            buttonBarMessage,
            shouldShowFooter,
            __footerContainerClassName,
        } = this.props;

        const notifications = _getNotifications(notificationOptions);

        const mainClasses = classNames('eds-modal__main', {
            'eds-modal__main--row': illustration,
        });

        return (
            <div className="eds-modal__container">
                <Header
                    headerActionIconType={headerActionIconType}
                    headerActionTitle={headerActionTitle}
                    onHeaderAction={onHeaderAction}
                    title={title}
                    secondaryTitle={secondaryTitle}
                />
                <main className={mainClasses} data-spec="eds-modal__main">
                    <Illustration illustration={illustration} />
                    <div className="eds-modal__content">
                        {notifications}
                        <div className="eds-modal__content__children">
                            {children}
                        </div>
                        <ButtonBar
                            primaryType={primaryType}
                            primaryText={primaryText}
                            primarySize={primarySize}
                            primaryIsDisabled={primaryIsDisabled}
                            onPrimaryAction={onPrimaryAction}
                            secondaryType={secondaryType}
                            secondaryText={secondaryText}
                            secondaryIsDisabled={secondaryIsDisabled}
                            onSecondaryAction={onSecondaryAction}
                            title={title}
                            primaryIsLoading={primaryIsLoading}
                            tertiaryNode={tertiaryNode}
                            onTertiaryAction={onTertiaryAction}
                            buttonBarMessage={buttonBarMessage}
                            shouldShowFooter={shouldShowFooter}
                            __footerContainerClassName={
                                __footerContainerClassName
                            }
                        />
                    </div>
                </main>
            </div>
        );
    }
}
