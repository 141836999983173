import PropTypes from 'prop-types';
import { SVG_PROP_TYPE } from '@eb/eds-vector-image';

export const STYLE_LINK = 'link';
export const STYLE_NEUTRAL = 'neutral';
// 'dark' is deprecated in favour of 'neutral' for consistent naming with `<Button>`
export const STYLE_DARK = 'dark';
export const STYLE_INVERSE = 'inverse';
export const STYLE_BRAND = 'brand';
export const STYLE_OUTLINE = 'outline';
export const STYLES = [
    STYLE_NEUTRAL,
    STYLE_DARK,
    STYLE_LINK,
    STYLE_INVERSE,
    STYLE_BRAND,
    STYLE_OUTLINE,
];

export const STYLE_PROP_TYPE = PropTypes.oneOf(STYLES);
export const ICON_PROP_TYPE = SVG_PROP_TYPE;
