// TODO: Move this into it's own package or add to infra/redux-image-field
import { HAS_FORM_DATA } from '@eb/feature-detection';

import { fetchJSON, fetchEB } from './fetch';
import { httpWithUploadProgress } from './upload';
import { objectToFormData } from './form';

/**
 * These are the types available in our APIv3 media endpoint.
 * core/django/src/www/eventbrite/ebapi/views/media.py
 * core/django/src/www/eventbrite/ebapi_service/actions/media.py
 */
export const IMAGE_TYPE_EVENT_LOGO = 'image-event-logo';
export const IMAGE_TYPE_ORG_LOGO = 'image-organizer-logo';
export const IMAGE_TYPE_USER_PHOTO = 'image-user-photo';
export const IMAGE_TYPE_STRUCTURED_CONTENT = 'image-structured-content';
export const IMAGE_TYPES = [
    IMAGE_TYPE_EVENT_LOGO,
    IMAGE_TYPE_ORG_LOGO,
    IMAGE_TYPE_USER_PHOTO,
    IMAGE_TYPE_STRUCTURED_CONTENT,
];

const BASE_MEDIA_URL = '/api/v3/media/';
const UPLOAD_URL = `${BASE_MEDIA_URL}upload/`;

/**
 * Initiates image upload and receives necessary credentials
 *
 * @param type - the image type that is being uploaded
 * @returns Promise
 */
const startImageUpload = (type) => fetchJSON(`${UPLOAD_URL}?type=${type}`);

/**
 * Combines file object into necessary credential form values to upload.
 *
 * @param {string} url - the URL to which it should upload
 * @param {object} uploadData - a plain object with necessary form values
 * @param {File} file - a File object taken from a drop or browse event
 * @param {(percentLoaded: number) => void} onProgress - function that will be invoked on each progress event
 * @returns Promise<string>
 */
const transportFile = (url, uploadData, file, onProgress) =>
    httpWithUploadProgress(
        url,
        objectToFormData({ ...uploadData, file }),
        onProgress,
    );

/**
 * Finalizes image upload which creates a local image record
 *
 * @param {string} uploadToken - identifying token received when image upload is started
 * @param {object} apiCropMask
 * @returns {Promise<Response>}
 */
const finishImageUpload = (uploadToken, apiCropMask) =>
    fetchEB(UPLOAD_URL, {
        method: 'POST',

        body: JSON.stringify({
            upload_token: uploadToken,
            ...apiCropMask,
        }),
    }).then((response) => response.json());

/**
 * Given a file object and type, upload an image via our API
 *
 * @param {File} file - a File object taken from a drop or browse event
 * @param {'image-event-logo' | 'image-organizer-logo' | 'image-user-photo' | 'image-structured-content'} type - the image type that is being uploaded
 * @param {(percentLoaded: number) => void} onProgress - function that will be invoked on each progress event
 * @param {object} apiCropMask
 * @returns {Promise<Response>}
 */
export const uploadImageFile = (file, type, onProgress, apiCropMask) => {
    if (!HAS_FORM_DATA) {
        return Promise.reject(
            'Unable to uploadImageFile in environment without FormData',
        );
    }

    return startImageUpload(type)
        .then((response) =>
            transportFile(
                response['upload_url'],
                response['upload_data'],
                file,
                onProgress,
            ).then(() => response['upload_token']),
        )
        .then((token) => finishImageUpload(token, apiCropMask));
};

/**
 * Update given image ID with given crop mask.  This will result in a new image record
 * with the new crop mask applied.
 *
 * @param {string} imageId - id of the image to update
 * @param {object} apiCropMask - a crop mask formatted correctly for API interaction
 *      {top_left: {x,y}, width, height}
 */
export const updateImage = (imageId, apiCropMask) =>
    fetchJSON(`${BASE_MEDIA_URL}${imageId}/`, {
        method: 'POST',
        body: JSON.stringify(apiCropMask),
    });

/**
 * Transform a crop mask object to parameters formatted for an API request
 *
 * @param {object} cropMask - An object with x, y, width, height
 * @param {number} cropMask.x
 * @param {number} cropMask.y
 * @param {number} cropMask.width
 * @param {number} cropMask.height
 * @returns {{'crop_mask.top_left.x': number, 'crop_mask.top_left.y': number, 'crop_mask.width': number, 'crop_mask.height': number}}
 */
export const transformToApiCropMask = ({ x, y, width, height }) => ({
    'crop_mask.top_left.x': x,
    'crop_mask.top_left.y': y,
    'crop_mask.width': width,
    'crop_mask.height': height,
});
