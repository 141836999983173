import gettext from '@eb/gettext';
import { lazyGettext } from '@eb/lazy-gettext';
import ngettext from '@eb/ngettext';

/* Card and Button */
export const viewOrganizerProfileString = (organizerName) =>
    gettext('View %(organizerName)s profile', { organizerName });
export const organizerImageAltText = (organizerName) =>
    gettext('%(organizerName)s profile image.', { organizerName });

export const getUpcomingEventCopy = (numUpcomingEvents) =>
    `${numUpcomingEvents} ${ngettext(
        'upcoming event',
        'upcoming events',
        numUpcomingEvents,
    )}`;
export const getFollowersCopy = (numFollowers) =>
    `${numFollowers} ${ngettext('follower', 'followers', numFollowers)}`;
export const defaultDataPointText = lazyGettext('Created this event');

export const FOLLOWING_TEXT = lazyGettext('Following');
export const FOLLOW_TEXT = lazyGettext('Following');
export const REMOVE_ORGANIZER_CARD_TEXT = lazyGettext(
    'Remove this organizer card',
);

/* GDPR Modal */
export const GDPR_TITLE_TEXT = lazyGettext('Get alerts for new events');
export const GDPR_SUBTITLE_TEXT = lazyGettext(
    'By clicking okay, you are agreeing to receive marketing emails and notifications about creators you follow so that you don’t miss out.',
);
export const ACCEPT_TEXT = lazyGettext('Okay');
export const REJECT_TEXT = lazyGettext('No Thanks');

/* Login Modal */
export const followLoginModalTitleText = (organizerName) =>
    gettext('Sign in to follow %(organizerName)s', { organizerName });
export const FOLLOW_LOGIN_MODAL_SUBTITLE_TEXT = lazyGettext(
    'Stay up on the latest from your favorite event organizers',
);
export const GET_STARTED_TEXT = lazyGettext('Get Started');

/* Notification */
export const getSuccessNotificationText = (name) =>
    gettext(
        'You\'re now following %(name)s. See more in your <a className="eds-link" href="/mytickets/">profile</a>',
        { name },
    );
export const getFollowErrorNotification = (name) =>
    gettext('Unable to follow %(name)s. Please try again in a few minutes', {
        name,
    });
export const getUnFollowErrorNotification = (name) =>
    gettext('Unable to unfollow %(name)s. Please try again in a few minutes', {
        name,
    });
export const GET_USER_ERROR_NOTIFICATION = gettext(
    'There was an error with the server. Please try again in a few minutes',
);
export const GET_ORG_ERROR_NOTIFICATION = gettext(
    'There was an error getting the organizer info from the server. Please try again in a few minutes',
);
