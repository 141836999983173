// TODO: move to transformation-utils?
import { HAS_FORM_DATA } from '@eb/feature-detection';

/**
 * Converts a plain object map into a FormData object
 *
 * @param obj - object of form values
 * @returns FormData instance
 */
export const objectToFormData = (obj) => {
    if (!HAS_FORM_DATA) {
        throw new Error(
            'Unable to convert objectToFormData in environment without FormData',
        );
    }

    const data = new FormData();

    Object.entries(obj).forEach(([key, value]) => data.append(key, value));

    return data;
};
