import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { translationPropType } from '@eb/i18n';
import {
    SVG_PROP_TYPE,
    SIZE_PROP_TYPE,
    COLOR_PROP_TYPE,
    SHOULD_USE_RESET_PATTERN,
    VECTOR_IMAGE_ICON,
    VECTOR_IMAGE_BRAND,
    VECTOR_IMAGE_PAYMENT,
    VECTOR_IMAGE_GRAPHIC,
    VECTOR_IMAGE_ILLUSTRATION,
} from './constants';

import './vectorImage.scss';

export default class VectorImage extends PureComponent {
    static propTypes = {
        /**
         * The SVG of icon that you want to display
         * (or a component that renders and <svg />)
         */
        svg: SVG_PROP_TYPE.isRequired,
        /**
         * The type of icon that you want to display e.g. icon, brand, payment, graphic
         */
        type: PropTypes.oneOf([
            VECTOR_IMAGE_ICON,
            VECTOR_IMAGE_BRAND,
            VECTOR_IMAGE_PAYMENT,
            VECTOR_IMAGE_GRAPHIC,
            VECTOR_IMAGE_ILLUSTRATION,
        ]).isRequired,
        /**
         * Additional content to display next to svg
         */
        children: PropTypes.node,
        /**
         * The size of the icon
         */
        size: SIZE_PROP_TYPE,
        /**
         * The specified width of the icon
         */
        width: PropTypes.string,
        /**
         * The specified height of the icon
         */
        height: PropTypes.string,
        /**
         * The display the icon as a block level element
         */
        isBlockLevel: PropTypes.bool,
        /**
         * The fill color of the icon
         */
        color: COLOR_PROP_TYPE,
        /**
         * The stroke color of the icon
         */
        strokeColor: COLOR_PROP_TYPE,
        /**
         * Option to include a halo for the icon
         */
        hasHalo: PropTypes.bool,
        /**
         * Option to include a background color halo for the icon
         */
        haloColor: COLOR_PROP_TYPE,
        /**
         * Option to override the halo size.
         */
        haloSize: SIZE_PROP_TYPE,
        /**
         * Option to animate the icon
         * (Note: not all icons can be animated)
         */
        animated: PropTypes.bool,
        /**
         * Add a title attribute to the to icons markup
         */
        title: translationPropType,
        __containerClassName: PropTypes.string,
    };

    render() {
        const {
            svg,
            type,
            size,
            width,
            height,
            color,
            strokeColor,
            hasHalo,
            haloColor,
            haloSize,
            animated,
            isBlockLevel,
            title,
            __containerClassName,
            children,
        } = this.props;
        const className = classNames(
            'eds-vector-image',
            {
                [`eds-${type}--${size}`]: size,
                [`eds-${type}--animated`]: animated,
                [`eds-vector-image--${color}`]: color,
                [`eds-vector-image--stroke-${strokeColor}`]: strokeColor,
                'eds-vector-image--block': isBlockLevel,
                'eds-vector-image-size--reset':
                    SHOULD_USE_RESET_PATTERN.test(type) ||
                    (width && height) ||
                    haloSize,
                'eds-align--center': haloSize,
                'eds-vector-image-halo-size-override': haloSize,
            },
            __containerClassName,
        );
        let hiddenText;
        let accessibilityProps = {};
        let style;

        // NOTE: According to Alli we may want to always have a `title` for accessibility
        // reasons, so it'd be nice if we had a mapping of types to display names that
        // we can fallback to if the `title` isn't specified
        if (title) {
            hiddenText = (
                <span className="eds-is-hidden-accessible">{title}</span>
            );
        } else {
            accessibilityProps = { ...accessibilityProps, 'aria-hidden': true };
        }

        if (height && width) {
            style = {
                height,
                width,
            };
        }

        const icon = (
            <i
                className={className}
                title={title}
                data-spec="icon"
                style={style}
                {...accessibilityProps}
            >
                {svg}
                {hiddenText}
                {children}
            </i>
        );

        if (hasHalo) {
            let newHaloSize = size;

            if (haloSize) {
                newHaloSize = haloSize;
            }

            const haloClassName = classNames(
                `eds-${type}--halo`,
                {
                    [`eds-${type}-halo--${newHaloSize}`]: newHaloSize,
                    [`eds-bg-color--${haloColor}`]: haloColor,
                },
                __containerClassName,
            );

            return <span className={haloClassName}>{icon}</span>;
        }

        return icon;
    }
}
