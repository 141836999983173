import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import gettext from '@eb/gettext';

import { Button } from '@eb/eds-button';
import * as constants from './constants';

import './followButton.scss';

export default class FollowButton extends Component {
    static propTypes = {
        /**
         * Disables the button
         */
        disabled: PropTypes.bool,
        /**
         * Following state
         */
        isFollowing: PropTypes.bool,
        /**
         * Callback function invoked when the button is clicked.
         * () => { }
         */
        onClick: PropTypes.func,
        /**
         * Changes the style of the button.
         * Available styles: standard, solitary
         */
        style: PropTypes.oneOf(constants.STYLES),
        /**
         * Changes the size of the button.
         * Available sizes: standard, compact, mini
         */
        size: PropTypes.oneOf(constants.SIZES),
        /**
         * Adds a class name to the container of the button.
         */
        __containerClassName: PropTypes.string,
    };

    static defaultProps = {
        size: constants.SIZE_STANDARD,
        style: constants.STYLE_STANDARD,
    };

    render() {
        const {
            onClick,
            isFollowing,
            style,
            size,
            disabled,
            __containerClassName,
        } = this.props;
        const buttonText = isFollowing
            ? gettext('Following')
            : gettext('Follow');
        const styleClasses = classNames({
            'eds-btn--follow-solitary': style === constants.STYLE_SOLITARY,
        });
        const sizeClasses = classNames({
            'eds-btn--follow-compact': size === constants.SIZE_COMPACT,
            'eds-btn--follow-mini': size === constants.SIZE_MINI,
        });

        const containerClasses = classNames(styleClasses, sizeClasses, {
            [`${__containerClassName}`]: __containerClassName,
        });

        return (
            <Button
                onClick={onClick}
                disabled={disabled}
                style="follow"
                isFollowing={isFollowing}
                __containerClassName={containerClasses}
            >
                {buttonText}
            </Button>
        );
    }
}
