import PropTypes from 'prop-types';
import { lazyGettext } from '@eb/lazy-gettext';

import { ICON_TYPE_PROP_TYPE } from '@eb/eds-icon';

export const TYPE_ERROR = 'error';
export const TYPE_NEUTRAL = 'neutral';
export const TYPE_ORBITAL = 'orbital';
export const TYPE_SUCCESS = 'success';
export const TYPE_WARNING = 'warning';
export const NOTIFICATION_TYPES = [
    TYPE_NEUTRAL,
    TYPE_ERROR,
    TYPE_ORBITAL,
    TYPE_SUCCESS,
    TYPE_WARNING,
];
export const NOTIFICATION_TYPES_PROP_TYPE = PropTypes.oneOf(NOTIFICATION_TYPES);
export const ICON_COLOR = 'grey-700';
export const ICON_INVERSE_COLOR = 'white';

export const NOTIFICATION_OPTIONS_PROP_TYPES = PropTypes.shape({
    /**
     * Usually plain text or a node with notification text
     */
    children: PropTypes.node.isRequired,
    /**
     * Type of notification
     */
    type: NOTIFICATION_TYPES_PROP_TYPE,
    /**
     * Icon to show on the left of the notification bar
     */
    iconType: ICON_TYPE_PROP_TYPE,
    /**
     * Whether or not the close button should be displayed
     */
    hasCloseButton: PropTypes.bool,
    /**
     * Callback function called when notification is closed (typically by close button)
     */
    onClose: PropTypes.func,
    /**
     * Whether or not the notification is hidden by default
     */
    isHidden: PropTypes.bool,
    /**
     * Whether or not the page should scroll to the notification when the notification is mounted
     */
    shouldScrollTo: PropTypes.bool,
    /**
     * Whether or not the notification should persist. Default non-cta notifications retract after 8 seconds
     */
    shouldPersist: PropTypes.bool,
});

export const actionText = lazyGettext('Agree');
export const linkText = lazyGettext(
    'For more information please review our cookie policy.',
);
