import transform from 'lodash/transform';
import PropTypes from 'prop-types';

export const ANALYTICS_LABEL_MAP = {
    '/access-levels/': 'AccessLevels',
    '/apps/bandsintown': 'BandsintownPromoter',
    '/apps/conference-badges': 'CustomBadges',
    '/apps/eventkingdom': 'DesignerInvitations',
    '/apps/fanzone': 'SharedTransportation',
    '/apps/goldstar': 'Goldstar',
    '/apps/mailchimp': 'MailChimpSync',
    '/apps/membership': 'MembershipManagement',
    '/apps/presdo-match': 'CustomMobileApp',
    '/apps/surveymonkey': 'SurveyMonkey',
    '/apps/toneden': 'FacebookAndInstagramAds',
    createinvite: 'EmailInvitations',
    '/e/': 'listing',
    directory: 'discovery',
    '/facebook-publish?eid=': 'AddToFacebook',
    home: 'home',
    '/language?eid=': 'EventTypeAndLanguage',
    '/links?eid=': 'TrackingLinks',
    multi_event_discounts: 'DiscountAndAccessCodes',
    myevents: 'myevents',
    '/myevent?eid=': 'EventDashboard',
    '/myevents/apps/': 'BrowseExtensions',
    mytickets: 'mytickets',
    '/orderconfirmation/': 'orderconfirmation',
    'order-confirmation': 'OrderConfirmation',
    '/reports?eid=': 'Orders',
    '/tracking/': 'TrackingPixels',
    '/questions?eid=': 'OrderForm',
    '/waitlist-settings?eid=': 'Waitlist',
    'web-integration': 'Widgets',
    '/zone-management/': 'Zones',
};
export const TRACK_EVENT_REQUIRED_PROPS = ['category', 'action'];
export const EB_GA_PROPERTY_FOR_GA = [
    'page',
    'referrer',
    'useBeacon',
    'location',
    'hostname',
    'title',
];

export const GA_SETTINGS_PROP_TYPE = PropTypes.shape({
    isActive: PropTypes.bool,
    userPartnerId: PropTypes.string,
    guestPartnerId: PropTypes.string,
    correlationId: PropTypes.string,
    activeScopeUserId: PropTypes.string,
});

// migrated from js-utils/analytics
const METRIC_TYPES = {
    1: 'timeSpentOnPage',
    2: 'idlePeriodId',
    3: 'timeSpentThisActive',
    4: 'timeSpentThisIdle',
    5: 'totalTimeSpentActive',
    6: 'totalTimeSpentIdle',
    7: 'totalTimeRemaining',
    8: 'ticketQuantity',
    9: 'searchRank',
    12: 'searchResultCount',
    13: 'searchPagination',
    14: 'searchQueryResponseTime',
    15: 'searchResultCountUnattendable',
};
/**
 * See https://docs.google.com/spreadsheets/d/1L8EDa1_i8iZyC6g_0ElvTXCoPiKfELTgl06qtbrJc-g/edit#gid=148629284
 * for available dimensions. If adding a dimension, please update that spreadsheet!
 */
const DIMENSION_TYPES = {
    1: 'pageScrollPosition',
    2: 'inFocusField',
    3: 'nameOfButton',
    4: 'locationOnPage',
    5: 'isOnDesignTab',
    6: 'howEntered',
    7: 'fieldChanged',
    8: 'isRepeating',
    9: 'ticketType',
    10: 'visibility',
    11: 'errorCode',
    12: 'eventId',
    13: 'temporaryObjectId',
    14: 'userId',
    15: 'managePage',
    16: 'isFirstCreateView',
    17: 'isFirstCreateSave',
    18: 'isFirstPublish',
    19: 'isFirstOrder',
    20: 'sessionId',
    21: 'experimentId',
    23: 'urlParams',
    24: 'guestId',
    28: 'eventOwnerUserId',
    38: 'experimentId3',
    50: 'correlationId',
    51: 'activeScopeUserId',
    52: 'searchId',
    54: 'eventSource',
    55: 'checkoutStep',
    56: 'organizationVertical',
    58: 'apiKeyId',
    60: 'scheduleId',
    61: 'searchLocation',
    62: 'searchEventIds1',
    63: 'searchEventIds2',
    64: 'searchQuery',
    65: 'searchDates',
    66: 'searchPrice',
    67: 'searchCategory',
    68: 'searchFormat',
    69: 'searchSort',
    70: 'appName',
    71: 'appVersion',
};

function _invertMapping(values, type) {
    return transform(values, (memo, value, index) => {
        memo[value] = `${type}${index}`; // eslint-disable-line no-param-reassign
    });
}

export const METRICS = _invertMapping(METRIC_TYPES, 'metric');
export const DIMENSIONS = _invertMapping(DIMENSION_TYPES, 'dimension');
