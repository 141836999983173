export { default as HipercardLogo } from './icons/HipercardLogo';
export { default as AffirmPayment } from './icons/AffirmPayment';
export { default as AlertChunky } from './icons/AlertChunky';
export { default as AlignCenterChunky } from './icons/AlignCenterChunky';
export { default as AlignJustifyChunky } from './icons/AlignJustifyChunky';
export { default as AlignLeftChunky } from './icons/AlignLeftChunky';
export { default as AlignRightChunky } from './icons/AlignRightChunky';
export { default as AmexPayment } from './icons/AmexPayment';
export { default as AndroidPayPayment } from './icons/AndroidPayPayment';
export { default as ApplePayPayment } from './icons/ApplePayPayment';
export { default as Apple } from './icons/Apple';
export { default as ArrowDownChunky } from './icons/ArrowDownChunky';
export { default as ArrowDown } from './icons/ArrowDown';
export { default as ArrowLeftChunky } from './icons/ArrowLeftChunky';
export { default as ArrowLeft } from './icons/ArrowLeft';
export { default as ArrowRightChunky } from './icons/ArrowRightChunky';
export { default as ArrowRight } from './icons/ArrowRight';
export { default as ArrowSwitchChunky } from './icons/ArrowSwitchChunky';
export { default as ArrowUpChunky } from './icons/ArrowUpChunky';
export { default as ArrowUpLeftChunky } from './icons/ArrowUpLeftChunky';
export { default as ArrowUp } from './icons/ArrowUp';
export { default as AssortmentsEssentialsComplexIllustration } from './icons/AssortmentsEssentialsComplexIllustration';
export { default as AssortmentsEssentialsSimpleIllustration } from './icons/AssortmentsEssentialsSimpleIllustration';
export { default as AssortmentsPremiumComplexIllustration } from './icons/AssortmentsPremiumComplexIllustration';
export { default as AssortmentsPremiumSimpleIllustration } from './icons/AssortmentsPremiumSimpleIllustration';
export { default as AssortmentsProfessionalComplexIllustration } from './icons/AssortmentsProfessionalComplexIllustration';
export { default as AssortmentsProfessionalSimpleIllustration } from './icons/AssortmentsProfessionalSimpleIllustration';
export { default as AttendanceIllustration } from './icons/AttendanceIllustration';
export { default as Auto } from './icons/Auto';
export { default as Balloon } from './icons/Balloon';
export { default as BancontactPayment } from './icons/BancontactPayment';
export { default as BankGraphic } from './icons/BankGraphic';
export { default as Bank } from './icons/Bank';
export { default as BarGraphFill } from './icons/BarGraphFill';
export { default as BarGraph } from './icons/BarGraph';
export { default as Bathroom } from './icons/Bathroom';
export { default as Beaker } from './icons/Beaker';
export { default as BellNotificationChunky } from './icons/BellNotificationChunky';
export { default as BellNotification } from './icons/BellNotification';
export { default as Bell } from './icons/Bell';
export { default as Bike } from './icons/Bike';
export { default as BlueContentIllustration } from './icons/BlueContentIllustration';
export { default as BlueContentMobileIllustration } from './icons/BlueContentMobileIllustration';
export { default as BlueDeleteIllustration } from './icons/BlueDeleteIllustration';
export { default as BlueEditIllustration } from './icons/BlueEditIllustration';
export { default as BlueMoneyIllustration } from './icons/BlueMoneyIllustration';
export { default as BluePreviewIllustration } from './icons/BluePreviewIllustration';
export { default as BlueTicketIllustration } from './icons/BlueTicketIllustration';
export { default as Boat } from './icons/Boat';
export { default as BoldChunky } from './icons/BoldChunky';
export { default as Bold } from './icons/Bold';
export { default as BoletoPayment } from './icons/BoletoPayment';
export { default as BoltFill } from './icons/BoltFill';
export { default as BoltOffFill } from './icons/BoltOffFill';
export { default as BoltOff } from './icons/BoltOff';
export { default as Bolt } from './icons/Bolt';
export { default as Boogie } from './icons/Boogie';
export { default as BookOpen } from './icons/BookOpen';
export { default as BookRoundIllustration } from './icons/BookRoundIllustration';
export { default as Book } from './icons/Book';
export { default as BookmarkChunky } from './icons/BookmarkChunky';
export { default as BookmarkFillChunky } from './icons/BookmarkFillChunky';
export { default as BookmarkFill } from './icons/BookmarkFill';
export { default as Bookmark } from './icons/Bookmark';
export { default as BouncedGraphic } from './icons/BouncedGraphic';
export { default as BrandSuperCropIllustration } from './icons/BrandSuperCropIllustration';
export { default as Briefcase } from './icons/Briefcase';
export { default as Bug } from './icons/Bug';
export { default as Bus } from './icons/Bus';
export { default as Business } from './icons/Business';
export { default as CalendarAddFill } from './icons/CalendarAddFill';
export { default as CalendarAdd } from './icons/CalendarAdd';
export { default as CalendarCheckMarkChunky } from './icons/CalendarCheckMarkChunky';
export { default as CalendarChunky } from './icons/CalendarChunky';
export { default as CalendarFill } from './icons/CalendarFill';
export { default as CalendarGraphic } from './icons/CalendarGraphic';
export { default as CalendarOffChunky } from './icons/CalendarOffChunky';
export { default as CalendarRepeatEventIllustration } from './icons/CalendarRepeatEventIllustration';
export { default as CalendarRepeatFill } from './icons/CalendarRepeatFill';
export { default as CalendarRepeat } from './icons/CalendarRepeat';
export { default as CalendarStatusChunky } from './icons/CalendarStatusChunky';
export { default as Calendar } from './icons/Calendar';
export { default as CameraFill } from './icons/CameraFill';
export { default as CameraGraphic } from './icons/CameraGraphic';
export { default as CameraOffFill } from './icons/CameraOffFill';
export { default as CameraOff } from './icons/CameraOff';
export { default as Camera } from './icons/Camera';
export { default as CardReaderFill } from './icons/CardReaderFill';
export { default as CardReader } from './icons/CardReader';
export { default as CartChunky } from './icons/CartChunky';
export { default as CartFill } from './icons/CartFill';
export { default as Cart } from './icons/Cart';
export { default as CashChunky } from './icons/CashChunky';
export { default as CashOffChunky } from './icons/CashOffChunky';
export { default as Chair } from './icons/Chair';
export { default as ChangesGraphic } from './icons/ChangesGraphic';
export { default as Charity } from './icons/Charity';
export { default as ChatFill } from './icons/ChatFill';
export { default as Chat } from './icons/Chat';
export { default as CheckChunky } from './icons/CheckChunky';
export { default as CheckInChunky } from './icons/CheckInChunky';
export { default as CheckOutChunky } from './icons/CheckOutChunky';
export { default as Check } from './icons/Check';
export { default as Checklist } from './icons/Checklist';
export { default as Cheque } from './icons/Cheque';
export { default as ChevronDownChunky } from './icons/ChevronDownChunky';
export { default as ChevronDown } from './icons/ChevronDown';
export { default as ChevronFirstChunky } from './icons/ChevronFirstChunky';
export { default as ChevronLastChunky } from './icons/ChevronLastChunky';
export { default as ChevronLeftChunky } from './icons/ChevronLeftChunky';
export { default as ChevronRightChunky } from './icons/ChevronRightChunky';
export { default as ChevronUpChunky } from './icons/ChevronUpChunky';
export { default as ChevronUp } from './icons/ChevronUp';
export { default as CircleFill } from './icons/CircleFill';
export { default as Circle } from './icons/Circle';
export { default as City } from './icons/City';
export { default as ClickGraphic } from './icons/ClickGraphic';
export { default as ClipboardChunky } from './icons/ClipboardChunky';
export { default as Clipboard } from './icons/Clipboard';
export { default as ClockChunky } from './icons/ClockChunky';
export { default as ClockFill } from './icons/ClockFill';
export { default as Clock } from './icons/Clock';
export { default as CmrPayment } from './icons/CmrPayment';
export { default as Cocktail } from './icons/Cocktail';
export { default as CodeChunky } from './icons/CodeChunky';
export { default as Code } from './icons/Code';
export { default as Coffee } from './icons/Coffee';
export { default as ColumnSelectorChunky } from './icons/ColumnSelectorChunky';
export { default as CompassFill } from './icons/CompassFill';
export { default as Compass } from './icons/Compass';
export { default as Conference } from './icons/Conference';
export { default as CopyChunky } from './icons/CopyChunky';
export { default as CopyGraphic } from './icons/CopyGraphic';
export { default as Copy } from './icons/Copy';
export { default as CreateBetaGraphic } from './icons/CreateBetaGraphic';
export { default as CreateClassicGraphic } from './icons/CreateClassicGraphic';
export { default as CreateRoundIllustration } from './icons/CreateRoundIllustration';
export { default as CreditCardBackFill } from './icons/CreditCardBackFill';
export { default as CreditCardBack } from './icons/CreditCardBack';
export { default as CreditCardFrontFill } from './icons/CreditCardFrontFill';
export { default as CreditCardFront } from './icons/CreditCardFront';
export { default as CreditsIllustration } from './icons/CreditsIllustration';
export { default as Crop } from './icons/Crop';
export { default as CrossChunky } from './icons/CrossChunky';
export { default as CrossSignChunky } from './icons/CrossSignChunky';
export { default as Cross } from './icons/Cross';
export { default as Crosshair } from './icons/Crosshair';
export { default as CurrencyIllustration } from './icons/CurrencyIllustration';
export { default as Curtain } from './icons/Curtain';
export { default as DashboardGraphic } from './icons/DashboardGraphic';
export { default as DesignIllustration } from './icons/DesignIllustration';
export { default as DiamondGraphic } from './icons/DiamondGraphic';
export { default as DiscoverPayment } from './icons/DiscoverPayment';
export { default as DocumentFill } from './icons/DocumentFill';
export { default as Document } from './icons/Document';
export { default as DoubleArrowChunky } from './icons/DoubleArrowChunky';
export { default as DoubleArrow } from './icons/DoubleArrow';
export { default as DoubleDeckerBus } from './icons/DoubleDeckerBus';
export { default as Download } from './icons/Download';
export { default as DragChunky } from './icons/DragChunky';
export { default as EloPayment } from './icons/EloPayment';
export { default as Exit } from './icons/Exit';
export { default as ExpandLeft } from './icons/ExpandLeft';
export { default as ExpandRight } from './icons/ExpandRight';
export { default as Expand } from './icons/Expand';
export { default as EyeOffChunky } from './icons/EyeOffChunky';
export { default as EyeOff } from './icons/EyeOff';
export { default as EyeOnChunky } from './icons/EyeOnChunky';
export { default as Eye } from './icons/Eye';
export { default as Fashion } from './icons/Fashion';
export { default as FilmCamera } from './icons/FilmCamera';
export { default as FilterChunky } from './icons/FilterChunky';
export { default as Filter } from './icons/Filter';
export { default as FlagChunky } from './icons/FlagChunky';
export { default as FlagReport } from './icons/FlagReport';
export { default as FlatwareFill } from './icons/FlatwareFill';
export { default as Flatware } from './icons/Flatware';
export { default as Flower } from './icons/Flower';
export { default as FootstepsFill } from './icons/FootstepsFill';
export { default as Footsteps } from './icons/Footsteps';
export { default as Friends } from './icons/Friends';
export { default as Game } from './icons/Game';
export { default as GearChunky } from './icons/GearChunky';
export { default as GearFill } from './icons/GearFill';
export { default as Gear } from './icons/Gear';
export { default as GhostGraphic } from './icons/GhostGraphic';
export { default as GiftChunky } from './icons/GiftChunky';
export { default as Gift } from './icons/Gift';
export { default as GlobeChunky } from './icons/GlobeChunky';
export { default as Globe } from './icons/Globe';
export { default as GoogleWalletPayment } from './icons/GoogleWalletPayment';
export { default as GraphChunky } from './icons/GraphChunky';
export { default as GridChunky } from './icons/GridChunky';
export { default as GridViewFill } from './icons/GridViewFill';
export { default as GridView } from './icons/GridView';
export { default as GuestGraphic } from './icons/GuestGraphic';
export { default as GuestListGraphic } from './icons/GuestListGraphic';
export { default as H1Chunky } from './icons/H1Chunky';
export { default as H2Chunky } from './icons/H2Chunky';
export { default as H3Chunky } from './icons/H3Chunky';
export { default as H4Chunky } from './icons/H4Chunky';
export { default as H5Chunky } from './icons/H5Chunky';
export { default as H6Chunky } from './icons/H6Chunky';
export { default as HandWithMoney } from './icons/HandWithMoney';
export { default as Hashtag } from './icons/Hashtag';
export { default as HeadNegativeGraphic } from './icons/HeadNegativeGraphic';
export { default as Headline } from './icons/Headline';
export { default as Headphone } from './icons/Headphone';
export { default as HeartChunky } from './icons/HeartChunky';
export { default as HeartFillChunky } from './icons/HeartFillChunky';
export { default as HeartFill } from './icons/HeartFill';
export { default as Heart } from './icons/Heart';
export { default as HelpChunky } from './icons/HelpChunky';
export { default as HipercardPayment } from './icons/HipercardPayment';
export { default as HistoryChunky } from './icons/HistoryChunky';
export { default as Holiday } from './icons/Holiday';
export { default as HorizontalDotsChunky } from './icons/HorizontalDotsChunky';
export { default as HorsebackIllustration } from './icons/HorsebackIllustration';
export { default as HouseChunky } from './icons/HouseChunky';
export { default as HouseFill } from './icons/HouseFill';
export { default as House } from './icons/House';
export { default as IdCard } from './icons/IdCard';
export { default as IdealPayment } from './icons/IdealPayment';
export { default as ImageChunky } from './icons/ImageChunky';
export { default as InboxChunky } from './icons/InboxChunky';
export { default as InfoChunky } from './icons/InfoChunky';
export { default as InviteGraphic } from './icons/InviteGraphic';
export { default as InviteOnlyGraphic } from './icons/InviteOnlyGraphic';
export { default as ItalicChunky } from './icons/ItalicChunky';
export { default as Italic } from './icons/Italic';
export { default as JcbPayment } from './icons/JcbPayment';
export { default as Key } from './icons/Key';
export { default as KeyboardChunky } from './icons/KeyboardChunky';
export { default as LabelChunky } from './icons/LabelChunky';
export { default as LifePreserverFill } from './icons/LifePreserverFill';
export { default as LifePreserver } from './icons/LifePreserver';
export { default as LineChartFill } from './icons/LineChartFill';
export { default as LineChart } from './icons/LineChart';
export { default as Line } from './icons/Line';
export { default as LinkChunky } from './icons/LinkChunky';
export { default as Link } from './icons/Link';
export { default as ListViewFill } from './icons/ListViewFill';
export { default as ListView } from './icons/ListView';
export { default as LockFill } from './icons/LockFill';
export { default as Lock } from './icons/Lock';
export { default as LogoApple } from './icons/LogoApple';
export { default as LogoBandcamp } from './icons/LogoBandcamp';
export { default as LogoBandsintownColor } from './icons/LogoBandsintownColor';
export { default as LogoEBrand } from './icons/LogoEBrand';
export { default as LogoEBurst } from './icons/LogoEBurst';
export { default as LogoEColorBrand } from './icons/LogoEColorBrand';
export { default as LogoEventfulColor } from './icons/LogoEventfulColor';
export { default as LogoFacebookColor } from './icons/LogoFacebookColor';
export { default as LogoFacebookWhite } from './icons/LogoFacebookWhite';
export { default as LogoFacebook } from './icons/LogoFacebook';
export { default as LogoGmail } from './icons/LogoGmail';
export { default as LogoGoogleCalendar } from './icons/LogoGoogleCalendar';
export { default as LogoGooglePlus } from './icons/LogoGooglePlus';
export { default as LogoGoogle } from './icons/LogoGoogle';
export { default as LogoInstagramColor } from './icons/LogoInstagramColor';
export { default as LogoInstagram } from './icons/LogoInstagram';
export { default as LogoLinkedin } from './icons/LogoLinkedin';
export { default as LogoMessenger } from './icons/LogoMessenger';
export { default as LogoOutlook } from './icons/LogoOutlook';
export { default as LogoPaypal } from './icons/LogoPaypal';
export { default as LogoPinterest } from './icons/LogoPinterest';
export { default as LogoRss } from './icons/LogoRss';
export { default as LogoSeatgeekColor } from './icons/LogoSeatgeekColor';
export { default as LogoSnapColor } from './icons/LogoSnapColor';
export { default as LogoSongkickColor } from './icons/LogoSongkickColor';
export { default as LogoSoundcloud } from './icons/LogoSoundcloud';
export { default as LogoSpotifyColor } from './icons/LogoSpotifyColor';
export { default as LogoTwitter } from './icons/LogoTwitter';
export { default as LogoWhatsapp } from './icons/LogoWhatsapp';
export { default as LogoWordmarkBrand } from './icons/LogoWordmarkBrand';
export { default as LogoYahoo } from './icons/LogoYahoo';
export { default as LogoYoutube } from './icons/LogoYoutube';
export { default as MaestroPayment } from './icons/MaestroPayment';
export { default as MagnifyingGlassChunky } from './icons/MagnifyingGlassChunky';
export { default as MagnifyingGlassFill } from './icons/MagnifyingGlassFill';
export { default as MagnifyingGlass } from './icons/MagnifyingGlass';
export { default as MailChunky } from './icons/MailChunky';
export { default as MailFillChunky } from './icons/MailFillChunky';
export { default as MailFill } from './icons/MailFill';
export { default as MailOpened } from './icons/MailOpened';
export { default as Mail } from './icons/Mail';
export { default as MailboxChunky } from './icons/MailboxChunky';
export { default as ManageRoundIllustration } from './icons/ManageRoundIllustration';
export { default as MapPinChunky } from './icons/MapPinChunky';
export { default as MapPinFill } from './icons/MapPinFill';
export { default as MapPin } from './icons/MapPin';
export { default as Map } from './icons/Map';
export { default as Mask } from './icons/Mask';
export { default as MastercardDebitPayment } from './icons/MastercardDebitPayment';
export { default as MastercardPayment } from './icons/MastercardPayment';
export { default as MediaFill } from './icons/MediaFill';
export { default as Media } from './icons/Media';
export { default as Megaphone } from './icons/Megaphone';
export { default as MenuChunky } from './icons/MenuChunky';
export { default as MercadopagoPayment } from './icons/MercadopagoPayment';
export { default as MicrophoneGraphic } from './icons/MicrophoneGraphic';
export { default as MicrophoneIllustration } from './icons/MicrophoneIllustration';
export { default as Microphone } from './icons/Microphone';
export { default as MinusChunky } from './icons/MinusChunky';
export { default as Minus } from './icons/Minus';
export { default as MobileTicketIllustration } from './icons/MobileTicketIllustration';
export { default as MonerisPayment } from './icons/MonerisPayment';
export { default as MoneyGraphic } from './icons/MoneyGraphic';
export { default as Money } from './icons/Money';
export { default as Move } from './icons/Move';
export { default as MusicNoteFill } from './icons/MusicNoteFill';
export { default as MusicNote } from './icons/MusicNote';
export { default as NaranjaPayment } from './icons/NaranjaPayment';
export { default as NativaPayment } from './icons/NativaPayment';
export { default as NewWindowChunky } from './icons/NewWindowChunky';
export { default as NewWindow } from './icons/NewWindow';
export { default as NoResultsIllustration } from './icons/NoResultsIllustration';
export { default as NotAuthorizedIllustration } from './icons/NotAuthorizedIllustration';
export { default as NoteFilled } from './icons/NoteFilled';
export { default as Note } from './icons/Note';
export { default as OrangeFlagIllustration } from './icons/OrangeFlagIllustration';
export { default as OrangeSearchIllustration } from './icons/OrangeSearchIllustration';
export { default as OrderedListChunky } from './icons/OrderedListChunky';
export { default as OrderedList } from './icons/OrderedList';
export { default as OrdersEmptyGraphic } from './icons/OrdersEmptyGraphic';
export { default as OrganizerIllustration } from './icons/OrganizerIllustration';
export { default as OxxoPayment } from './icons/OxxoPayment';
export { default as PagofacilPayment } from './icons/PagofacilPayment';
export { default as PaintVenueGraphic } from './icons/PaintVenueGraphic';
export { default as Park } from './icons/Park';
export { default as Pause } from './icons/Pause';
export { default as PaymentChunky } from './icons/PaymentChunky';
export { default as PayoutsIllustration } from './icons/PayoutsIllustration';
export { default as PaypalBankPayment } from './icons/PaypalBankPayment';
export { default as PaypalPayment } from './icons/PaypalPayment';
export { default as PeakGraphic } from './icons/PeakGraphic';
export { default as PencilChunky } from './icons/PencilChunky';
export { default as PencilFill } from './icons/PencilFill';
export { default as Pencil } from './icons/Pencil';
export { default as Performance } from './icons/Performance';
export { default as PersonMeh } from './icons/PersonMeh';
export { default as PersonSad } from './icons/PersonSad';
export { default as Person } from './icons/Person';
export { default as PhoneChunky } from './icons/PhoneChunky';
export { default as PhotoFill } from './icons/PhotoFill';
export { default as Photo } from './icons/Photo';
export { default as PlaceholderSvg1 } from './icons/PlaceholderSvg1';
export { default as PlaceholderSvg2 } from './icons/PlaceholderSvg2';
export { default as PlaceholderSvg3 } from './icons/PlaceholderSvg3';
export { default as PlaceholderSvg4 } from './icons/PlaceholderSvg4';
export { default as PlatterGraphic } from './icons/PlatterGraphic';
export { default as PlayFill } from './icons/PlayFill';
export { default as Play } from './icons/Play';
export { default as PlusChunky } from './icons/PlusChunky';
export { default as Plus } from './icons/Plus';
export { default as Politic } from './icons/Politic';
export { default as PrinterChunky } from './icons/PrinterChunky';
export { default as PrinterFill } from './icons/PrinterFill';
export { default as Printer } from './icons/Printer';
export { default as PromoCodesEmptyStateGraphic } from './icons/PromoCodesEmptyStateGraphic';
export { default as PublishChunky } from './icons/PublishChunky';
export { default as PublishIllustration } from './icons/PublishIllustration';
export { default as Puzzle } from './icons/Puzzle';
export { default as QrCode } from './icons/QrCode';
export { default as RapipagoPayment } from './icons/RapipagoPayment';
export { default as ReactChunky } from './icons/ReactChunky';
export { default as React } from './icons/React';
export { default as ReceiptChunky } from './icons/ReceiptChunky';
export { default as ReceiptFill } from './icons/ReceiptFill';
export { default as Receipt } from './icons/Receipt';
export { default as Rectangle21 } from './icons/Rectangle21';
export { default as Rectangle43 } from './icons/Rectangle43';
export { default as RectangleCustom } from './icons/RectangleCustom';
export { default as RedoChunky } from './icons/RedoChunky';
export { default as Redo } from './icons/Redo';
export { default as RefreshChunky } from './icons/RefreshChunky';
export { default as Religion } from './icons/Religion';
export { default as RepresentativeGraphic } from './icons/RepresentativeGraphic';
export { default as ReturnArrowChunky } from './icons/ReturnArrowChunky';
export { default as RfidChunky } from './icons/RfidChunky';
export { default as RfidDisabled } from './icons/RfidDisabled';
export { default as Rfid } from './icons/Rfid';
export { default as Rocket } from './icons/Rocket';
export { default as RockingHorse } from './icons/RockingHorse';
export { default as Rotate } from './icons/Rotate';
export { default as RowFill } from './icons/RowFill';
export { default as Row } from './icons/Row';
export { default as Save } from './icons/Save';
export { default as ScanCheckFill } from './icons/ScanCheckFill';
export { default as ScanCheck } from './icons/ScanCheck';
export { default as ScanChunky } from './icons/ScanChunky';
export { default as ScanCrossChunky } from './icons/ScanCrossChunky';
export { default as ScanPlusFill } from './icons/ScanPlusFill';
export { default as ScanPlus } from './icons/ScanPlus';
export { default as ScreenLarge } from './icons/ScreenLarge';
export { default as ScreenMedium } from './icons/ScreenMedium';
export { default as ScreenSmallChunky } from './icons/ScreenSmallChunky';
export { default as ScreenSmallFill } from './icons/ScreenSmallFill';
export { default as ScreenSmall } from './icons/ScreenSmall';
export { default as SectionCenterFill } from './icons/SectionCenterFill';
export { default as SectionCenter } from './icons/SectionCenter';
export { default as SectionJustifyFill } from './icons/SectionJustifyFill';
export { default as SectionJustify } from './icons/SectionJustify';
export { default as SectionLeftFill } from './icons/SectionLeftFill';
export { default as SectionLeft } from './icons/SectionLeft';
export { default as SectionRightFill } from './icons/SectionRightFill';
export { default as SectionRight } from './icons/SectionRight';
export { default as Seminar } from './icons/Seminar';
export { default as SendFill } from './icons/SendFill';
export { default as SendInviteGraphic } from './icons/SendInviteGraphic';
export { default as SepaPayment } from './icons/SepaPayment';
export { default as ShareAndroidChunky } from './icons/ShareAndroidChunky';
export { default as ShareAndroid } from './icons/ShareAndroid';
export { default as ShareFillChunky } from './icons/ShareFillChunky';
export { default as ShareIosChunky } from './icons/ShareIosChunky';
export { default as ShareIos } from './icons/ShareIos';
export { default as ShareSimpleChunky } from './icons/ShareSimpleChunky';
export { default as Shoe } from './icons/Shoe';
export { default as Signs } from './icons/Signs';
export { default as SofortPayment } from './icons/SofortPayment';
export { default as Square } from './icons/Square';
export { default as StarFill } from './icons/StarFill';
export { default as Star } from './icons/Star';
export { default as StatusDotChunky } from './icons/StatusDotChunky';
export { default as StopwatchChunky } from './icons/StopwatchChunky';
export { default as StopwatchFill } from './icons/StopwatchFill';
export { default as Stopwatch } from './icons/Stopwatch';
export { default as Strikethrough } from './icons/Strikethrough';
export { default as TableFill } from './icons/TableFill';
export { default as Table } from './icons/Table';
export { default as TagChunky } from './icons/TagChunky';
export { default as TagFill } from './icons/TagFill';
export { default as Tag } from './icons/Tag';
export { default as Teepee } from './icons/Teepee';
export { default as TextChunky } from './icons/TextChunky';
export { default as TextDefaultChunky } from './icons/TextDefaultChunky';
export { default as ThumbsDownChunky } from './icons/ThumbsDownChunky';
export { default as ThumbsUpChunky } from './icons/ThumbsUpChunky';
export { default as TicketAddGraphic } from './icons/TicketAddGraphic';
export { default as TicketChunky } from './icons/TicketChunky';
export { default as TicketFillChunky } from './icons/TicketFillChunky';
export { default as TicketFillOffChunky } from './icons/TicketFillOffChunky';
export { default as TicketFill } from './icons/TicketFill';
export { default as TicketMultiFill } from './icons/TicketMultiFill';
export { default as TicketMulti } from './icons/TicketMulti';
export { default as TicketOff } from './icons/TicketOff';
export { default as Ticket } from './icons/Ticket';
export { default as TicketsRoundIllustration } from './icons/TicketsRoundIllustration';
export { default as TitleEdit } from './icons/TitleEdit';
export { default as Title } from './icons/Title';
export { default as TrashChunky } from './icons/TrashChunky';
export { default as Trash } from './icons/Trash';
export { default as Travel } from './icons/Travel';
export { default as Trending } from './icons/Trending';
export { default as TriangleDownChunky } from './icons/TriangleDownChunky';
export { default as Trophy } from './icons/Trophy';
export { default as UndoChunky } from './icons/UndoChunky';
export { default as UndoCircularChunky } from './icons/UndoCircularChunky';
export { default as Undo } from './icons/Undo';
export { default as UnorderedListChunky } from './icons/UnorderedListChunky';
export { default as UnorderedList } from './icons/UnorderedList';
export { default as Upload } from './icons/Upload';
export { default as UserChunky } from './icons/UserChunky';
export { default as UserFill } from './icons/UserFill';
export { default as User } from './icons/User';
export { default as VerticalDotsChunky } from './icons/VerticalDotsChunky';
export { default as VerticalDots } from './icons/VerticalDots';
export { default as VideoChunky } from './icons/VideoChunky';
export { default as VisaDebitPayment } from './icons/VisaDebitPayment';
export { default as VisaPayment } from './icons/VisaPayment';
export { default as Voucher } from './icons/Voucher';
export { default as WaitingRoomIllustration } from './icons/WaitingRoomIllustration';
export { default as Walk } from './icons/Walk';
export { default as Wallet } from './icons/Wallet';
export { default as Wheelchair } from './icons/Wheelchair';
export { default as Wifi } from './icons/Wifi';
const iconSizes = require('./iconography_sizes.json');
export const SIZES_JSON = iconSizes;
