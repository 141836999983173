import { isEmpty } from 'lodash';
import { formatUrl } from 'url-lib';

import { deepKeysToSnake } from '@eb/transformation-utils';
import { fetchJSON } from '@eb/http';

import { pruneObject } from '../../utils/transformation';

const GET_PLACE_FROM_GOOGLE_PLACE_ID_URL =
    '/api/v3/geo/place_from_google_place_id/';
const GET_PLACE_FROM_COORDINATES_URL = '/api/v3/geo/place_from_coordinates/';

export const getPlaceFromGooglePlaceId = (googlePlaceId) => {
    const queryParams = deepKeysToSnake(
        pruneObject({
            googlePlaceId,
        }),
    );

    return fetchJSON(
        formatUrl(GET_PLACE_FROM_GOOGLE_PLACE_ID_URL, queryParams),
    );
};

export const getPlaceFromCoordinatesBase = ({
    latitude,
    longitude,
    placeType,
}) => {
    const queryParams = deepKeysToSnake(
        pruneObject({
            latitude,
            longitude,
            placeType,
        }),
    );

    return fetchJSON(formatUrl(GET_PLACE_FROM_COORDINATES_URL, queryParams));
};

export const getPlaceFromCoordinates = ({ latitude, longitude, placeType }) =>
    new Promise((resolve, reject) => {
        getPlaceFromCoordinatesBase({ latitude, longitude, placeType }).then(
            (response) => {
                if (isEmpty(response.place)) {
                    reject('{latitude, longitude}');
                }

                resolve(response);
            },
        );
    });

const searchLocationsSuggestionsUrl = (query) =>
    `/api/v3/destination/search/places/?q=${query}`;

export const searchLocationSuggestions = (query) =>
    fetchJSON(searchLocationsSuggestionsUrl(query));
