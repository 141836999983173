import PropTypes from 'prop-types';

//This will match the output of the
//collection selector present in this duck.
export const COLLECTION_PROP_TYPE = PropTypes.arrayOf(
    PropTypes.shape({
        eventCount: PropTypes.number,
        href: PropTypes.string,
        id: PropTypes.string,
        name: PropTypes.string,
    }),
);

// GA TRACKING CONSTANTS
export const CREATE_COLLECTION_GA_ACTION = 'CreateCollection';
export const ADD_TO_COLLECTION_GA_ACTION = 'AddToCollection';
export const REMOVE_FROM_COLLECTION_GA_ACTION = 'RemoveFromCollection';
export const REACHED_MAX_EVENTS_GA_ACTION = 'CollectEventLimitReached';
export const CREATE_COLLECTION_VIEW_GA_ACTION = 'CreateCollectionAttempt';
export const SHOW_ADD_TO_COLLECTION_GA_ACTION = 'AddToCollectionListShown';
