export const DEFAULT_LANGUAGES = [
    'nl_NL',
    'en_AU',
    'en_CA',
    'en_GB',
    'fr_CA',
    'fr_FR',
    'de_DE',
    'it_IT',
    'pt_BR',
    'pt_PT',
    'es_AR',
    'es_MX',
    'es_ES',
];

export const SUPPORTED_TLDS: { [key: string]: string } = Object.freeze({
    com: 'en_US',
    ca: 'en_CA',
    'com.ar': 'es_AR',
    'com.br': 'pt_BR',
    ch: 'de_CH',
    cl: 'es_CL',
    co: 'es_CO',
    'com.mx': 'es_MX',
    'co.uk': 'en_GB',
    ie: 'en_IE',
    in: 'en_IN',
    at: 'de_AT',
    be: 'nl_BE',
    de: 'de_DE',
    es: 'es_ES',
    fr: 'fr_FR',
    it: 'it_IT',
    nl: 'nl_NL',
    pt: 'pt_PT',
    'com.pe': 'es_PE',
    'co.nz': 'en_NZ',
    'com.au': 'en_AU',
    sg: 'en_SG',
    hk: 'en_HK',
    dk: 'en_DK',
    no: 'nn_NO',
    fi: 'en_FI',
    se: 'en_SE',
});
