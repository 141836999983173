import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import get from 'lodash/get';
import { translationPropType } from '@eb/i18n';

import { VectorImage } from '@eb/eds-vector-image';

import * as constants from './constants';

import { getIconVectorType, iconNameMatches } from './util';

import './icon.scss';

export default class Icon extends PureComponent {
    static propTypes = {
        /**
         * Props from Vector Image
         */
        ...omit(VectorImage.propTypes, ['svg', 'type']),
        /**
         * The type of icon that you want to display e.g. alert
         */
        type: constants.ICON_TYPE_PROP_TYPE.isRequired,
        /**
         * The size of the icon
         */
        size: PropTypes.oneOf(constants.SIZES),
        /**
         * The fill color of the icon
         */
        color: constants.COLOR_PROP_TYPE,
        /**
         * The stroke color of the icon
         */
        strokeColor: constants.COLOR_PROP_TYPE,
        /**
         * The title of the icon
         */
        title: translationPropType,
    };

    static defaultProps = {
        size: 'small',
    };

    render() {
        const { type, size, color, strokeColor } = this.props;
        const vectorImageProps = pick(
            this.props,
            ...Object.keys(VectorImage.propTypes),
        );
        let overrideColor = color;
        let overrideStrokeColor = strokeColor;

        // Default the color to a theme color no color has been specified.
        // This way the icons will be themable

        // NOTE: Below is commented out until we can properly theme all
        // the places icons are used (EB-99689)
        // if (!overrideColor && !overrideStrokeColor) {
        //     overrideColor = 'ui-700';
        // }

        // Clear out defaulted color for logo icons
        if (
            !color &&
            iconNameMatches(type, (name) => name.startsWith('logo-'))
        ) {
            overrideColor = undefined;
        }

        // if the icon type is one that should use a stroke color instead of fill
        // color, replace the fill color with the stroke color
        if (!strokeColor) {
            const isStrokeIcon = !!constants.STROKE_COLOR_ICONS.find(
                (strokeColorIcon) =>
                    strokeColorIcon.displayName ===
                    get(type, 'type.displayName'),
            );

            if (isStrokeIcon) {
                overrideStrokeColor = overrideColor;
                overrideColor = undefined;
            }
        }

        return (
            <VectorImage
                {...vectorImageProps}
                type={getIconVectorType(type)}
                svg={type}
                size={size}
                color={overrideColor}
                strokeColor={overrideStrokeColor}
            />
        );
    }
}
