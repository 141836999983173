import React from 'react';
import { GREYS_JSON } from '@eb/eds-color';

export const Initials = ({ x, y, fontSize, textColor, children }) => (
    <text
        x={x}
        y={y}
        fontSize={fontSize}
        fontWeight="600"
        textAnchor="middle"
        fill={GREYS_JSON[textColor]}
    >
        {children}
    </text>
);
