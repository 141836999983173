const INDEX_REGEX = /%s/g;
const NAMED_REGEX = /%\(\w+\)s/g;

export default function (fmt, obj, named) {
    let pattern = INDEX_REGEX;
    let transformer;

    function indexTransformer() {
        return String(obj.shift());
    }
    function objectTransformer(match) {
        return String(obj[match.slice(2, -2)]);
    }

    transformer = indexTransformer;

    if (named) {
        pattern = NAMED_REGEX;
        transformer = objectTransformer;
    }

    return String(fmt).replace(pattern, transformer);
}
