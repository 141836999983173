import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { FollowButton } from '@eb/eds-follow-button';
import FollowLoadingButton from './FollowLoadingButton';

import {
    FOLLOW_BUTTON_STYLES,
    FOLLOW_BUTTON_SIZES,
    FOLLOWING_TEXT,
    FOLLOW_TEXT,
} from '../constants';

const _isInfoReady = (isFollowing) => typeof isFollowing === 'boolean';

class FollowButtonComponent extends Component {
    static propTypes = {
        /**
         * Callback for when user clicks to follow
         */
        onFollow: PropTypes.func.isRequired,
        /**
         * Callback for when user clicks to unfollow
         */
        onUnFollow: PropTypes.func.isRequired,
        /**
         * Whether user is currently following organizer or not
         */
        isFollowing: PropTypes.bool,
        /**
         * Whether user can click the button or not
         */
        isDisabled: PropTypes.bool,
        /**
         * Name of the organizer
         */
        organizerName: PropTypes.string,
        /**
         * Style of Button to be rendered 'standard' or 'solitary'
         */
        style: PropTypes.oneOf(FOLLOW_BUTTON_STYLES),
        /**
         * Size of button 'compact', 'mini' or 'standard'
         */
        size: PropTypes.oneOf(FOLLOW_BUTTON_SIZES),
        /**
         * Class for the container of the button
         */
        __containerClassName: PropTypes.string,
    };

    static defaultProps = {
        style: 'standard',
        size: 'standard',
        isFollowing: null,
    };

    render() {
        const {
            style,
            size,
            __containerClassName,
            isFollowing,
            onFollow,
            onUnFollow,
            organizerName,
            isDisabled,
        } = this.props;
        const followButtonText = isFollowing ? FOLLOWING_TEXT : FOLLOW_TEXT;
        const followButtonDataSpec = isFollowing
            ? 'following-organizer-button'
            : 'follow-organizer-button';
        const followButtonAction = isFollowing ? onUnFollow : onFollow;

        if (!_isInfoReady(isFollowing)) {
            return <FollowLoadingButton size={size} />;
        }

        if (!organizerName) {
            return null;
        }

        return (
            <div className={__containerClassName}>
                <FollowButton
                    disabled={isDisabled}
                    data-spec={followButtonDataSpec}
                    onClick={followButtonAction}
                    style={style}
                    size={size}
                    isFollowing={isFollowing}
                    __containerClassName={__containerClassName}
                >
                    {followButtonText}
                </FollowButton>
            </div>
        );
    }
}

export default FollowButtonComponent;
