import React from 'react';

// original author: will
import PropTypes from 'prop-types';

import gettext from '@eb/gettext';
import classNames from 'classnames';
import { Icon } from '@eb/eds-icon';
import { Button } from '@eb/eds-button';
import { CrossChunky } from '@eb/eds-iconography';
import { IconButton } from '@eb/eds-icon-button';
import { ICON_TYPE_PROP_TYPE } from '@eb/eds-icon';
import { STYLE_INVERSE, STYLE_DARK } from '@eb/eds-icon-button';
import {
    STYLE_ANCHOR as BTN_STYLE_ANCHOR,
    STYLE_INVERSE as BTN_STYLE_INVERSE,
} from '@eb/eds-button';
import * as constants from './constants';
import { getAdditionalProps } from '@eb/eds-utils';

import './notificationBar.scss';

const NotificationBarIcon = ({ shouldShow, iconType, iconColor }) => {
    let component = null;

    if (shouldShow && iconType) {
        component = (
            <div className="eds-notification-bar__icon">
                <Icon
                    type={iconType}
                    color={iconColor}
                    data-spec="notification-bar-icon"
                />
            </div>
        );
    }

    return component;
};

const NotificationBarClose = ({
    shouldShow,
    onClick,
    useInverseStyle,
    iconColor,
}) => {
    let component = null;
    const style = useInverseStyle ? STYLE_INVERSE : STYLE_DARK;

    if (shouldShow) {
        component = (
            <div className="eds-notification-bar__close eds-l-pad-right-2 eds-l-pad-vert-1">
                <IconButton
                    iconType={<CrossChunky />}
                    data-spec="close-button"
                    title={gettext('Close')}
                    style={style}
                    onClick={onClick}
                    iconColor={iconColor}
                />
            </div>
        );
    }

    return component;
};

const NotificationBarCTA = ({ isOrbital, callAction, onClick }) => {
    let component = null;

    if (callAction && onClick) {
        const buttonStyle = isOrbital ? BTN_STYLE_INVERSE : BTN_STYLE_ANCHOR;

        component = (
            <div
                className="eds-notification-bar__cta"
                data-spec="notification-bar-cta"
            >
                <Button
                    style={buttonStyle}
                    onClick={onClick}
                    data-spec="notification-bar-cta-btn"
                >
                    {callAction}
                </Button>
            </div>
        );
    }

    return component;
};

export default class NotificationBar extends React.PureComponent {
    static propTypes = {
        /**
         * Icon to show on the left of the notification bar
         */
        iconType: ICON_TYPE_PROP_TYPE,
        /**
         * Usually plain text or a node with notification text
         */
        children: PropTypes.node.isRequired,
        /**
         * Type of notification
         */
        type: constants.NOTIFICATION_TYPES_PROP_TYPE,
        /**
         * Show/hide close button
         */
        hasCloseButton: PropTypes.bool,
        /**
         * CTA for notification
         */
        callAction: PropTypes.node,
        // DEPRECATED
        callActionString: PropTypes.node,
        /**
         * CTA callback for notification
         */
        onCallAction: PropTypes.func,
        // DEPRECATED
        callActionClick: PropTypes.func,
        /**
         * Callback method when close button has been clicked
         */
        onClose: PropTypes.func,
        /**
         * Hide notification
         */
        isHidden: PropTypes.bool,
        /**
         * Container class name
         */
        __containerClassName: PropTypes.string,
        /**
         * Should scroll to notification on render
         */
        shouldScrollTo: PropTypes.bool,
    };

    static defaultProps = {
        type: constants.TYPE_NEUTRAL,
        isHidden: false,
        hasCloseButton: false,
        shouldScrollTo: false,
    };

    constructor(props) {
        super(props);

        const { isHidden } = props;

        this.state = { isHidden };
    }

    componentDidMount() {
        const { _notificationElement } = this;
        const { shouldScrollTo } = this.props;
        const { isHidden } = this.state;

        if (!isHidden && _notificationElement && shouldScrollTo) {
            this._scrollIntoView(_notificationElement);
        }
    }

    componentWillReceiveProps({ isHidden }) {
        this.setState({ isHidden });
    }

    componentDidUpdate() {
        const { _notificationElement } = this;
        const { shouldScrollTo } = this.props;
        const { isHidden } = this.state;

        if (!isHidden && _notificationElement && shouldScrollTo) {
            this._scrollIntoView(_notificationElement);
        }
    }

    _handleCloseClick() {
        this.setState({ isHidden: true });
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    _setNotificationRef(element) {
        this._notificationElement = element;
    }

    _scrollIntoView(element) {
        if (element && element.scrollIntoView) {
            element.scrollIntoView();
        }
    }

    render() {
        if (this.state.isHidden) {
            return null;
        }

        const {
            iconType,
            children,
            hasCloseButton,
            type,
            __containerClassName,

            // `callActionString` was deprecated in favor of `callAction`
            // `callActionClick` was deprecated in favor of `onCallAction`
            callAction,
            callActionString,
            onCallAction,
            callActionClick,
        } = this.props;
        const extraAttrs = getAdditionalProps(this);
        const isOrbital = type === constants.TYPE_ORBITAL;
        const isDefault =
            type === constants.TYPE_NEUTRAL || type === constants.TYPE_WARNING;
        const isSuccess = type === constants.TYPE_SUCCESS;
        const className = classNames(
            {
                'eds-notification-bar--has-close': hasCloseButton,
            },
            'eds-notification-bar',
            `eds-notification-bar--${type}`,
            __containerClassName,
        );
        const storeRef = this._setNotificationRef.bind(this);
        const iconColor = isDefault
            ? constants.ICON_COLOR
            : constants.ICON_INVERSE_COLOR;
        const showIcon = !isOrbital;
        const showClose = hasCloseButton || isOrbital;

        return (
            <div
                {...extraAttrs}
                className={className}
                role="alert"
                data-spec="notification-bar"
                ref={storeRef}
            >
                <NotificationBarIcon
                    shouldShow={showIcon}
                    iconType={iconType}
                    iconColor={iconColor}
                />
                <div className="eds-notification-bar__content">
                    <div className="eds-notification-bar__content-child">
                        {children}
                    </div>
                    <NotificationBarCTA
                        isOrbital={isOrbital}
                        callAction={callAction || callActionString}
                        onClick={onCallAction || callActionClick}
                    />
                </div>
                <NotificationBarClose
                    shouldShow={showClose}
                    onClick={this._handleCloseClick.bind(this)}
                    useInverseStyle={!isDefault && !isSuccess}
                    iconColor={iconColor}
                />
            </div>
        );
    }
}
