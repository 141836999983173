const FACEBOOK_JS_ID = 'facebook-jssdk';
const _getFacebookSdkUrl = (locale) =>
    `//connect.facebook.net/${locale}/sdk.js`;

/**
 * Load facebook-sdk and return a Promise that will be fulfilled
 * either when the script is loaded or when is already donwloaded
 */
export const getSdk = (locale) =>
    new Promise((resolve) => {
        // check if facebook-sdk is already loaded
        if (document.getElementById(FACEBOOK_JS_ID)) {
            resolve();
            return;
        }

        const firstScriptElement = document.getElementsByTagName('script')[0];
        const scriptElement = document.createElement('script');

        // facebook will call this function for us when the script has finish loading
        window.fbAsyncInit = resolve;

        scriptElement.id = FACEBOOK_JS_ID;
        scriptElement.type = 'text/javascript';
        scriptElement.src = _getFacebookSdkUrl(locale);
        firstScriptElement.parentNode.insertBefore(
            scriptElement,
            firstScriptElement,
        );
    });

export const initApplication = (options) =>
    window.FB && window.FB.init(options);

export const openUI = (options) => window.FB && window.FB.ui(options);
