import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Illustration } from '@eb/eds-illustration';

import FollowButton from './FollowButton';
import RandomSuperCrop from './RandomSuperCrop';

import dataPointPolicy from '../utils/dataPointPolicy';
import {
    STANDARD_SIZE,
    MINI_SIZE,
    CARD_SIZES,
    COMPACT_BUTTON_SIZE,
    STANDARD_BUTTON_SIZE,
    STANDARD_BUTTON_STYLE,
    SOLITARY_BUTTON_STYLE,
    viewOrganizerProfileString,
    organizerImageAltText,
} from '../constants';

const ORGANIZER_IMAGE_DIAMETER_STANDARD = '80px';
const ORGANIZER_IMAGE_DIAMETER_MINI = '48px';

const OrganizerProfileImage = ({
    organizerId,
    imageUrl,
    organizerName,
    size,
}) => {
    let organizerImage = null;

    let organizerImageSize = ORGANIZER_IMAGE_DIAMETER_STANDARD;

    if (size === MINI_SIZE) {
        organizerImageSize = ORGANIZER_IMAGE_DIAMETER_MINI;
    }

    if (imageUrl) {
        organizerImage = (
            <img
                className="organizer-card__image"
                src={imageUrl}
                width={organizerImageSize}
                height={organizerImageSize}
                alt={organizerImageAltText(organizerName)}
            />
        );
    } else {
        organizerImage = (
            <div className="organizer-card__image-placeholder">
                <Illustration
                    type={<RandomSuperCrop id={organizerId} />}
                    width={organizerImageSize}
                    height={organizerImageSize}
                />
            </div>
        );
    }

    return (
        <div className="organizer-card__image-container">{organizerImage}</div>
    );
};

const getSubtitle = (
    size,
    customDataPoint,
    numUpcomingEvents,
    numFollowers,
    shouldHideDefaultDataPoint,
) => {
    if (
        !dataPointPolicy.shouldShowDataPoint(
            customDataPoint,
            numUpcomingEvents,
            numFollowers,
            shouldHideDefaultDataPoint,
        )
    ) {
        return null;
    }

    const isStandardSize = size === STANDARD_SIZE;
    const isMiniSize = size === MINI_SIZE;
    const subtitleTextClasses = classnames(
        'organizer-card__subtitle',
        { 'eds-text-bm': isStandardSize },
        { 'eds-text-bs': isMiniSize },
    );

    return (
        <div className="organizer-card__subtitle-container">
            <p className={subtitleTextClasses}>
                {dataPointPolicy.getDataPointText(
                    customDataPoint,
                    numUpcomingEvents,
                    numFollowers,
                )}
            </p>
        </div>
    );
};

const getButtonStyle = (isSolitary) => {
    if (isSolitary) {
        return SOLITARY_BUTTON_STYLE;
    }

    return STANDARD_BUTTON_STYLE;
};
const getButtonSize = (size) => {
    if (size === STANDARD_SIZE) {
        return STANDARD_BUTTON_SIZE;
    }

    return COMPACT_BUTTON_SIZE;
};

export const FollowBlock = ({
    isFollowing,
    organizerId,
    organizerName,
    organizerProfileUrl,
    imageUrl,
    numUpcomingEvents,
    numFollowers,
    customDataPoint,
    onHandleOnClick = () => ({}),
    shouldHideDefaultDataPoint,
    onFollow,
    onUnFollow,
    size,
    isSolitary,
    isFollowButtonDisabled,
}) => {
    const isStandardSize = size === STANDARD_SIZE;
    const isMiniSize = size === MINI_SIZE;
    const organizerCardTitleClasses = classnames(
        'organizer-card__title',
        { 'eds-text-bl': isStandardSize },
        { 'eds-text-bm eds-text-weight--heavy': isMiniSize },
    );

    return (
        <div className="organizer-card__block">
            <div className="organizer-card__image-and-text">
                <a
                    onClick={onHandleOnClick}
                    href={organizerProfileUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="organizer-card__link organizer-card__image-link"
                    title={viewOrganizerProfileString(organizerName)}
                    tabIndex="-1"
                >
                    <OrganizerProfileImage
                        imageUrl={imageUrl}
                        organizerName={organizerName}
                        organizerId={organizerId}
                        size={size}
                    />
                </a>

                <a
                    onClick={onHandleOnClick}
                    href={organizerProfileUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="organizer-card__link organizer-card__title-link"
                    title={viewOrganizerProfileString(organizerName)}
                >
                    <div className="organizer-card__title-container">
                        <h4 className={organizerCardTitleClasses}>
                            {organizerName}
                        </h4>
                    </div>
                    {getSubtitle(
                        size,
                        customDataPoint,
                        numUpcomingEvents,
                        numFollowers,
                        shouldHideDefaultDataPoint,
                    )}
                </a>
            </div>

            <FollowButton
                __containerClassName="organizer-card__cta"
                isFollowing={isFollowing}
                onFollow={onFollow}
                onUnFollow={onUnFollow}
                organizerName={organizerName}
                style={getButtonStyle(isSolitary)}
                size={getButtonSize(size)}
                isDisabled={isFollowButtonDisabled}
            />
        </div>
    );
};

FollowBlock.propTypes = {
    isFollowing: PropTypes.bool,
    organizerId: PropTypes.string.isRequired,
    organizerName: PropTypes.string.isRequired,
    organizerProfileUrl: PropTypes.string.isRequired,
    onFollow: PropTypes.func.isRequired,
    onUnFollow: PropTypes.func.isRequired,
    size: PropTypes.oneOf(CARD_SIZES).isRequired,
    imageUrl: PropTypes.string,
    numUpcomingEvents: PropTypes.number,
    numFollowers: PropTypes.number,
    customDataPoint: PropTypes.string,
    shouldHideDefaultDataPoint: PropTypes.bool,
    onHandleOnClick: PropTypes.func,
    isSolitary: PropTypes.bool,
};

export default FollowBlock;
