import i18next from 'i18next';
import { GenericLazyString } from './genericLazyString';

export interface RequireContext {
    keys(): string[];
    (id: string): any;
    <T>(id: string): T;
    resolve(id: string): string;
}

const LOCALE_FROM_FILENAME_REGEX = /^\.\/(.*)\.json$/;
export const i18n = i18next.createInstance();

/**
 * I18n initialization
 *  - Uses all available translations downloaded from Transifex at build time.
 *  - If a locale is provided like 'es-ES' is not found, then an 'es' translation will be looked up.
 *  - If no 'es' translation is found, then an 'en' translation will be looked up
 *  - If no 'en' translation is found, then the provided key will be returned as is.
 */
i18n.init({
    lng: 'en',
    fallbackLng: 'en',
    returnEmptyString: false,
    keySeparator: false,
    nsSeparator: false,
    pluralSeparator: void 0,
    contextSeparator: void 0,
    initImmediate: false, // This one prevents a setTimeout(fn, 0)
    interpolation: {
        escapeValue: false,
        prefix: '%(',
        suffix: ')s',
    },
});

/**
 * Locale files generated by transifex tooling always use '_' as the region separator,
 * but i18next expects them to be to be separated by '-'.
 * @param {string} locale the locale string to normalize
 */
const normalizeLocale = (locale: string) => locale.replace('_', '-');

/**
 * isPlural returns true if the passed in count should be expressed as a plural in the current active
 * language
 *
 * @param {number} count a quantity to check if it should be expressed as plural.
 */
export const isPlural = (count: number) =>
    i18n.services.pluralResolver.getSuffix(i18n.language, count) === 'plural';

/**
 * gettext is the main translation function.
 *
 * @param {string} key the key, string or template string of the message to translate
 * @param {object} interpolation an object used for interpolation in the template string.
 * @returns a GenericLazyString instance that will resolve to the active language when used.
 */
export const gettext = (key: string, interpolation?: object) =>
    new GenericLazyString(() => {
        if (!interpolation) {
            /*
             * When no interpolation object is provided, we need to preserve the placeholders
             * This is for backward compatibility with existing code relying on this behavior.
             */
            return i18n.t(key, {
                interpolation: { suffix: '####', prefix: '####' },
            });
        }

        return i18n.t(key, interpolation);
    });

/**
 * ngettext returns singularString is the amount passed in 'number' qualifies as singular
 * given the set locale, returns pluralString otherwise.
 *
 * NB: ngettext DOES NOT send strings to Transifex for translation. In order to have strings
 * translated, you'll have to swrap them in lazyGettext or gettext
 *
 * @param {string} singularString String to display if count is singular.
 * @param {string} pluralString String to display if count is plural.
 * @param {number} count a quantity to check if it should be expressed as plural.
 */
export const ngettext = (
    singularString: string,
    pluralString: string,
    count: number,
) => (isPlural(count) ? pluralString : singularString);

/**
 * lazyGettex is just re-ing gettext for backward compatibility. You should use gettext() instead of this.
 * @deprecated Use `gettext` instead
 */
export const lazyGettext = gettext;

/**
 * Changes the active language for all subsequent calls to gettext()
 *
 * @param {string} language the new desired language for translations
 */
export const setLanguage = (language: string) =>
    i18n.changeLanguage(normalizeLocale(language));

export const setup = (context: RequireContext) => {
    const loadLocales = (context: RequireContext) => {
        const translations: { [key: string]: any } = {};

        context.keys().forEach((locale: string) => {
            const [, localeName] = locale.match(LOCALE_FROM_FILENAME_REGEX);

            const normalizedLocaleName = normalizeLocale(localeName);

            translations[normalizedLocaleName] = {
                ...context(locale),
            };
        });

        return translations;
    };

    const translations = loadLocales(context);
    Object.keys(translations).forEach((key) => {
        i18n.addResourceBundle(key, 'translation', translations[key]);
    });
};
