import Cookies from 'cookies-js';
import { sdkRequest, setWindowLocation } from '@eb/http';
import { formatUrl } from 'url-lib';
import { EVENT_COOKIE } from '../../constants';

const saveEventUrl = '/api/v3/destination/save/';
const unsaveEventUrl = '/api/v3/destination/unsave/';

const _saveOrUnsaveEvent = async (url, eventId) =>
    await sdkRequest(url, {
        method: 'POST',
        body: JSON.stringify({
            event_ids: [`${eventId}`],
        }),
    });

export const saveEvent = async (eventId) =>
    await _saveOrUnsaveEvent(saveEventUrl, eventId);
export const unsaveEvent = async (eventId) =>
    await _saveOrUnsaveEvent(unsaveEventUrl, eventId);

export const loggedOutSaveRedirect = (eventId, { pathname, query }) => {
    // save the cookie with organizer information
    Cookies.set(EVENT_COOKIE, eventId, {
        path: pathname,
        secure: true,
        expires: 300,
    });

    // then redirect to signin page
    setWindowLocation(
        formatUrl('/signin/', {
            referrer: formatUrl(pathname, query),
        }),
    );

    return Promise.resolve();
};
