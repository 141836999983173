import {
    ADD_EVENT_TO_COLLECTION,
    CREATE_COLLECTION,
    REMOVE_EVENT_FROM_COLLECTION,
    REACH_MAX_LIMIT,
} from './actionTypes';
import {
    ADD_TO_COLLECTION_GA_ACTION,
    REMOVE_FROM_COLLECTION_GA_ACTION,
    REACHED_MAX_EVENTS_GA_ACTION,
    CREATE_COLLECTION_GA_ACTION,
} from './constants';

export const trackCreateCollection = (id, category) => ({
    type: CREATE_COLLECTION,
    meta: {
        analytics: {
            action: CREATE_COLLECTION_GA_ACTION,
            label: id,
            category,
        },
    },
});

export const trackAddToCollection = (id, category) => ({
    type: ADD_EVENT_TO_COLLECTION,
    meta: {
        analytics: {
            action: ADD_TO_COLLECTION_GA_ACTION,
            label: id,
            category,
        },
    },
});

export const trackRemoveFromCollection = (id, category) => ({
    type: REMOVE_EVENT_FROM_COLLECTION,
    meta: {
        analytics: {
            action: REMOVE_FROM_COLLECTION_GA_ACTION,
            label: id,
            category,
        },
    },
});

export const trackMaxEventAdded = (id, category) => ({
    type: REACH_MAX_LIMIT,
    meta: {
        analytics: {
            action: REACHED_MAX_EVENTS_GA_ACTION,
            label: id,
            category,
        },
    },
});
