import * as React from 'react';

const MenuChunkySvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 24 24" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 17h16v2H4v-2zM4 11h16v2H4zM4 5h16v2H4z"
        />
    </svg>
);

MenuChunkySvg.displayName = 'MenuChunkySvg';
export default MenuChunkySvg;
