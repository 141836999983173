import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
// https://github.com/ScottHamper/Cookies
import cookies from 'cookies-js';
import { translationPropType } from '@eb/i18n';
import { moment } from '@eb/date';
import NotificationBar from './NotificationBar';
import { Link } from '@eb/eds-link';
import * as constants from './constants';

// Depending on the quarter, determines the month on which the cookie expires
// i.e. Q3 cookies expires on the 1st of October(10)
const EXPIRATION_MONTHS = {
    1: 4,
    2: 7,
    3: 10,
    4: 1,
};

const getExpirationDate = () => {
    const date = new Date();
    const quarter = moment(date).quarter();
    const expirationMonth = EXPIRATION_MONTHS[quarter];
    const expirationYear =
        quarter === 4 ? date.getFullYear() + 1 : date.getFullYear();
    // Subtract 1 from expirationMonth to return to 0-based months
    const expirationDate = new Date(expirationYear, expirationMonth - 1, 1);

    return expirationDate;
};

const getCookieConfig = () => ({ path: '/', expires: getExpirationDate() });

export default class CookieNotification extends PureComponent {
    static propTypes = {
        /**
         * The name of cookie that cookies-js attempts to retrieve
         */
        cookieName: PropTypes.string.isRequired,
        /**
         * The href to help article about cookies
         */
        linkHref: PropTypes.string.isRequired,
        /**
         * The main text that is shown on the cookie notification bar
         */
        message: translationPropType.isRequired,
        /**
         * The text on the primary action button
         */
        actionText: translationPropType,
        /**
         * Text that will be linked to the help article about cookies
         */
        linkText: translationPropType,
        /**
         * Callback for when the user closes the notification
         */
        onClose: PropTypes.func,
        /**
         * Callback for when the user accepts the notification
         */
        onCallAction: PropTypes.func,
    };

    static defaultProps = {
        actionText: constants.actionText,
        linkText: constants.linkText,
    };

    state = {
        isHidden: false,
    };

    _handleClose = () => {
        const { onClose } = this.props;

        if (onClose) {
            onClose();
        }

        this._handleSetCookieWithExpiry();
    };

    _handleCallAction = () => {
        const { onCallAction } = this.props;

        if (onCallAction) {
            onCallAction();
        }

        this._handleSetCookieWithExpiry();
    };

    _handleSetCookieWithExpiry = () => {
        const { cookieName } = this.props;

        if (!cookies.get(cookieName)) {
            cookies.set(cookieName, 'true', getCookieConfig());
        }

        this.setState({ isHidden: true });
    };

    render() {
        const { actionText, linkText, linkHref, message } = this.props;
        const { isHidden } = this.state;

        return (
            <NotificationBar
                hasCloseButton={true}
                isHidden={isHidden}
                type={constants.TYPE_ORBITAL}
                callAction={actionText}
                onCallAction={this._handleCallAction}
                onClose={this._handleClose}
            >
                <span data-spec="cookie-notification-message">{`${message} `}</span>
                <Link data-spec="cookie-notification-link" to={linkHref}>
                    {linkText}
                </Link>
            </NotificationBar>
        );
    }
}
