import { combineReducers } from 'redux';

import { omit } from 'lodash';

import {
    SET_COLLECTIONS,
    UPDATE_COLLECTION,
    UPDATE_COLLECTION_EVENTS,
    DELETE_COLLECTION,
    CLEAR_COLLECTIONS,
} from './actionTypes';

/*
state.collections: {
    123123: {
        events: [12,3,4,5],
        name: Cool Collection,
        secret: 234234,
        id: 123123,
    },
    123124: {
        events: [12,3,4,5],
        name: Cool Collection,
        secret: 234234,
        id: 123123,
    }
}
*/
export const entities = (state = {}, { type, payload }) => {
    let nextState = state;

    if (type === CLEAR_COLLECTIONS) {
        nextState = {};
    }

    if (type === SET_COLLECTIONS) {
        nextState = {
            ...nextState,
            ...payload.entities,
        };
    }

    if (type === UPDATE_COLLECTION) {
        nextState = {
            ...nextState,
            [payload.id]: {
                ...nextState[payload.id],
                ...payload,
            },
        };
    }

    if (type === UPDATE_COLLECTION_EVENTS) {
        nextState = {
            ...nextState,
            [payload.collectionId]: {
                ...nextState[payload.collectionId],
                eventCount: payload.shouldAdd
                    ? (nextState[payload.collectionId].eventCount += 1)
                    : (nextState[payload.collectionId].eventCount -= 1),
            },
        };
    }

    if (type === DELETE_COLLECTION) {
        nextState = omit(nextState, payload);
    }

    return nextState;
};

export const hasMoreItems = (state = '', { type, payload }) => {
    let nextState = state;

    if (type === SET_COLLECTIONS && payload.hasMoreItems) {
        nextState = payload.hasMoreItems;
    }

    return nextState;
};

export const continuation = (state = '', { type, payload }) => {
    let nextState = state;

    if (type === SET_COLLECTIONS && payload.continuation) {
        nextState = payload.continuation;
    }

    return nextState;
};

export const hasMoreFollowed = (state = false, { type, payload }) => {
    let nextState = state;

    if (type === SET_COLLECTIONS && 'hasMoreFollowed' in payload) {
        nextState = payload.hasMoreFollowed;
    }

    return nextState;
};

export const continuationFollowed = (state = '', { type, payload }) => {
    let nextState = state;

    if (type === SET_COLLECTIONS && payload.continuationFollowed) {
        nextState = payload.continuationFollowed;
    }

    return nextState;
};

export const hasMoreForPlace = (state = false, { type, payload }) => {
    let nextState = state;

    if (type === SET_COLLECTIONS && 'hasMoreForPlace' in payload) {
        nextState = payload.hasMoreForPlace;
    }

    return nextState;
};

export const continuationForPlace = (state = '', { type, payload }) => {
    let nextState = state;

    if (type === SET_COLLECTIONS && payload.continuationForPlace) {
        nextState = payload.continuationForPlace;
    }

    return nextState;
};

export default combineReducers({
    entities,
    hasMoreItems,
    continuation,
    hasMoreFollowed,
    continuationFollowed,
    hasMoreForPlace,
    continuationForPlace,
});
