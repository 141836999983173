import {
    SET_WAITING_FOR_LOCATION,
    LOCATION_DENIED,
    UPDATE_LOCATION,
} from './actionTypes';

export const setWaitingForLocation = (isWaitingForLocation) => ({
    type: SET_WAITING_FOR_LOCATION,
    payload: isWaitingForLocation,
});

export const showLocationDeniedNotification = () => ({
    type: LOCATION_DENIED,
    payload: true,
});

export const updateLocation = ({
    slug,
    placeId,
    latitude,
    longitude,
    bbox,
    currentPlace,
    currentPlaceParent,
    placeType,
    city,
    region,
    isUsingCurrentLocation,
    isOnline,
}) => ({
    type: UPDATE_LOCATION,
    payload: {
        slug,
        placeId,
        latitude,
        longitude,
        bbox,
        currentPlace,
        currentPlaceParent,
        placeType,
        city,
        region,
        isUsingCurrentLocation,
        isOnline,
    },
});
