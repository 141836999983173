import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icon } from '@eb/eds-icon';
import * as constants from './constants';
import { calculateTextProperties, generateInitials } from './util';
import { COLORS_PROP_TYPE } from '@eb/eds-color';
import { ICON_TYPE_PROP_TYPE } from '@eb/eds-icon';
import { GREYS_JSON } from '@eb/eds-color';
import { Initials } from './_internals/Initials';
import { Image } from './_internals/Image';

import './avatar.scss';

const MainContent = ({
    text,
    maxChars,
    textColor,
    numericSize,
    iconType,
    iconColor,
    size,
    imageUrl,
    x,
    y,
    fontSize,
}) => {
    let content = null;

    // Prioritize for images
    if (imageUrl) {
        content = <Image imageUrl={imageUrl} size={numericSize} />;
    } else if (iconType) {
        // Use icon if no image is available. We need foreignObject to insert HTML into SVG
        content = (
            <foreignObject width={numericSize} height={numericSize}>
                <div className="eds-avatar__icon-container eds-align--center">
                    <Icon
                        type={iconType}
                        isBlockLevel={true}
                        size={size}
                        color={iconColor}
                    />
                </div>
            </foreignObject>
        );
    } else if (text) {
        // Use text/initials if no image or icon is available
        content = (
            <Initials x={x} y={y} fontSize={fontSize} textColor={textColor}>
                {generateInitials(text, maxChars)}
            </Initials>
        );
    }

    return content;
};

export default class Avatar extends PureComponent {
    static propTypes = {
        /**
         * The text used to generate the initials/abbreviation
         */
        text: PropTypes.string,
        /**
         * Size of the avatar xsmall, small, medium, large
         */
        size: PropTypes.oneOf(constants.SIZES),
        /**
         * Overrides the background color with any valid EDS color value
         */
        backgroundColor: COLORS_PROP_TYPE,
        /**
         * Overrides the text color with any EDS greyscale color value
         */
        textColor: PropTypes.oneOf([...Object.keys(GREYS_JSON)]),
        /**
         * Type of icon to display in the Avatar
         */
        iconType: ICON_TYPE_PROP_TYPE,
        /**
         * Color of icon to display in the Avatar
         */
        iconColor: COLORS_PROP_TYPE,
        /**
         * Url for the image asset to display in the avatar
         */
        imageUrl: PropTypes.string,
        /**
         * callback to execute if the avatar is clicked
         */
        onClick: PropTypes.func,
        /**
         * callback to execute if the avatar is hovered
         */
        onHover: PropTypes.func,
        /**
         * optional prop to allow for more/less characters in the avatar
         */
        maxChars: PropTypes.number,
    };

    static defaultProps = {
        size: 'medium',
        maxChars: 2,
    };

    _handleClick = () => {
        const { onClick } = this.props;

        if (onClick) {
            onClick();
        }
    };

    _handleMouseEnter = () => {
        const { onHover } = this.props;

        if (onHover) {
            onHover(true);
        }
    };

    _handleMouseLeave = () => {
        const { onHover } = this.props;

        if (onHover) {
            onHover(false);
        }
    };

    render() {
        const { size, backgroundColor, ...additionalProps } = this.props;
        // translate semantic size to numeric size
        const numericSize = constants.AVATAR_SIZES[size];
        const textProperties = calculateTextProperties(numericSize);
        // Add border to avatars that use an image, have no background colour set, or have white background colour
        // See JIRA EB-83954
        const hasBorder =
            additionalProps.imageUrl ||
            backgroundColor === 'white' ||
            !backgroundColor;
        const backgroundClassName = classNames('eds-avatar__background', {
            [`eds-bg-color--${backgroundColor}`]: backgroundColor,
            'eds-avatar__background--has-border': hasBorder,
        });
        const mainContentProps = {
            ...additionalProps,
            ...textProperties,
            size,
            numericSize,
        };

        return (
            <svg
                className={backgroundClassName}
                height={numericSize}
                width={numericSize}
                onClick={this._handleClick}
                onMouseEnter={this._handleMouseEnter}
                onMouseLeave={this._handleMouseLeave}
                data-spec="spec-avatar"
            >
                <MainContent {...mainContentProps} />
            </svg>
        );
    }
}
