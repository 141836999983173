import PropTypes from 'prop-types';

export const SHARE_ON_EMAIL = 'email';
export const SHARE_ON_FACEBOOK = 'facebook';
export const SHARE_ON_FACEBOOK_MESSENGER = 'facebookMessenger';
export const SHARE_ON_TWITTER = 'twitter';

// share components should be ordered as: facebook, facebookMessenger, twitter, email
// don't change the order unless you're totally sure about what you're doing
export const SHARE_TYPES = [
    SHARE_ON_FACEBOOK,
    SHARE_ON_FACEBOOK_MESSENGER,
    SHARE_ON_TWITTER,
    SHARE_ON_EMAIL,
];

export const DEFAULT_UTM_OPTIONS = {
    'utm-campaign': 'social',
    'utm-content': 'attendeeshare',
    'utm-medium': 'discovery',
    'utm-source': '',
    'utm-term': 'checkoutwidget',
};

export const UTM_SHARE_TYPE_MAP = {
    [SHARE_ON_FACEBOOK]: { 'utm-source': 'fb' },
    [SHARE_ON_FACEBOOK_MESSENGER]: { 'utm-source': 'fbm' },
    [SHARE_ON_TWITTER]: { 'utm-source': 'tw' },
    [SHARE_ON_EMAIL]: {
        'utm-campaign': 'social,email',
        'utm-source': 'strongmail',
    },
};

export const FACEBOOK_PROP_TYPE_SHAPE = PropTypes.shape({
    appId: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    page: PropTypes.string.isRequired,
    version: PropTypes.string.isRequired,
    xfbml: PropTypes.bool.isRequired,
});

export const UTM_OPTION_PROPTYPE = PropTypes.shape({
    'utm-campaign': PropTypes.string,
    'utm-content': PropTypes.string,
    'utm-medium': PropTypes.string,
    'utm-source': PropTypes.string,
    'utm-term': PropTypes.string,
});
