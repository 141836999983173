import _ from 'lodash';
import Cookies from 'cookies-js';

import { HAS_WINDOW } from '@eb/eds-utils';
import { getWindowObject } from '@eb/feature-detection';
import { logCheckpointOnPageChange, logCheckpointViaAjax } from '@eb/janus';

import {
    ANALYTICS_LABEL_MAP,
    DIMENSIONS,
    EB_GA_PROPERTY_FOR_GA,
    METRICS,
    TRACK_EVENT_REQUIRED_PROPS,
} from './constants';
import { setInternalUseGACookie } from './set-internal-use-ga-cookie';

const PENDING_TRACKING_COOKIE_NAME = 'ebEventToTrack';

/** This method purposefully does not supply a default argument for the gaSettings.
 * We want every app to supply their gaSettings as appropriate every time we do tracking.
 * Without that, we aren't consistently tracking user interactions across the site.
 */

const _getDimensionsFromState = ({
    request,
    eventId,
    eid,
    gaSettings,
    user,
}) => ({
    eventId: eventId || eid,
    userId: gaSettings.userPartnerId,
    guestId: gaSettings.guestPartnerId,
    correlationId: gaSettings.correlationId,
    activeScopeUserId: gaSettings.activeScopeUserId,
    appName: gaSettings.appName,
    appVersion: gaSettings.appVersion,
    sessionId: request && request.session_id,
});

const _pageDimensions = {};

export const addDimensions = (dimensions) =>
    _.extend(_pageDimensions, dimensions);
export const initDimensionsFromState = (state = {}) =>
    addDimensions(_getDimensionsFromState(state));
export const clearDimensions = () => {
    for (const dim in _pageDimensions) {
        if (_pageDimensions.hasOwnProperty(dim)) {
            delete _pageDimensions[dim];
        }
    }
};

const _getPathFromWindow = () => {
    if (HAS_WINDOW) {
        return window.location.pathname;
    }

    return '';
};

// NOTE: Adapted from ebapps.analytics.helpers.global_header_analytics_events_attrs
const _getLabelFromRequest = (requestPath) => {
    let eventLabel = '';
    const locationPath = requestPath || _getPathFromWindow();

    if (locationPath) {
        // need to look in ANALYTICS_LABEL_MAP to see if one of its keys are a
        // matching sub path. If so use the value as the event label.
        eventLabel = _.find(
            ANALYTICS_LABEL_MAP,
            (labelValue, requestSubPath) =>
                locationPath.indexOf(requestSubPath) > -1,
        );

        // Special case for home page
        if (!eventLabel && locationPath === '/') {
            eventLabel = 'home';
        }
    }

    return eventLabel;
};

/**
 * Pull Google Analytics API options out of the properties
 *
 * @param  {object} properties
 */
const _gaPropertiesFromEBProperties = (properties) => {
    let gaProperties = _.pick(properties, EB_GA_PROPERTY_FOR_GA);

    if (
        _.has(properties, 'fromCookie') ||
        _.has(properties, 'nonInteraction')
    ) {
        gaProperties = _.extend({}, gaProperties, {
            nonInteraction: true,
        });
    }

    return gaProperties;
};

const _gaPrefixFromTrackerName = (trackerName) =>
    _.isEmpty(trackerName) ? '' : `${trackerName}.`;

/**
 * Send a Google Analytics event through the API
 * gaProperties are API options and data being sent (action, label, etc)
 *
 * @param  {object} gaProperties
 * @param  {string} trackerName The optional tracker name
 */
const _sendGoogleAnalyticsAction = (gaProperties, trackerName) => {
    const ga = getWindowObject('ga');
    let prefix;

    if (ga) {
        prefix = _gaPrefixFromTrackerName(trackerName);

        ga(`${prefix}send`, gaProperties);
    } else if (process.env.NODE_ENV === 'development') {
        console.log('Google analytic: ', gaProperties);
    }
};

const _getGaDimensions = (dimensions) =>
    _.reduce(
        dimensions,
        (memo, value, index) => {
            if (DIMENSIONS[index] && typeof value !== 'undefined') {
                return {
                    [DIMENSIONS[index]]: value,
                    ...memo,
                };
            }
            return memo;
        },
        {},
    );

const _getGaMetrics = (metrics) =>
    _.reduce(
        metrics,
        (memo, value, index) => {
            if (METRICS[index] && typeof value !== 'undefined') {
                return {
                    [METRICS[index]]: value,
                    ...memo,
                };
            }
            return memo;
        },
        {},
    );

/**
 * For tracking an event.
 * This is a private method intentionally
 * properties.dimensions should be an array of DIMENSIONS.CONSTANT_NAME('string value').
 * properties.metrics should be an array of METRICS.CONSTANT_NAME(integerValue).
 *
 * @param  {object} properties
 * @param  {string} [trackerName] The optional tracker name
 */
const _trackEvent = (properties, trackerName) => {
    // gaProperties are API options data being sent (action, label, etc)
    let gaProperties = _gaPropertiesFromEBProperties(properties);
    const isMissingRequiredProps = _.some(
        TRACK_EVENT_REQUIRED_PROPS,
        (prop) => !_.has(properties, prop),
    );
    const dimensions = properties.dimensions || {};
    const metrics = properties.metrics || {};

    if (isMissingRequiredProps) {
        return;
    }

    gaProperties = _.extend({}, gaProperties, dimensions, metrics, {
        hitType: 'event',
        eventCategory: properties.category,
        eventAction: properties.action,
        eventLabel: properties.label,
        eventValue: properties.value,
    });
    _sendGoogleAnalyticsAction(gaProperties, trackerName);
};

/**
 * Find pending internal link events and fire off the associated
 * Google Analytics event.
 */
const _trackPendingInternalLink = () => {
    const currentCookieRawValue = Cookies.get(PENDING_TRACKING_COOKIE_NAME);

    if (currentCookieRawValue) {
        try {
            Cookies.expire(PENDING_TRACKING_COOKIE_NAME);
            const trackingData = _.extend(
                {},
                JSON.parse(currentCookieRawValue),
                {
                    fromCookie: true,
                },
            );

            _trackEvent(trackingData);
        } catch (err) {
            // swallow
        }
    }
};

/**
 * For tracking an event from a link that would change the page
 * This is a private method intentionally.
 * @param  {object} properties
 */
const _trackEventFromLink = (properties) => {
    // really nifty feature that's gaining browser support
    // that lets you send beacons even on tab close
    if (navigator && _.isFunction(getWindowObject('navigator').sendBeacon)) {
        _trackEvent(
            _.extend({}, properties, {
                useBeacon: true,
            }),
        );
    } else if (getWindowObject('document')) {
        // We want to make sure the event is fired with as much
        // Correct metadata as possible. So we store all these
        // on the cookie for now.
        let cookieData = properties;

        cookieData = _.extend({}, properties, {
            page: document.location.pathname,
            referrer: document.referrer,
            location: document.location.href,
            hostname: document.location.hostname,
            title: document.title,

            // Get client id from EB's tracker (the default on our
            // pages) which we'll need for sending data on the backend
            // clientId: ga.getByName('t0').get('clientId')
        });

        const expirationDate = new Date();

        expirationDate.setMinutes(expirationDate.getMinutes() + 5);
        const cookieProperties = {
            expires: expirationDate,
            path: '/',
        };

        Cookies.set(
            PENDING_TRACKING_COOKIE_NAME,
            JSON.stringify(cookieData),
            cookieProperties,
        );
    }
};

/**
 * Track a google analytics event, mixing in default dimensions set in the above singleton.
 *
 * @param {object} options
 * @param {string} options.action - GA action name
 * @param {string} options.category - GA action category
 * @param {string} [options.label=null] - GA action label. Will attempt to map from the current request path if not provided.
 * @param {object} [options.dimensions={}] - map of dimension to value. Available dimensions can be found here:
 *     https://github.com/eventbrite/js-utils/blob/4ca167bf85d1819dbb311d29516b3216fea86fe9/src/analytics/constants.js#L17-L49
 * @param {string} [options.requestPath=null] - request path. Will grab from the window if not provided. Used to generate label.
 * @param {boolean} [options.onWindowUnload=false] - whether to track the event on window unload. Pass true if about to change the
 *     window location so that the async call is not interrupted.
 * @param {...object} [options.properties] - Other properties. Options are here:
 *     https://github.com/eventbrite/js-utils/blob/4ca167bf85d1819dbb311d29516b3216fea86fe9/src/analytics/constants.js#L62
 */
export const trackAnalyticsEvent = ({
    action,
    category,
    label = null,
    dimensions = {},
    metrics = {},
    requestPath = null,
    onWindowUnload = false,
    ...properties
}) => {
    const trackFunc = onWindowUnload ? _trackEventFromLink : _trackEvent;

    return trackFunc({
        action,
        category,
        label: label || _getLabelFromRequest(requestPath),
        dimensions: _getGaDimensions({
            ..._pageDimensions,
            ...dimensions,
        }),
        metrics: _getGaMetrics(metrics),
        ...properties,
    });
};

/**
 * Utility function to track a google analytics event while populating dimension values from the state.
 * This allows us to set user and event id from the default state of an EB JS app.
 *
 * @param {object} state - State of the application. Grabs the user and event id from this to populate the
 *     associated dimensions. Should be in format {gaSettings: {userPartnerId: '1007'}, event: {publicEventId: '1003'}}
 * @param {object} options
 * @param {object} [options.dimensions={}] - map of dimension to value. Available dimensions can be found here:
 *     https://github.com/eventbrite/js-utils/blob/4ca167bf85d1819dbb311d29516b3216fea86fe9/src/analytics/constants.js#L17-L49
 * @param {...object} options.properties - Other properties to trackAnalyticsEvent
 */
export const trackEventFromState = (
    state,
    { dimensions = {}, metrics = {}, ...properties } = {},
) =>
    trackAnalyticsEvent({
        dimensions: {
            ..._getDimensionsFromState(state),
            ...dimensions,
        },
        metrics,
        ...properties,
    });

/**
 * Utility function to track a google analytics event on page unload or immediately on the next page request. Useful
 * when tracking an event immediately before changing the window location to ensure that the GA event is fired. Uses
 * the same logic to parse state into dimensions as trackEventFromState
 *
 * @param {object} state - State of the application. Grabs the user and event id from this to populate the
 *     associated dimensions. Should be in format {gaSettings: {userPartnerId: '1007'}, event: {publicEventId: '1003'}}
 * @param {object} properties - See trackEventFromState and trackAnalyticsEvent for available properties
 */
export const trackEventFromStateOnPageUnload = (state, properties) =>
    trackEventFromState(state, { ...properties, onWindowUnload: true });

/**
 * For tracking pageviews. There is a pageview that occurs by default on every page.
 * Use this for ajax refreshes on single page apps
 *
 * @param  {object} properties
 * @param  {string} trackerName The optional tracker name
 */
const _trackPageView = (properties, trackerName) => {
    _sendGoogleAnalyticsAction(
        _.extend({}, properties, {
            hitType: 'pageview',
        }),
        trackerName,
    );
};

export const trackInitialPageView = (
    gaSettings,
    { dimensions = {}, metrics = {}, ...properties } = {},
) => {
    // NOTE: adapted from core/django/templates/shared/google_analytics.html
    // but excluding all of the event-specific logic which now needs to be passed
    // in within `dimensions`
    if (!gaSettings || !gaSettings.isActive) {
        return;
    }

    setInternalUseGACookie();
    _trackPendingInternalLink();
    _trackPageView({
        ..._getGaDimensions({
            urlParams: getWindowObject('location').search,
            ..._pageDimensions,
            ..._getDimensionsFromState({ gaSettings }),
            ...dimensions,
        }),
        ..._getGaMetrics(metrics),
        ...properties,
    });
};

/**
 * Returns a function that when executed (i.e. when the user clicks)
 * will submit a track link google analytics event, optionally marking
 * a Janus checkpoint
 * @param {object} gaOptions
 * @param {string} gaOptions.action - GA action name
 * @param {string} gaOptions.category - GA action category
 * @param {string} [gaOptions.label] - GA action label. Will attempt to map from the current request path if not provided.
 * @param {object} [gaOptions.dimensions] - map of dimension to value. Available dimensions can be found here:
 *     https://github.com/eventbrite/js-utils/blob/4ca167bf85d1819dbb311d29516b3216fea86fe9/src/analytics/constants.js#L17-L49
 * @param {string} [gaOptions.requestPath] - request path. Will grab from the window if not provided. Used to generate label.
 * @param {object} [janusOptions={}]
 * @param {string} janusOptions.checkpointNames - comma-separated list of checkpoint names to log
 * @param {string} janusOptions.domain - domain name
 * @param {boolean} [janusOptions.pageWillChange=true] - Whether or not the user is navigating to a new page when this action is triggered
 * @returns {() => void}
 */
export const getAnalyticsClickHandler = (
    { action, category, requestPath, label, dimensions },
    { checkpointName, domain, pageWillChange = true } = {},
) => () => {
    trackAnalyticsEvent({
        action,
        category,
        requestPath,
        label,
        dimensions,
        onWindowUnload: true,
    });

    if (checkpointName) {
        if (pageWillChange) {
            logCheckpointOnPageChange(checkpointName, domain);
        } else {
            logCheckpointViaAjax(checkpointName);
        }
    }
};
