import { snakeToCamel } from '@eb/string-utils';

/**
 * Recursively transform nested objects into a single flat object with combined
 * keys. {root: {nested: 'value'}} becomes {rootNested: 'value'}
 */
export const flattenObject = <InputObject extends object>(obj: InputObject) => {
    const object: any = obj;

    /* eslint-disable guard-for-in */
    for (const key in object) {
        const originalValue = object[key];

        if (
            typeof originalValue !== 'object' ||
            originalValue === null ||
            !object.hasOwnProperty(key)
        ) {
            continue;
        }

        // for multi-level flattening
        const flattenedValue = flattenObject(originalValue);

        for (const nestedKey in flattenedValue) {
            if (!flattenedValue.hasOwnProperty(nestedKey)) {
                continue;
            }

            object[snakeToCamel(`${key}_${nestedKey}`)] =
                flattenedValue[nestedKey];
        }

        delete object[key];
    }

    return object;
};
