import * as React from 'react';

const ChevronDownChunkySvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 24 24" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 10.2l5 5 5-5-1.4-1.4-3.6 3.6-3.6-3.6z"
        />
    </svg>
);

ChevronDownChunkySvg.displayName = 'ChevronDownChunkySvg';
export default ChevronDownChunkySvg;
