import gettext from '@eb/gettext';
import { GenericLazyString } from '@eb/i18n';

/**
 * LazyString is a class that inherits from GenericLazyString. The difference with GenericLazyString
 * is that in this case, we want it to be bound to 'gettext()'.
 *
 * Ideally this class should be named something like "GettextLazyString", but we are keeping the current name because
 * changing this will probably be a breaking change and should be done more carefully.
 *
 * @param {string} msgid the message or template string
 * @param {object} interpolation an object containing the values to be used in the template string
 */

export class LazyString extends GenericLazyString {
    constructor(msgid: string, interpolation: Function) {
        const translateFn = () => {
            return gettext(msgid, interpolation);
        };

        super(translateFn);
    }
}
