import classNames from 'classnames';
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import { ProgressIndicator } from '@eb/eds-progress-indicator';
import { Icon } from '@eb/eds-icon';
import { CheckChunky } from '@eb/eds-iconography';
import { CrossChunky } from '@eb/eds-iconography';

import * as constants from './constants';
import { isIconComponent, getButtonClassNames } from './utils';

import './button.scss';

const ButtonButton = ({ children, ...componentAttrs }) => (
    <button {...componentAttrs} type="button">
        {children}
    </button>
);

const ButtonSubmit = ({ children, ...componentAttrs }) => (
    <button {...componentAttrs} type="submit">
        {children}
    </button>
);

const ButtonLink = ({ children, ...componentAttrs }) => (
    <a {...componentAttrs}>{children}</a>
);

const Loading = ({ className, style }) => (
    <div className={className}>
        <ProgressIndicator
            shape="circular"
            size="small-chunky"
            style={constants.BUTTON_PROGRESS_STYLE[style]}
        />
    </div>
);

const Success = ({ className }) => (
    <span className={className}>
        <Icon size="small" type={<CheckChunky />} animated={true} />
    </span>
);

const Fail = ({ className }) => (
    <i className={className}>
        <Icon size="small" type={<CrossChunky />} />
    </i>
);

const TYPES_COMPONENT_MAP = {
    [constants.TYPE_BUTTON]: ButtonButton,
    [constants.TYPE_SUBMIT]: ButtonSubmit,
    [constants.TYPE_LINK]: ButtonLink,
};

const ButtonBody = ({
    isLoading,
    isSuccessful,
    isNotSuccessful,
    children,
    style,
}) => {
    let component;
    const className = classNames('eds-btn__children', {
        'eds-fx--fade-out-up ': isLoading,
    });
    const componentClassName = classNames('eds-btn__extra', 'eds-fx--fade-in', {
        'eds-fx--delay-3': isLoading || isNotSuccessful,
    });

    if (isLoading) {
        component = <Loading className={componentClassName} style={style} />;
    }

    if (isSuccessful) {
        component = <Success className={componentClassName} />;
    }

    if (isNotSuccessful) {
        component = <Fail className={componentClassName} />;
    }

    return (
        <div>
            <div className={className}>{children}</div>
            {component}
        </div>
    );
};

export default class Button extends React.PureComponent {
    static propTypes = {
        /**
         * Contents of the button
         */
        children: PropTypes.node.isRequired,
        /**
         * Defines the type of button. (button, submit, or link)
         */
        type: PropTypes.oneOf(constants.TYPES),
        /**
         * Changes the style of the button.
         * Available styles: none, anchor, link, neutral, fill, follow
         */
        style: PropTypes.oneOf(constants.STYLES),
        /**
         * Changes button size
         * Available sizes: responsive, block
         */
        size: PropTypes.oneOf(constants.SIZES),
        /**
         * Set active styles to button
         */
        isFollowing: PropTypes.bool,
        /**
         * Adds a loading spinner to button
         */
        isLoading: PropTypes.bool,
        /**
         * Adds a checkmark to button
         */
        isSuccessful: PropTypes.bool,
        /**
         * Adds a cross to button
         */
        isNotSuccessful: PropTypes.bool,
        /**
         * Callback function invoked when the button is clicked.
         * () => { }
         */
        onClick: PropTypes.func,
        /**
         * Disables the button
         */
        disabled: PropTypes.bool,
        /**
         * is a phab the button
         */
        phab: PropTypes.bool,
        /**
         * Container class name
         */
        __containerClassName: PropTypes.string,
    };

    static defaultProps = {
        type: constants.TYPE_BUTTON,
        style: constants.STYLE_NEUTRAL,
    };

    _handleClick(syntheticEvent) {
        syntheticEvent.stopPropagation();
        // simple wrapper for onClick so that the event object doesn't
        // get passed to the callback

        if (this.props.onClick) {
            syntheticEvent.persist();
            if (this.props.type === constants.TYPE_LINK) {
                syntheticEvent.preventDefault();
            }
            this.props.onClick(syntheticEvent);
        }
    }

    focus() {
        // TODO: This implementation should be revisited so that we hopefully
        // don't need to do this
        // eslint-disable-next-line react/no-find-dom-node
        ReactDOM.findDOMNode(this).focus();
    }

    render() {
        const {
            children,
            isFollowing,
            isLoading,
            isSuccessful,
            isNotSuccessful,
            phab,
            size,
            style,
            type,
            __containerClassName,
            ...extraAttrs
        } = this.props;
        let { disabled } = this.props;

        const componentChildren = React.Children.toArray(children);
        const childCount = componentChildren.length;
        const iconCount = componentChildren.filter(isIconComponent).length;
        const hasIconsOnly = childCount === iconCount;
        const hasIconsAndOtherChildren =
            childCount - iconCount > 0 && iconCount > 0;
        const className = getButtonClassNames({
            children,
            disabled,
            hasIconsAndOtherChildren,
            hasIconsOnly,
            isFollowing,
            isLoading,
            isSuccessful,
            isNotSuccessful,
            phab,
            size,
            style,
            type,
            __containerClassName,
        });

        let onClick;
        let accessibilityProps = {};

        if (disabled || isLoading || isSuccessful || isNotSuccessful) {
            accessibilityProps = {
                ...accessibilityProps,
                'aria-disabled': true,
            };
            disabled = true;
        } else {
            // set click handler if not disabled because
            // anchor-type buttons don't respect disabled attribute
            onClick = this._handleClick.bind(this);
        }

        const componentAttrs = {
            ...extraAttrs,
            className,
            disabled,
            onClick,
        };

        const ButtonSubComponent = TYPES_COMPONENT_MAP[type];

        let buttonBody = children;

        // ButtonBody provides an inner container for showing loading, success, and error states
        // that is otherwise not needed (and in fact, may break components like ExpandableCardContent
        // that depend on children to be the direct descendants of ButtonSubComponent)
        if (isLoading || isSuccessful || isNotSuccessful) {
            buttonBody = (
                <ButtonBody
                    style={style}
                    isLoading={isLoading}
                    isSuccessful={isSuccessful}
                    isNotSuccessful={isNotSuccessful}
                >
                    {children}
                </ButtonBody>
            );
        }

        return (
            <ButtonSubComponent {...componentAttrs} {...accessibilityProps}>
                {buttonBody}
            </ButtonSubComponent>
        );
    }
}
