import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import gettext from '@eb/gettext';

import { onClickOutsideHOC } from '@eb/eds-utils';

import { Theme, THEME_PROP_TYPE } from '@eb/eds-theming';
import { IconButton } from '@eb/eds-icon-button';
import { Divider } from '@eb/eds-divider';
import { NavList } from '@eb/eds-nav-list';
import { Modal } from '@eb/eds-modal';
import { LoadingOverlay } from '@eb/eds-loading-overlay';
import { NotificationBar } from '@eb/eds-notification';
import { Dialog } from '@eb/eds-dialog';
import { FixedBottomBarLayout } from '@eb/eds-containers';

import { ITEMS_PROP_TYPE } from '@eb/eds-nav-list';
import { NOTIFICATION_OPTIONS_PROP_TYPES } from '@eb/eds-notification';
import { ErrorBoundary } from '@eb/error-boundary';
import {
    OVERLAY_OPTIONS_PROP_TYPES,
    FOCUS_DRAWER_OPTIONS_PROP_TYPES,
    BOTTOM_BAR_OPTIONS_PROP_TYPE,
    STRUCTURE_MAIN_CONTAINER_CLASSNAME,
    NOTIFICATION_POSITION_TOAST,
    NOTIFICATION_POSITION_TOP,
} from './constants';

import './structure.scss';

import { CrossChunky } from '@eb/eds-iconography';
import { MenuChunky } from '@eb/eds-iconography';

import { RootErrorFallback } from './RootErrorFallback';

const OVERLAY_TYPE_MAP = {
    dialog: Dialog,
    modal: Modal,
};

const MenuToggle = ({ onToggle, iconType, title }) => (
    <IconButton iconType={iconType} title={title} onClick={onToggle} />
);

const DrawerToggle = ({ onToggle }) => {
    if (!onToggle) {
        return null;
    }

    return (
        <div
            className="eds-text--center"
            data-spec="eds-structure-drawer-toggle"
        >
            <MenuToggle
                onToggle={onToggle}
                iconType={<CrossChunky />}
                title={gettext('Close')}
            />
        </div>
    );
};

const DrawerTitle = ({ children }) => {
    if (!children) {
        return null;
    }

    return (
        <h3
            className="eds-structure__drawer__title"
            data-spec="eds-structure-drawer-title"
        >
            {children}
        </h3>
    );
};

const PageTitle = ({ pageTitle, content }) => (
    <>
        <div className="eds-g-cell eds-l-mar-vert-2 eds-l-mar-left-1 eds-structure__title__heading">
            <h2
                className="eds-structure__title__heading__text"
                data-spec="eds-structure-title-heading"
            >
                {pageTitle}
            </h2>
        </div>
        {content ? (
            <div className="eds-g-cell eds-l-mar-vert-2 eds-structure__title__content">
                {content}
            </div>
        ) : null}
    </>
);

const PageHeader = ({
    component,
    contentComponent,
    onToggleSideDrawer,
    showMenuToggle,
}) => {
    let menuToggle;
    let pageTitle;

    if (component) {
        pageTitle = (
            <PageTitle pageTitle={component} content={contentComponent} />
        );
    }

    if (showMenuToggle) {
        menuToggle = (
            <div
                className="eds-l-mar-vert-2 eds-l-mar-left-2 eds-structure__title__toggle"
                data-spec="eds-structure-sidebar-toggle"
            >
                <MenuToggle
                    onToggle={onToggleSideDrawer}
                    iconType={<MenuChunky />}
                    title={gettext('Toggle Navigation')}
                />
            </div>
        );
    }

    if (menuToggle || pageTitle) {
        return (
            <div className="eds-structure__title-container eds-g-group">
                <div
                    className="eds-structure__title"
                    data-spec="eds-structure-title"
                >
                    {menuToggle}
                    {pageTitle}
                </div>
            </div>
        );
    }

    return null;
};

const Notification = ({ options, fullWidth }) => {
    let component = null;

    if (!isEmpty(options)) {
        /* Should stick comes from the AddMainControls HOC to determine
        if the notification component should stick to top of viewport */
        /* isClosing comes from the AddMainControls HOC and the addFocusDrawerControls HOC
        to determine if the notification component should animate out of view */
        const {
            position,
            shouldStick,
            isClosing,
            ...notificationOptions
        } = options;

        const isTop = !position || position === NOTIFICATION_POSITION_TOP;
        const isToast = position === NOTIFICATION_POSITION_TOAST;

        const classes = classNames('eds-structure__notification', {
            'eds-structure__notification--position-top': isTop,
            'eds-structure__notification--position-toast': isToast,
            'eds-structure__notification--sticky': shouldStick,
            'eds-structure__notification--is-closing': isClosing,
            'eds-g-cell-md-10-12': isTop && !fullWidth,
            'eds-g-offset-md-1-12': isTop && !fullWidth,
            'eds-g-cell-12-12': isTop && fullWidth,
        });

        component = (
            <div className={classes}>
                <NotificationBar {...notificationOptions} />
            </div>
        );
    }

    return component;
};

const DrawerHeader = ({ children, onToggle, hideToggle }) => {
    let drawerToggle;

    if (children || onToggle) {
        const titleClasses = classNames(
            'eds-g-cell',
            'eds-l-pad-top-4',
            'eds-l-pad-bot-3',
            'eds-l-pad-left-6',
            'eds-l-pad-right-2',
            {
                'eds-g-cell-10-12': !hideToggle,
                'eds-g-cell-12-12': hideToggle,
            },
        );

        if (!hideToggle) {
            drawerToggle = (
                <div className="eds-g-cell eds-g-cell-2-12 eds-l-pad-top-2">
                    <DrawerToggle onToggle={onToggle} />
                </div>
            );
        }

        return (
            <div
                className="eds-structure__drawer-header"
                data-spec="eds-structure-drawer-header"
            >
                <div className={titleClasses}>
                    <DrawerTitle>{children}</DrawerTitle>
                </div>
                {drawerToggle}
                <Divider />
            </div>
        );
    }

    return null;
};

const Drawer = onClickOutsideHOC(
    ({
        component,
        title,
        onToggle,
        hideToggle,
        extraClassName,
        notificationOptions,
        dataSpec,
        alwaysInclude,
    }) => {
        let drawer = null;

        // NOTE: For the purposes of initial animation, we may want to always include
        // the drawer container. That way if the drawer content is later added &
        // the drawer is shown, it won't just pop in
        if (component || alwaysInclude) {
            const className = classNames(
                'eds-structure__drawer',
                extraClassName,
            );

            drawer = (
                <aside className={className} data-spec={dataSpec}>
                    <Notification
                        options={notificationOptions}
                        fullWidth={true}
                    />
                    <DrawerHeader onToggle={onToggle} hideToggle={hideToggle}>
                        {title}
                    </DrawerHeader>
                    <div className="eds-structure__drawer-content">
                        {component}
                    </div>
                </aside>
            );
        }

        return drawer;
    },
);

const SideDrawerDescription = ({ component }) => {
    if (!component) {
        return null;
    }

    return (
        <section className="eds-structure__left-drawer-description">
            {component}
        </section>
    );
};

const SideDrawer = ({ items, sideDrawerDescription }) => (
    <div
        className="eds-structure__side-drawer"
        data-spec="eds-structure-side-drawer"
    >
        <SideDrawerDescription component={sideDrawerDescription} />
        <NavList
            data-spec="eds-main-side-nav"
            items={items}
            useAltTheme={true}
            showSymbols={true}
        />
    </div>
);

const Body = ({
    children,
    navItems,
    onSideDrawerDismiss,
    onToggleSideDrawer,
    pageTitle,
    pageTitleContent,
    hasIndependentScrolling,
    sideDrawerDescription,
    notificationOptions,
    footer,
    focusDrawerOptions: {
        content: focusDrawerContent,
        title: focusDrawerTitle,
        hideClose: hideFocusDrawerClose,
        onClose: onFocusDrawerClose,
        bottomBarOptions: focusDrawerBottomBarOptions = {},
        notificationOptions: focusDrawerNotificationOptions,
        onClickOutside: onFocusDrawerClickOutside,
        isShown: focusDrawerIsShown,
    } = {},
    mainBottomBarOptions = {},
}) => {
    let sideDrawer;
    let focusDrawer;
    let showMenuToggle = false;

    // If there are items then show the side drawer
    if (!isEmpty(navItems)) {
        sideDrawer = (
            <SideDrawer
                items={navItems}
                sideDrawerDescription={sideDrawerDescription}
            />
        );
        showMenuToggle = true;
    }

    if (focusDrawerContent) {
        focusDrawer = (
            <div className="eds-structure__focus-drawer-content-wrapper eds-structure__drawer--fade-in">
                <FixedBottomBarLayout {...focusDrawerBottomBarOptions}>
                    {focusDrawerContent}
                </FixedBottomBarLayout>
            </div>
        );

        if (mainBottomBarOptions) {
            // eslint-disable-next-line no-param-reassign
            mainBottomBarOptions = {
                ...mainBottomBarOptions,
                isShown: false,
            };
        }
    }

    const bottomBarWrapperClassName = classNames(
        'eds-structure__fixed-bottom-bar-layout-wrapper',
        {
            'eds-structure__fixed-bottom-bar-layout-wrapper--absolute': hasIndependentScrolling,
        },
    );

    const mainContent = (
        <div className={bottomBarWrapperClassName}>
            <FixedBottomBarLayout
                {...mainBottomBarOptions}
                useFixedPositioning={!hasIndependentScrolling}
            >
                <div className={STRUCTURE_MAIN_CONTAINER_CLASSNAME}>
                    <PageHeader
                        component={pageTitle}
                        contentComponent={pageTitleContent}
                        onToggleSideDrawer={onToggleSideDrawer}
                        showMenuToggle={showMenuToggle}
                    />
                    <main
                        className="eds-structure__main"
                        data-spec="eds-structure-main"
                    >
                        {children}
                    </main>
                    {footer}
                </div>
            </FixedBottomBarLayout>
        </div>
    );

    return (
        <div className="eds-structure__body">
            <Drawer
                component={sideDrawer}
                extraClassName="eds-structure__left-drawer"
                dataSpec="eds-structure-drawer-left"
                onClickOutside={onSideDrawerDismiss}
            />
            <Drawer
                component={focusDrawer}
                title={focusDrawerTitle}
                hideToggle={hideFocusDrawerClose}
                onToggle={onFocusDrawerClose}
                notificationOptions={focusDrawerNotificationOptions}
                dataSpec="eds-structure-drawer-right"
                extraClassName="eds-structure__right-drawer"
                alwaysInclude={true}
                onClickOutside={
                    focusDrawerIsShown ? onFocusDrawerClickOutside : noop
                }
            />
            <div
                className="eds-structure__main-mask"
                data-spec="eds-structure-main-mask"
            >
                <Notification options={notificationOptions} />
                {mainContent}
            </div>
        </div>
    );
};

const Overlay = ({ options }) => {
    let component = null;

    if (options) {
        const { kind, ...overlayOptions } = options;
        const OverlayType = OVERLAY_TYPE_MAP[kind];

        component = <OverlayType {...overlayOptions} />;
    }

    return component;
};

const FixedLoadingOverlay = ({ isLoading }) => {
    let component = null;

    if (isLoading) {
        component = (
            <div className="eds-structure__loading-overlay">
                <LoadingOverlay isShown={isLoading} />
            </div>
        );
    }

    return component;
};

export default class Structure extends React.PureComponent {
    static propTypes = {
        /**
         * Main page content
         */
        children: PropTypes.node.isRequired,
        /**
         * Array of nav list items for display in the side drawer
         */
        navItems: ITEMS_PROP_TYPE,
        /**
         * Pass a header node
         */
        header: PropTypes.node,
        /**
        /**
         * Pass a side drawer description node
         */
        sideDrawerDescription: PropTypes.node,
        /**
         * Pass a footer node
         */
        footer: PropTypes.node,
        /**
         * Page title
         */
        pageTitle: PropTypes.node,
        /**
         * Extra content to append after Page title
         */
        pageTitleContent: PropTypes.node,
        /**
         * Show / hide side drawer
         * `undefined`: use default logic for determining whether it should show
         * `false`: forces side drawer to be closed (overriding any existing state)
         * `true`: forces side drawer to be open (overriding any existing state)
         */
        showSideDrawer: PropTypes.bool,
        /**
         * Function to be invoked when the side drawer's state changes.
         */
        onSideDrawerChange: PropTypes.func,
        /**
         * Makes the Main content area full width
         */
        fullScreenMain: PropTypes.bool,
        /**
         * Gives the Main content area a min height
         */
        noMinHeightMain: PropTypes.bool,
        /**
         * The options used to configure the focus drawer
         */
        focusDrawerOptions: FOCUS_DRAWER_OPTIONS_PROP_TYPES,
        /**
         * The options used to configure an overlay (modal or dialog)
         */
        overlayOptions: OVERLAY_OPTIONS_PROP_TYPES,
        /**
         * The options used to configure a notification
         */
        notificationOptions: NOTIFICATION_OPTIONS_PROP_TYPES,
        /**
         * The options used to configure the fixed bottom bar that sits on top
         * of the main content
         */
        mainBottomBarOptions: BOTTOM_BAR_OPTIONS_PROP_TYPE,
        /**
         * Whether or not the main body and side drawers are independently scrollable,
         * which pins the header to the top
         */
        hasIndependentScrolling: PropTypes.bool,
        /**
         * Whether or not the page is loading and should show a loading overlay
         */
        isLoading: PropTypes.bool,
        /**
         * The root theme to apply to the page structure
         */
        rootTheme: THEME_PROP_TYPE,
    };

    static defaultProps = {
        showSideDrawer: false,
        fullScreenMain: false,
        noMinHeightMain: false,
        hasIndependentScrolling: false,
        isLoading: false,
    };

    constructor(props) {
        super(props);

        const { showSideDrawer } = props;

        this.state = { showSideDrawer };
    }

    componentWillReceiveProps({ showSideDrawer }) {
        this.setState({ showSideDrawer });
    }

    _handleToggleSideDrawer() {
        // NOTE: Due to the nature of the interactions between the showSideDrawer
        // prop, the showSideDrawer internal state, and how we default based
        // open screen size, we need to ensure that the toggled state has been set
        // before calling the handler by passing a callback to setState
        this.setState(
            ({ showSideDrawer: prevShowSideDrawer }) => ({
                showSideDrawer: !prevShowSideDrawer,
            }),
            () => {
                if (this.props.onSideDrawerChange) {
                    this.props.onSideDrawerChange(this.state.showSideDrawer);
                }
            },
        );
    }

    _handleSideDrawerDismiss() {
        // If the side drawer is not open
        if (!this.state.showSideDrawer) {
            return;
        }

        const showSideDrawer = false;

        this.setState({ showSideDrawer });

        if (this.props.onSideDrawerChange) {
            this.props.onSideDrawerChange(showSideDrawer);
        }
    }

    render() {
        const {
            header,
            children,
            overlayOptions,
            notificationOptions,
            footer,
            pageTitle,
            pageTitleContent,
            navItems,
            fullScreenMain,
            noMinHeightMain,
            hasIndependentScrolling,
            sideDrawerDescription,
            focusDrawerOptions,
            mainBottomBarOptions,
            isLoading,
            rootTheme,
        } = this.props;

        const { showSideDrawer } = this.state;
        const className = classNames('eds-structure', {
            'eds-structure--left-open': showSideDrawer,
            'eds-structure--right-open':
                focusDrawerOptions && focusDrawerOptions.isShown,
            'eds-structure--main-bottom-bar-open':
                mainBottomBarOptions && mainBottomBarOptions.isShown,
            'eds-structure--min-height': !noMinHeightMain,
            'eds-structure--independent-scrolling': hasIndependentScrolling,
            'eds-structure--restricted-width': !fullScreenMain,
        });

        return (
            <Theme theme={rootTheme} isRoot={true}>
                {(themeProps) => (
                    <div
                        {...themeProps}
                        className={className}
                        role="region"
                        data-spec="eds-structure"
                    >
                        <div className="eds-structure__header">{header}</div>
                        <Body
                            navItems={navItems}
                            hasIndependentScrolling={hasIndependentScrolling}
                            sideDrawerDescription={sideDrawerDescription}
                            focusDrawerOptions={focusDrawerOptions}
                            pageTitle={pageTitle}
                            pageTitleContent={pageTitleContent}
                            onToggleSideDrawer={this._handleToggleSideDrawer.bind(
                                this,
                            )}
                            onSideDrawerDismiss={this._handleSideDrawerDismiss.bind(
                                this,
                            )}
                            notificationOptions={notificationOptions}
                            footer={footer}
                            mainBottomBarOptions={mainBottomBarOptions}
                        >
                            <ErrorBoundary fallback={<RootErrorFallback />}>
                                {children}
                            </ErrorBoundary>
                        </Body>
                        <Overlay options={overlayOptions} />
                        <FixedLoadingOverlay isLoading={isLoading} />
                    </div>
                )}
            </Theme>
        );
    }
}
