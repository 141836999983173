import React, { Component } from 'react';
import { render } from 'react-dom';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { formatUrl } from 'url-lib';

import { setWindowLocation } from '@eb/http';
import { logCheckpointViaAjax } from '@eb/janus';
import { getUrlPath } from '@eb/path-utils';
import { setAction } from '@eb/redux-destination';

import {
    followOrganizer as followOrganizerAPI,
    unfollowOrganizer as unfollowOrganizerAPI,
    getOrganizer as getOrganizerAPI,
} from '../api/organizer';
import { computeMergedState } from '../utils/state';
import {
    createGDPRContainer,
    createLoginModalContainer,
    createFollowNotificationContainer,
    shouldShowGDPRModal,
    getOrganizerIdFromCookie,
    expireOrganizerCookie,
} from '../utils/modals';

// import FollowButton from '../components/FollowButton';
import FollowNotification from '../components/FollowNotification';
import FollowCard from '../components/FollowCard';
import FollowGDPRModal from '../components/FollowGDPRModal';
import FollowLoginModal from '../components/FollowLoginModal';

import {
    LOGIN_CONFIRMATION_PATH,
    FOLLOW_DONE_CUSTOM_METRIC_DATA,
    FOLLOW_BUTTON_STYLES,
    FOLLOW_LIST_STYLES,
    FOLLOW_READY_CUSTOM_METRIC_MARK,
    FOLLOW_BUTTON_SIZES,
    CARD_SIZES,
    ERROR_FOLLOW,
    ERROR_UNFOLLOW,
    ERROR_ORG_INFO,
    actionToGAActionMap,
} from '../constants';

const getLoggedOutOrganizerData = (organizerId, organizerData = {}) => ({
    ...organizerData[organizerId],
    followedByYou: false,
});
const hasRequiredOrganizerData = ({
    followedByYou,
    id,
    imageUrl,
    name,
    url,
}) => {
    const hasValidFollowedByYou = typeof followedByYou === 'boolean';
    const hasValidId = typeof id === 'string';
    const hasValidImageURL = typeof imageUrl === 'string';
    const hasValidName = typeof name === 'string';
    const hasValidProfileURL = typeof url === 'string';

    return (
        hasValidFollowedByYou &&
        hasValidId &&
        hasValidImageURL &&
        hasValidName &&
        hasValidProfileURL
    );
};
const hasAllData = (organizerData, organizers) => {
    const hasValidOrganizerData = organizerData && !isEmpty(organizerData);
    const hasDataForAllOrganizers =
        Object.keys(organizerData).length === organizers.length;

    if (!hasValidOrganizerData || !hasDataForAllOrganizers) {
        return false;
    }

    return Object.keys(organizerData).every((orgId) =>
        hasRequiredOrganizerData(organizerData[orgId]),
    );
};

export default class FollowMarionette extends Component {
    static propTypes = {
        isGDPRCountry: PropTypes.bool.isRequired,
        organizers: PropTypes.arrayOf(PropTypes.string).isRequired,
        isAuthenticated: PropTypes.bool.isRequired,
        gaCategory: PropTypes.string.isRequired,
        trackGAEvent: PropTypes.func.isRequired,

        getFollowState: PropTypes.func.isRequired,
        setFollowState: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,

        organizerData: PropTypes.objectOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
                url: PropTypes.string.isRequired,
                followedByYou: PropTypes.bool,
                profilePicture: PropTypes.string,
                numUpcomingEvents: PropTypes.number,
                numFollowers: PropTypes.number,
                customDataPoint: PropTypes.string,
            }),
        ),

        loginOptions: PropTypes.shape({
            referrer: PropTypes.string,
            shouldOpenNewTab: PropTypes.bool,
            shouldUseSigninConfirmationRedirect: PropTypes.bool,
        }),

        userId: PropTypes.string,
        gaActionSuffix: PropTypes.string,
        style: PropTypes.oneOf(FOLLOW_BUTTON_STYLES),
        size: PropTypes.oneOf(FOLLOW_BUTTON_SIZES),
        listStyle: PropTypes.oneOf(FOLLOW_LIST_STYLES),
        cardSize: PropTypes.oneOf(CARD_SIZES),
        featureFlags: PropTypes.object,
    };

    static defaultProps = {
        style: 'standard',
        size: 'standard',
        listStyle: 'grid',
        loginOptions: {},
    };

    constructor(props) {
        const { getFollowState, setFollowState } = props;

        super(props);
        const newState = computeMergedState(props);

        setFollowState({
            ...getFollowState(),
            ...newState,
        });
        this.state = { ...newState };
    }

    componentDidMount() {
        this._isMounted = true;

        this.props.onChange(this._handleFollowStateChange);
        this._hydrateOrganizerData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    _handleFollowStateChange = ({ organizerData, organizers }) => {
        this.setState({
            organizers,
            organizerData,
        });
    };

    _handleOnFollowOrganizer = (organizerId, { callback } = {}) => {
        const { isAuthenticated } = this.props;

        if (isAuthenticated) {
            this._followOrganizer(organizerId).then(() => {
                callback && callback();
            });
        } else {
            this._trackFollowAttempt(organizerId);
            this._handleLoggedOutFollow(organizerId);
        }
    };

    _handleOnUnfollowOrganizer = (organizerId, { callback } = {}) => {
        this._unfollowOrganizer(organizerId).then(() => {
            callback && callback();
        });
    };

    _handleError = (organizerId, action) => {
        this._renderNotification(organizerId, action);
    };

    // This function returns a promise so it has to be handled with async
    _followOrganizer = (organizerId) =>
        followOrganizerAPI(organizerId)
            .catch(() => this._handleError(organizerId, ERROR_FOLLOW))
            .then((response) => {
                this._updateFollowState(organizerId, response);
                this._trackFollowEvent(organizerId);
                this._informUser(organizerId);
            });

    // This function returns a promise so it has to be handled with async
    _unfollowOrganizer = (organizerId) =>
        unfollowOrganizerAPI(organizerId)
            .catch(() => this._handleError(organizerId, ERROR_UNFOLLOW))
            .then((response) => {
                this._updateFollowState(organizerId, response);
                this._trackUnfollowEvent(organizerId);
            });

    _handleLoggedOutFollow = (organizerId) => {
        const { loginOptions } = this.props;
        const referrer = loginOptions.referrer || getUrlPath();

        if (loginOptions.shouldUseSigninConfirmationRedirect) {
            this._setUserActionCookie(organizerId, referrer);

            const url = formatUrl(LOGIN_CONFIRMATION_PATH, { referrer });

            if (loginOptions.shouldOpenNewTab) {
                return window.open(url);
            }

            return setWindowLocation(url);
        }

        return this._renderLoginModal(organizerId);
    };

    _setUserActionCookie = (organizerId, referrer) => {
        const { getFollowState } = this.props;
        const { organizerData } = getFollowState();

        setAction({
            action: 'followOrganizer',
            origin: referrer,
            payload: {
                organizerId,
                organizerName: get(organizerData, [organizerId, 'name']),
                image: get(organizerData, [organizerId, 'profileImage']),
            },
        });
    };

    _hydrateOrganizerData = () => {
        const { organizers } = this.state;
        const { isAuthenticated, organizerData } = this.props;
        const isMultiOrganizer = organizers.length > 1;

        if (hasAllData(organizerData, organizers)) {
            organizers.forEach((organizerId) => {
                this._updateState(organizerId, organizerData[organizerId]);
            });
        } else if (isAuthenticated || isMultiOrganizer) {
            organizers.forEach((organizerId) => {
                getOrganizerAPI(organizerId)
                    .catch(this._handleError.bind(null, ERROR_ORG_INFO))
                    .then(this._updateState.bind(null, organizerId));
            });
        } else {
            organizers.forEach((organizerId) => {
                this._updateState(
                    organizerId,
                    getLoggedOutOrganizerData(organizerId, organizerData),
                );
            });
        }
    };

    _updateState = (
        organizerId,
        {
            followedByYou,
            name,
            url,
            image: { url: profilePicture } = {},
            numUpcomingEvents,
            numFollowers,
            imageUrl,
        },
    ) => {
        const { getFollowState, setFollowState } = this.props;

        if (this._isMounted) {
            const currentState = getFollowState();

            setFollowState({
                ...currentState,
                organizerData: {
                    ...currentState.organizerData,
                    [organizerId]: {
                        ...currentState.organizerData[organizerId],
                        id: organizerId,
                        followedByYou,
                        name,
                        url,
                        profilePicture: profilePicture || imageUrl,
                        numUpcomingEvents,
                        numFollowers,
                    },
                },
            });

            this._shouldFollowOrganizerFromCookie(organizerId);
        }
    };

    _informUser = async (organizerId) => {
        this._renderNotification(organizerId);
        const { isGDPRCountry, userId } = this.props;
        const shouldShowModal = await shouldShowGDPRModal(
            isGDPRCountry,
            userId,
        );
        if (shouldShowModal) {
            this.props.trackGAEvent(
                this._getGAEventParamsFor('gdprModal', organizerId),
            );
            this._renderGDPRModal();
        }
    };

    _shouldFollowOrganizerFromCookie = (organizerId) => {
        const { isAuthenticated, getFollowState } = this.props;
        const { organizerData } = getFollowState();
        const organizerIdFromCookie = getOrganizerIdFromCookie();

        if (
            isAuthenticated &&
            organizerData[organizerId] &&
            !organizerData[organizerId].followedByYou &&
            organizerIdFromCookie &&
            organizerIdFromCookie === organizerData[organizerId].id
        ) {
            this._followOrganizer(organizerId);
            this._clearOrganizerCookie();
        }
    };

    _clearOrganizerCookie = () => {
        expireOrganizerCookie();
    };

    _trackFollowEvent = (organizerId) => {
        this.props.trackGAEvent(
            this._getGAEventParamsFor('follow', organizerId),
        );
        logCheckpointViaAjax('follow_organizer');
    };

    _trackFollowAttempt = (organizerId) => {
        this.props.trackGAEvent(
            this._getGAEventParamsFor('followAttempt', organizerId),
        );
    };

    _trackUnfollowEvent = (organizerId) => {
        this.props.trackGAEvent(
            this._getGAEventParamsFor('unfollow', organizerId),
        );
    };

    _getGAEventParamsFor = (action, organizerId) => {
        const { gaCategory, gaActionSuffix } = this.props;
        const { organizerData } = this.state;
        const actionName = actionToGAActionMap[action];
        const dimensions = gaActionSuffix ? { dimension4: gaActionSuffix } : {};

        return {
            action: actionName,
            category: gaCategory,
            label: get(organizerData, [organizerId, 'name']),
            dimensions,
        };
    };

    _updateFollowState = (organizerId, { followedByYou }) => {
        const { setFollowState, getFollowState } = this.props;
        const currentState = getFollowState();

        if (this._isMounted) {
            setFollowState({
                ...currentState,
                organizerData: {
                    ...currentState.organizerData,
                    [organizerId]: {
                        ...currentState.organizerData[organizerId],
                        followedByYou,
                    },
                },
            });
        }
    };

    _renderGDPRModal = () => {
        const {
            trackGAEvent,
            getFollowState,
            setFollowState,
            onChange,
            userId,
            gaCategory,
        } = this.props;

        render(
            <FollowGDPRModal
                userId={userId}
                trackGAEvent={trackGAEvent}
                gaCategory={gaCategory}
                getSharedState={getFollowState}
                setSharedState={setFollowState}
                onChangeSharedState={onChange}
            />,
            createGDPRContainer(),
            () => {
                setFollowState({
                    ...getFollowState(),
                    gdprModalShown: true,
                });
            },
        );
    };

    _renderLoginModal = (organizerId) => {
        const {
            getFollowState,
            setFollowState,
            trackGAEvent,
            onChange,
            loginOptions,
        } = this.props;

        const { organizerData } = getFollowState();
        const organizer = get(organizerData, [organizerId]);

        if (organizer) {
            render(
                <FollowLoginModal
                    organizerId={organizerId}
                    organizerName={get(organizer, 'name')}
                    trackGAEvent={trackGAEvent}
                    organizerProfilePicture={get(
                        organizer,
                        'profilePicture',
                        '',
                    )}
                    getSharedState={getFollowState}
                    setSharedState={setFollowState}
                    onChangeSharedState={onChange}
                    referrer={loginOptions.referrer}
                    shouldOpenNewTab={loginOptions.shouldOpenNewTab}
                />,
                createLoginModalContainer(),
                () => {
                    setFollowState({
                        ...getFollowState(),
                        loginModalShown: true,
                    });
                },
            );
        }
    };

    _renderNotification = (organizerId, errorActionType = '') => {
        const { getFollowState, setFollowState, onChange } = this.props;

        render(
            <FollowNotification
                organizerId={organizerId}
                getSharedState={getFollowState}
                setSharedState={setFollowState}
                onChangeSharedState={onChange}
                errorActionType={errorActionType}
            />,
            createFollowNotificationContainer(),
            () => {
                setFollowState({
                    ...getFollowState(),
                    followNotificationShown: true,
                });
            },
        );
    };

    render() {
        const { style, listStyle, size } = this.props;
        const { organizerData, organizers } = this.state;

        let followComponent = null;

        if (organizers.length > 1) {
            followComponent = (
                <ul className={`follow-list follow-list--${listStyle}`}>
                    {organizers.map((organizerId) => (
                        <li
                            key={get(organizerData, [organizerId, 'id'])}
                            className="follow-list__item"
                        >
                            <FollowCard
                                organizerId={organizerId}
                                organizerName={get(organizerData, [
                                    organizerId,
                                    'name',
                                ])}
                                style={listStyle}
                                isFollowing={get(organizerData, [
                                    organizerId,
                                    'followedByYou',
                                ])}
                                organizerProfileUrl={get(organizerData, [
                                    organizerId,
                                    'url',
                                ])}
                                onFollow={this._handleOnFollowOrganizer.bind(
                                    null,
                                    organizerId,
                                )}
                                onUnFollow={this._handleOnUnfollowOrganizer.bind(
                                    null,
                                    organizerId,
                                )}
                                isClosable={false}
                                isHiddenWhenFollowed={false}
                                numUpcomingEvents={get(organizerData, [
                                    organizerId,
                                    'numUpcomingEvents',
                                ])}
                                numFollowers={get(organizerData, [
                                    organizerId,
                                    'numFollowers',
                                ])}
                                imageUrl={get(organizerData, [
                                    organizerId,
                                    'imageUrl',
                                ])}
                                customDataPoint={get(organizerData, [
                                    organizerId,
                                    'customDataPoint',
                                ])}
                                size={size}
                            />
                        </li>
                    ))}
                </ul>
            );
        } else {
            followComponent = (
                <FollowCard
                    onFollow={this._handleOnFollowOrganizer.bind(
                        null,
                        organizers[0],
                    )}
                    onUnFollow={this._handleOnUnfollowOrganizer.bind(
                        null,
                        organizers[0],
                    )}
                    organizerName={get(organizerData, [organizers[0], 'name'])}
                    style={style}
                    size={size}
                    isFollowing={get(organizerData, [
                        organizers[0],
                        'followedByYou',
                    ])}
                    isOnlyButton={true}
                />
            );
        }

        return followComponent;
    }
}
