import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import pull from 'lodash/pull';

import FollowCard from './FollowCard';

import { cleanOrganizerData } from '../utils/state';

import { FOLLOW_LIST_STYLES, CARD_SIZES } from '../constants';

export default class FollowList extends Component {
    static propTypes = {
        /**
         * Ids of the organizers we will render
         */
        organizers: PropTypes.arrayOf(PropTypes.string).isRequired,
        /**
         * Data about the organizers
         */
        organizerData: PropTypes.objectOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                name: PropTypes.string,
                url: PropTypes.string,
                followedByYou: PropTypes.bool,
                profilePicture: PropTypes.string,
                numUpcomingEvents: PropTypes.number,
                numFollowers: PropTypes.number,
                customDataPoint: PropTypes.string,
            }),
        ),
        /*
         * Style of list to be rendered 'grid' or 'list'
         */
        style: PropTypes.oneOf(FOLLOW_LIST_STYLES).isRequired,
        /*
         * Size of cards to render: 'mini' or 'standard'
         */
        size: PropTypes.oneOf(CARD_SIZES).isRequired,
        /*
         * Callback for when user clicks to follow
         */
        onFollow: PropTypes.func.isRequired,
        /*
         * Callback for when user clicks to unfollow
         */
        onUnFollow: PropTypes.func.isRequired,
        /*
         * Callback for when user closes an organizer
         */
        onRemoveOrganizer: PropTypes.func,
        /*
         * Callback for when user views an organizer
         */
        onViewOrganizer: PropTypes.func,
        /*
         * Callback for when close button is clicked
         */
        onClose: PropTypes.func,
        /*
         * Callback for when no organizers are left to follow
         */
        onEmpty: PropTypes.func,
        /*
         * Boolean if 'X' button appears on cards
         */
        isClosable: PropTypes.bool,
        /*
         * Boolean determining if cards should animate
         * "out" when follow occurs.
         */
        isHiddenWhenFollowed: PropTypes.bool,
        /*
         * If true, it won't show the default message whenever the
         * other data points are not available. This is false by default.
         */
        shouldHideDefaultDataPoint: PropTypes.bool,
    };

    static defaultProps = {
        isHiddenWhenFollowed: false,
        shouldHideDefaultDataPoint: false,
        isClosable: false,
    };

    _handleFollow = ({ organizerId, organizers }, { callback } = {}) => {
        if (this.props.isHiddenWhenFollowed) {
            const newOrganizerList = pull(organizers, organizerId);

            if (this.props.onEmpty && newOrganizerList.length === 0) {
                this.props.onEmpty();
            }
        }

        this.props.onFollow(organizerId, callback);
    };

    _handleUnFollow = ({ organizerId }, { callback } = {}) => {
        this.props.onUnFollow(organizerId, callback);
    };

    _handleOnViewOrganizer = (organizerId) => {
        this.props.onViewOrganizer(organizerId);
    };

    _handleClose = (organizerId, organizers) => {
        const newOrganizerList = pull(organizers, organizerId);

        if (this.props.onClose) {
            this.props.onClose(organizerId, newOrganizerList);
        }
        if (this.props.onEmpty && newOrganizerList.length === 0) {
            this.props.onEmpty();
        }
        if (this.props.onRemoveOrganizer) {
            this.props.onRemoveOrganizer(organizerId);
        }
    };

    render() {
        const {
            style,
            size,
            isClosable,
            isHiddenWhenFollowed,
            shouldHideDefaultDataPoint,
            organizers,
            organizerData,
        } = this.props;
        const {
            organizers: prunedOrganizers,
            organizerData: prunedOrganizerData,
        } = cleanOrganizerData(organizers, organizerData, isHiddenWhenFollowed);

        let followList = <div />;

        if (prunedOrganizers.length) {
            followList = (
                <ul
                    className={`follow-list follow-list--${style} mobile-card-carousel`}
                >
                    {prunedOrganizers.map((organizerId) => (
                        <li key={organizerId} className="follow-list__item">
                            <FollowCard
                                style={style}
                                size={size}
                                isClosable={isClosable}
                                organizerId={organizerId}
                                onFollow={this._handleFollow.bind(null, {
                                    organizerId,
                                    organizers: prunedOrganizers,
                                })}
                                onUnFollow={this._handleUnFollow.bind(null, {
                                    organizerId,
                                })}
                                onClose={this._handleClose.bind(
                                    null,
                                    organizerId,
                                    prunedOrganizers,
                                )}
                                onViewOrganizer={this._handleOnViewOrganizer.bind(
                                    null,
                                    organizerId,
                                )}
                                isFollowing={get(
                                    prunedOrganizerData,
                                    [organizerId, 'followedByYou'],
                                    null,
                                )}
                                organizerName={get(
                                    prunedOrganizerData,
                                    [organizerId, 'name'],
                                    '',
                                )}
                                organizerProfileUrl={get(
                                    prunedOrganizerData,
                                    [organizerId, 'url'],
                                    '',
                                )}
                                numUpcomingEvents={get(
                                    prunedOrganizerData,
                                    [organizerId, 'numUpcomingEvents'],
                                    null,
                                )}
                                numFollowers={get(
                                    prunedOrganizerData,
                                    [organizerId, 'numFollowers'],
                                    null,
                                )}
                                imageUrl={get(
                                    prunedOrganizerData,
                                    [organizerId, 'profilePicture'],
                                    '',
                                )}
                                customDataPoint={get(
                                    prunedOrganizerData,
                                    [organizerId, 'customDataPoint'],
                                    '',
                                )}
                                isHiddenWhenFollowed={isHiddenWhenFollowed}
                                shouldHideDefaultDataPoint={
                                    shouldHideDefaultDataPoint
                                }
                            />
                        </li>
                    ))}
                </ul>
            );
        }

        return <div>{followList}</div>;
    }
}
