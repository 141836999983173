import { sdkRequest } from '@eb/http';
import { formatUrl } from 'url-lib';

import { pruneObject } from '../../utils/transformation';
/*
Summary of the endpoints needed for interacting wtih collections on the user profile page.
*/

export const addToCollectionUrl = (collectionId) =>
    `/api/v3/destination/collections/${collectionId}/events/add/`;

export const addToCollection = (eventId, collectionId) =>
    sdkRequest(addToCollectionUrl(collectionId), {
        method: 'POST',
        body: JSON.stringify({
            event_ids: [eventId],
        }),
    });

export const removeFromCollectionUrl = (collectionId) =>
    `/api/v3/destination/collections/${collectionId}/events/remove/`;

export const removeFromCollection = (eventId, collectionId) =>
    sdkRequest(removeFromCollectionUrl(collectionId), {
        method: 'POST',
        body: JSON.stringify({
            event_ids: [eventId],
        }),
    });

export const getUserCollections = (userId, continuation) =>
    sdkRequest(
        formatUrl('/api/v3/destination/collections/', {
            continuation,
            user_id: userId,
            filter: 'can_curate',
        }),
    );

export const getFollowedCollections = (userId, continuation) =>
    sdkRequest(
        formatUrl(
            `/api/v3/destination/users/${userId}/following/collections/`,
            {
                continuation,
                'expand.collection': ['image_large', 'follow_status'],
            },
        ),
    );

export const getCollectionsForPlace = (placeId, continuation) =>
    sdkRequest(
        formatUrl(
            `/api/v3/destination/collections/${placeId}/editorial-collections`,
            {
                continuation,
                'expand.collection': ['image_large', 'follow_status'],
            },
        ),
    );

export const getCollectionsForOnline = (continuation) =>
    sdkRequest(
        formatUrl('/api/v3/destination/collections/?is_online=true', {
            continuation,
            'expand.collection': ['image_large', 'follow_status'],
        }),
    );

// POST
// Body: {
//     event_ids: [],
//     collection: {
//         name,
//         summary
//     }
// }
// Resppnse: {
//     can_admin: true
//     can_curate: true
//     can_edit: true
//     event_count: 1
//     id: "ccq"
//     image_id: null
//     is_quality: false
//     name: "Vincent's Test Collection"
//     summary: "Oh wow!"
// }
const CREATE_COLLECTION_URL = '/api/v3/destination/collections/';

export const createCollection = (name, eventIds) =>
    sdkRequest(CREATE_COLLECTION_URL, {
        method: 'POST',
        body: JSON.stringify(
            pruneObject({
                collection: {
                    name,
                },
                event_ids: eventIds,
            }),
        ),
    });

export const editCollection = (id, name) =>
    sdkRequest(`${CREATE_COLLECTION_URL}${id}/`, {
        method: 'POST',
        body: JSON.stringify({
            collection: {
                name,
            },
        }),
    });

export const deleteCollection = (id) =>
    sdkRequest(`/api/v3/destination/collections/${id}/delete/`, {
        method: 'POST',
        body: JSON.stringify({
            collection_id: id,
        }),
    });

export const getCollectionEvents = ({
    collectionId,
    continuation,
    filter,
    secret,
    pageSize = 20,
}) =>
    sdkRequest(
        formatUrl(`/api/v3/destination/collections/${collectionId}/events/`, {
            'expand.destination_event': [
                'primary_venue',
                'image',
                'ticket_availability',
                'saves',
                'my_collections',
            ],
            dates: filter,
            page_size: pageSize,
            continuation,
            secret,
        }),
    );

export const followCollectionUrl = (id) =>
    `/api/v3/destination/collections/${id}/follow/`;
export const unfollowCollectionUrl = (id) =>
    `/api/v3/destination/collections/${id}/unfollow/`;

export const followCollection = (collectionId) =>
    sdkRequest(followCollectionUrl(collectionId), { method: 'POST' });
export const unfollowCollection = (collectionId) =>
    sdkRequest(unfollowCollectionUrl(collectionId), { method: 'POST' });
