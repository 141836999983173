import { Schema, arrayOf } from 'normalizr';
import isEmpty from 'lodash/isEmpty';

import { transformUtil } from '@eb/transformation-utils';

// Transforms a given user's collections
// into a normalized by id object.
//
// example URL which response this util handles:
//  /api/v3/destination/collections/?filter=can_curate&user_id=userId
export const transformCollectionResponse = (collectionData = {}) => {
    if (!collectionData || isEmpty(collectionData)) {
        return {
            hasMoreItems: '',
            continuation: '',
            entities: {},
        };
    }

    const {
        collections: collectionsFromResponse = {},
        pagination = {},
    } = collectionData;

    const content = new Schema('collections');

    /* Parse the events into by ID entities */
    const { entities: { collections } = {} } = transformUtil({
        response: { collections: collectionsFromResponse },
        schema: {
            collections: arrayOf(content),
        },
    });

    const { has_more_items: hasMoreItems, continuation } = pagination;

    return {
        hasMoreItems,
        continuation,
        entities: collections,
    };
};
