import { SIZES_JSON } from '@eb/eds-iconography';
import mapValues from 'lodash/mapValues';

export const SIZES = ['xsmall', 'small', 'medium', 'large'];

const AVATAR_SIZE_MAP = SIZES.reduce(
    (memo, size) => ({
        ...memo,
        [size]: SIZES_JSON[`avatar-${size}`],
    }),
    {},
);

export const AVATAR_SIZES = mapValues(AVATAR_SIZE_MAP, (pixelSize) =>
    parseInt(pixelSize, 10),
);
