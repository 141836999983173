const DEFAULT_WINDOW_SETTINGS = {
    name: 'popup',
    width: 550,
    height: 450,
};

const _buildWindowSettings = (width, height) =>
    `toolbar=yes,width=${width},height=${height}`;

export const openUrl = (url) => {
    const { name, width, height } = DEFAULT_WINDOW_SETTINGS;

    window.open(url, name, _buildWindowSettings(width, height));
};
