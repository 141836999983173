import { isEmpty, isObject, isString, omitBy } from 'lodash';

/*
 Receives an object and removes empty objects, arrays, and strings
 but will leave explicit false declarations.

 @param objectToPrune - object
 {gunter: [], rick: false, morty: ''}

 @return object
 {rick: false}
*/
export const pruneObject = (objectToPrune) =>
    omitBy(
        objectToPrune,
        (value) => (isString(value) || isObject(value)) && isEmpty(value),
    );

export const transformGeoPlaceObject = (geoPlace) => {
    const {
        latitude,
        longitude,
        id: placeId,
        location_slug: locationSlug,
        place_type: placeType,
        derived_ancestor_type: parentPlaceType,
        localizations: { default: localeHierarchy = {} } = {},
    } = geoPlace;

    const ancestryMap = {
        locality: 'region',
        region: 'country',
        country: 'continent',
    };

    let currentPlaceParent = '';

    const { full_name: currentPlace } = localeHierarchy[placeType];

    if (parentPlaceType && localeHierarchy[parentPlaceType]) {
        currentPlaceParent = localeHierarchy[parentPlaceType]['full_name'];
    }

    if (ancestryMap[parentPlaceType] && ancestryMap[parentPlaceType]) {
        const parentAncestor = ancestryMap[parentPlaceType];

        if (
            localeHierarchy[parentAncestor] &&
            localeHierarchy[parentAncestor]['full_name']
        ) {
            if (currentPlaceParent) {
                currentPlaceParent = `${currentPlaceParent}, ${
                    localeHierarchy[ancestryMap[parentPlaceType]]['full_name']
                }`;
            } else {
                currentPlaceParent =
                    localeHierarchy[parentPlaceType[ancestryMap]]['full_name'];
            }
        }
    }

    return {
        placeId,
        placeType,
        latitude,
        longitude,
        currentPlace,
        currentPlaceParent,
        slug: locationSlug,
    };
};
