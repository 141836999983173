import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { IconButton } from '@eb/eds-icon-button';
import { CrossChunky } from '@eb/eds-iconography';

import FollowLoadingCard from './FollowLoadingCard';
import FollowButton from './FollowButton';
import { FollowBlock } from './FollowBlock';

import {
    CARD_TYPES,
    GRID_TYPE,
    LIST_TYPE,
    CARD_SIZES,
    MINI_SIZE,
    STANDARD_SIZE,
    CARD_CLOSE_DELAY,
    REMOVE_ORGANIZER_CARD_TEXT,
} from '../constants';

import './FollowCard.scss';

const SampleEventAttachment = ({ startDate, name }) => (
    <div className="follow-page__organizer-card__sample-event eds-l-pad-hor-6 eds-l-pad-vert-4 eds-text-weight--heavy">
        {startDate && (
            <div className="follow-page__organizer-card__sample-event-date">
                {startDate}
            </div>
        )}
        <div className="follow-page__organizer-card__sample-event-name">
            {name.toLocaleUpperCase()}
        </div>
    </div>
);

const isInfoReady = (isFollowing, organizerProfileUrl) =>
    typeof isFollowing === 'boolean' && organizerProfileUrl;

export default class FollowCard extends Component {
    static propTypes = {
        /*
         * ID of the organizer this card represents
         */
        organizerId: PropTypes.string.isRequired,
        /*
         * Style of Card to be rendered 'grid' or 'list'
         */
        style: PropTypes.oneOf(CARD_TYPES).isRequired,
        /*
         * Size of card 'mini' or 'standard'
         */
        size: PropTypes.oneOf(CARD_SIZES).isRequired,
        /*
         * Callback for when user clicks to follow
         */
        onFollow: PropTypes.func.isRequired,
        /*
         * Callback for when user clicks to unfollow
         */
        onUnFollow: PropTypes.func.isRequired,
        /*
         * Name of the organizer
         */
        organizerName: PropTypes.string,
        /*
         * URL to organizer profile
         */
        organizerProfileUrl: PropTypes.string,
        /*
         * Whether user is currently following organizer or not
         */
        isFollowing: PropTypes.bool,
        /*
         * Prop configuring whether or not the card
         * will display background color, border,
         * hover effects, etc.
         *
         * Useful to create a more
         * "embedded" looking card
         */
        isBackgroundHidden: PropTypes.bool,
        /*
         * URL to organizer profile image.
         * Defaults to random super crop
         */
        imageUrl: PropTypes.string,
        /*
         * Boolean determining if card should animate
         * "out" when follow occurs.
         *
         * Useful for if in an array of organizers
         * and want to show more orgs to follow after
         * a given Org is followed
         */
        isHiddenWhenFollowed: PropTypes.bool,
        /*
         * Boolean if 'X' button appears on card
         * allowing the user to dismiss this card.
         *
         * Useful for if in an array of organizers
         * and want to allow user to dismiss a given org
         */
        isClosable: PropTypes.bool,
        /*
         * Callback for when close button is clicked
         */
        onClose: PropTypes.func,
        /*
         * -- Legacy do not use --
         * Callback on onClose before animation finishes running
         */
        onExcludeOrganizer: PropTypes.func,
        /*
         * If passed will render "5 upcoming events" as
         * the default custom data point
         */
        numUpcomingEvents: PropTypes.number,
        /*
         * If passed will render "5 followers" as
         * the second default custom data point
         */
        numFollowers: PropTypes.number,
        /*
         * String rendered underneath organizer name
         * Should provide context and information about
         * the organizer.
         * Takes precedence over numFollowers and numUpcoming events.
         *
         * eg. "Followed by 20 users"
         */
        customDataPoint: PropTypes.string,
        /*
         * If true, it won't show the default message whenever the
         * other data points are not available. This is false by default.
         */
        shouldHideDefaultDataPoint: PropTypes.bool,
        /*
         * Sample of an event by this organizer
         * to render underneath the card
         * providing additional context to the user
         */
        sampleEvent: PropTypes.shape({
            formattedDate: PropTypes.string,
            name: PropTypes.string,
        }),
        /*
         * Callback for when user clicks to view
         * organizer profile.
         */
        onViewOrganizer: PropTypes.func,
        /*
         * Indicates if we want to show the solitary button, meaning
         * that the follow button will be the primary CTA in the page.
         * False by default
         */
        isSolitary: PropTypes.bool,
        /**
         * Allows the card to render only a simple button with no more info
         * but handling the disabled state when doing the call.
         */
        isOnlyButton: PropTypes.bool,
    };

    static defaultProps = {
        isHiddenWhenFollowed: false,
        shouldHideDefaultDataPoint: false,
        isClosable: false,
        isFollowing: null,
        isSolitary: false,
        isFollowButtonDisabled: false,
        isOnlyButton: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            isClosed: false,
            isFollowButtonDisabled: false,
        };
    }

    componentWillUnmount() {
        if (this._followTimeout) {
            clearTimeout(this._followTimeout);
            this._followTimeout = null;
        }

        if (this._closeTimeout) {
            clearTimeout(this._closeTimeout);
            this._closeTimeout = null;
        }
    }

    _handleFollow = () => {
        const {
            organizerId,
            onClose,
            onFollow,
            isHiddenWhenFollowed,
        } = this.props;

        this.setState({
            isFollowButtonDisabled: true,
        });

        onFollow({
            callback: () => {
                this.setState({
                    isFollowButtonDisabled: false,
                });
            },
        });

        if (isHiddenWhenFollowed && onClose) {
            this._followTimeout = setTimeout(() => {
                onClose({ organizerId });
            }, CARD_CLOSE_DELAY);
        }
    };

    _handleUnFollow = () => {
        this.setState({
            isFollowButtonDisabled: true,
        });

        this.props.onUnFollow({
            callback: () => {
                this.setState({
                    isFollowButtonDisabled: false,
                });
            },
        });
    };

    _handleClose = () => {
        const { onClose, onExcludeOrganizer, organizerId } = this.props;

        if (onExcludeOrganizer) {
            onExcludeOrganizer(organizerId);
        }

        this.setState({ isClosed: true });

        if (onClose) {
            this._closeTimeout = setTimeout(() => {
                onClose({ organizerId });
            }, CARD_CLOSE_DELAY);
        }
    };

    _handleOnClick = () => {
        const { organizerId, onViewOrganizer } = this.props;

        if (onViewOrganizer) {
            onViewOrganizer(organizerId);
        }
    };

    render() {
        const {
            organizerId,
            imageUrl,
            organizerProfileUrl,
            organizerName,
            style,
            sampleEvent,
            isHiddenWhenFollowed,
            isClosable,
            numUpcomingEvents,
            numFollowers,
            customDataPoint,
            shouldHideDefaultDataPoint,
            size,
            isBackgroundHidden,
            isFollowing,
            isSolitary,
            isOnlyButton,
        } = this.props;
        const { isClosed, isFollowButtonDisabled } = this.state;
        const shouldBeHidden = isHiddenWhenFollowed
            ? isFollowing || isClosed
            : false;
        const shouldBeFilteredOut =
            !organizerName || (shouldBeHidden && !isClosed);
        const containerClasses = classnames({
            'organizer-card__container': true,
            'eds-fx--fade-out': shouldBeHidden,
        });

        if (isOnlyButton) {
            return (
                <FollowButton
                    __containerClassName="organizer-card__cta"
                    isFollowing={isFollowing}
                    onFollow={this._handleFollow}
                    onUnFollow={this._handleUnFollow}
                    organizerName={organizerName}
                    style={style}
                    size={size}
                    isDisabled={isFollowButtonDisabled}
                />
            );
        }

        if (!isInfoReady(isFollowing, organizerProfileUrl)) {
            return (
                <div className={containerClasses}>
                    <FollowLoadingCard
                        style={style}
                        isBackgroundHidden={isBackgroundHidden}
                    />
                </div>
            );
        }

        if (shouldBeFilteredOut) {
            return null;
        }

        const organizerCardClasses = classnames('organizer-card', {
            'organizer-card--no-bg': isBackgroundHidden,
            'eds-bg-color--background': !isBackgroundHidden,
            'organizer-card--grid': style === GRID_TYPE,
            'organizer-card--list': style === LIST_TYPE,
            'organizer-card--list__mini':
                size === MINI_SIZE && style === LIST_TYPE,
            'organizer-card-list__standard':
                size === STANDARD_SIZE && style === LIST_TYPE,
        });
        const blockSize = style === LIST_TYPE ? size : STANDARD_SIZE;

        return (
            <div className={containerClasses}>
                <div>
                    <section className={organizerCardClasses}>
                        <FollowBlock
                            onHandleOnClick={this._handleOnClick}
                            organizerProfileUrl={organizerProfileUrl}
                            organizerName={organizerName}
                            imageUrl={imageUrl}
                            organizerId={organizerId}
                            isFollowing={isFollowing}
                            onFollow={this._handleFollow}
                            onUnFollow={this._handleUnFollow}
                            numUpcomingEvents={numUpcomingEvents}
                            numFollowers={numFollowers}
                            customDataPoint={customDataPoint}
                            isSolitary={isSolitary}
                            shouldHideDefaultDataPoint={
                                shouldHideDefaultDataPoint
                            }
                            size={blockSize}
                            isFollowButtonDisabled={isFollowButtonDisabled}
                        />
                        {isClosable && (
                            <div className="organizer-card__underlay">
                                <div className="organizer-card__close-btn">
                                    <IconButton
                                        iconType={<CrossChunky />}
                                        iconColor="grey-600"
                                        title={REMOVE_ORGANIZER_CARD_TEXT}
                                        onClick={this._handleClose}
                                    />
                                </div>
                            </div>
                        )}
                    </section>
                    {sampleEvent && (
                        <SampleEventAttachment
                            startDate={sampleEvent.formattedDate}
                            name={sampleEvent.name}
                        />
                    )}
                </div>
            </div>
        );
    }
}
