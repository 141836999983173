export const CLEAR_COLLECTIONS = 'collections/clear_collections';
export const DELETE_COLLECTION = 'collections/delete_collection';
export const SET_COLLECTIONS = 'collections/set_collections';
export const UPDATE_COLLECTION_EVENTS = 'collections/update_collection_events';
export const UPDATE_COLLECTION = 'collections/update_collection';
export const ADD_EVENT_TO_COLLECTION = 'collections/add_event_to_collection';
export const REMOVE_EVENT_FROM_COLLECTION =
    'collections/remove_event_from_collection';
export const CREATE_COLLECTION = 'collections/create_collection';
export const REACH_MAX_LIMIT = 'collections/max_event_limit';
export const BROWSE_EVENTS = 'collections/browse_events';
