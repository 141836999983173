import React from 'react';
import classNames from 'classnames';
import { COLORS_PROP_TYPE } from '@eb/eds-color';
import { DIVIDER_DIRECTIONS_PROP_TYPE } from './constants';

import './divider.scss';

export default class Divider extends React.PureComponent {
    static propTypes = {
        /**
         * Color of divider
         */
        color: COLORS_PROP_TYPE,
        /**
         * Direction of divider (horizontal, vertical)
         */
        direction: DIVIDER_DIRECTIONS_PROP_TYPE,
    };

    static defaultProps = {
        color: 'ui-200',
        direction: 'horizontal',
    };

    render() {
        const { color, direction } = this.props;

        const className = classNames('eds-divider__hr', {
            [`eds-bg-color--${color}`]: color,
            [`eds-divider--${direction}`]: direction,
        });

        return (
            <hr
                className={className}
                data-spec="divider-hr"
                aria-hidden="true"
            />
        );
    }
}
