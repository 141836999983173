import React from 'react';
import PropTypes from 'prop-types';
import gettext from '@eb/gettext';
import { formatUrl } from 'url-lib';
import { IconButton } from '@eb/eds-icon-button';

import { FACEBOOK_PROP_TYPE_SHAPE, UTM_OPTION_PROPTYPE } from './constants';
import { getSdk, initApplication, openUI } from './facebookUtils';
import { openUrl } from './utils';

import { LogoMessenger } from '@eb/eds-iconography';

const _getEventUrl = (eventUrl, utmOptions) =>
    formatUrl(eventUrl, {
        aff: 'esfb',
        ...utmOptions,
    });

const _getFacebookUrl = (eventUrl, appId) =>
    formatUrl('fb-messenger://share', {
        link: _getEventUrl(eventUrl),
        app_id: appId,
    });

export default class FacebookMessengerShare extends React.Component {
    static propTypes = {
        /**
         * Event's name
         */
        eventName: PropTypes.string.isRequired,

        /**
         * Event's url
         */
        eventUrl: PropTypes.string.isRequired,

        /**
         * Facebook options
         */
        facebookOptions: FACEBOOK_PROP_TYPE_SHAPE.isRequired,

        /**
         * Boolean prop to know if we're on a mobile device or not
         */
        isMobile: PropTypes.bool,

        /**
         * Function that onClick of one of the share options, passes
         * back an id of the clicked option
         */
        onClick: PropTypes.func,

        /**
         * utmOptions configured for this shareType
         */
        utmOptions: UTM_OPTION_PROPTYPE,
    };

    componentDidMount() {
        const { locale, ...options } = this.props.facebookOptions;

        // get facebook-sdk as soon as the component is mounted
        getSdk(locale).then(() => initApplication(options));
    }

    _handleButtonClick() {
        const {
            eventName,
            eventUrl,
            facebookOptions: { appId, locale },
            isMobile,
            onClick,
            utmOptions,
        } = this.props;

        if (onClick) {
            onClick();
        }

        if (isMobile) {
            openUrl(_getFacebookUrl(eventUrl, appId));
        } else {
            const description = gettext(
                'Check out "%(eventName)s" @Eventbrite',
                {
                    eventName,
                },
            );
            const options = {
                description,
                method: 'send',
                display: 'popup',
                link: _getEventUrl(eventUrl, utmOptions),
            };

            // verify facebook-sdk is loaded and then open facebook share dialog
            getSdk(locale).then(() => openUI(options));
        }
    }

    render() {
        return (
            <span
                className="eds-l-pad-right-1"
                data-spec="facebook-messenger-share-box-container"
            >
                <IconButton
                    iconType={<LogoMessenger />}
                    title={gettext('Share on Facebook Messenger')}
                    onClick={this._handleButtonClick.bind(this)}
                />
            </span>
        );
    }
}
