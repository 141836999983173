import React from 'react';

export const Image = ({ imageUrl, size }) => (
    <image
        xlinkHref={imageUrl}
        href={imageUrl}
        height={size}
        width={size}
        preserveAspectRatio="xMidYMin slice"
    />
);
