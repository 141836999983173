import * as React from 'react';

const MailFillChunkySvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 24 24" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 14.2L4 8.4V18h16V8.4l-8 5.8z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.1 6l7.9 5.8L19.9 6z"
        />
    </svg>
);

MailFillChunkySvg.displayName = 'MailFillChunkySvg';
export default MailFillChunkySvg;
