import { getWindowObject } from '@eb/feature-detection';

const FAKE_LOGGER_METHOD_MAP = {
    info: 'log',
    warning: 'warn',
    error: 'error',
};

const fakeLogger = {
    notify(message, options) {
        var methodName = FAKE_LOGGER_METHOD_MAP[options.severity];

        // eslint-disable-next-line no-console
        console[methodName](message, options.metaData);
    },
};

let initialFeatureName;

function _getLogger() {
    var logger = getWindowObject('bugsnagClient') || fakeLogger;

    // NOTE: The busgnagClient object would have been set up with a context property.
    // It doesn't exist on the fakeLogger
    initialFeatureName = logger.context;

    return logger;
}

function _notify(severity, message, metaData, callbacks) {
    _getLogger().notify(message, {
        metaData,
        severity,
        ...callbacks,
    });
}

export default {
    getOriginalPageName() {
        _getLogger();

        return initialFeatureName;
    },
    setPageName(featureName) {
        _getLogger().context = featureName;
    },
    info: _notify.bind(null, 'info'),
    warn: _notify.bind(null, 'warning'),
    error: _notify.bind(null, 'error'),
};
