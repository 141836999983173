import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { CARD_TYPES, GRID_TYPE } from '../constants';

import './FollowLoadingCard.scss';

export default class FollowLoadingCard extends React.Component {
    static propTypes = {
        style: PropTypes.oneOf(CARD_TYPES),
        isBackgroundHidden: PropTypes.bool,
    };

    static defaultProps = {
        style: GRID_TYPE,
    };

    render() {
        const { style, isBackgroundHidden } = this.props;

        const containerClasses = classnames(
            'consumer-loading-cards__container',
            `consumer-loading-cards__container consumer-loading-cards--${style}`,
        );

        const loadingCardClasses = classnames('consumer-org-loading-card', {
            'consumer-loading-card--no-bg': isBackgroundHidden,
        });

        return (
            <div className={containerClasses}>
                <div className={loadingCardClasses}>
                    <div className="consumer-org-loading-card__item consumer-org-loading-card__main" />
                    <div className="consumer-org-loading-card__aside">
                        <div className="consumer-org-loading-card__item" />
                        <div className="consumer-org-loading-card__item" />
                        <div className="consumer-org-loading-card__item" />
                        <div className="consumer-org-loading-card__item" />
                    </div>
                </div>
            </div>
        );
    }
}
