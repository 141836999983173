import * as React from 'react';

const ChevronUpChunkySvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 24 24" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17 13.8l-5-5-5 5 1.4 1.4 3.6-3.6 3.6 3.6z"
        />
    </svg>
);

ChevronUpChunkySvg.displayName = 'ChevronUpChunkySvg';
export default ChevronUpChunkySvg;
