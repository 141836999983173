import isNil from 'lodash/isNil';
import assignIn from 'lodash/assignIn';
import cookies from 'cookies-js';
import { getWindowObject } from '@eb/feature-detection';

const CHECKPOINT_COOKIE_NAME = 'janus_cp';
const EXPOSURE_COOKIE_NAME = 'janus_re';
const EXPIRATION_MINUTES = 5;
const AJAX_CHECKPOINT_URL = '/ajax/janus/checkpoint/';
const AJAX_EXPOSURE_URL = '/ajax/janus/exposure/';

function _isCachedHit(): boolean {
    const isVarnishHit = getWindowObject('eb') && getWindowObject('eb').VHit;

    return !isNil(isVarnishHit);
}

function _getUrlFormattedCheckpointNames(
    commaSeparatedCheckpointNames: string,
): string {
    return commaSeparatedCheckpointNames
        .split(',')
        .map((name) => `${name}=1`)
        .join('&');
}

function _getCSRFToken(): string {
    return cookies.get('csrftoken');
}

function _getCookieProperties(domain: string) {
    const expirationDate = new Date();

    expirationDate.setMinutes(expirationDate.getMinutes() + EXPIRATION_MINUTES);

    return {
        expires: expirationDate,
        // Add a leading dot to domain if needed
        domain: domain.charAt(0) !== '.' ? `.${domain}` : domain,
        path: '/',
    };
}

function _postAjaxRequest(
    endpoint: string,
    data: any,
): Promise<Response | void> {
    const fetch = getWindowObject('fetch');

    if (!fetch) {
        // equivalent of a noop but just return an empty promise
        return Promise.resolve();
    }

    const options = {
        method: 'POST',
        body: JSON.stringify(data),
        credentials: 'same-origin',
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRFToken': _getCSRFToken(),
        },
    };

    return fetch(endpoint, options);
}

/**
 * Set a cookie to request exposure to a given experiment.
 *
 * Janus middleware looks for this cookie on the next request, and
 * attempts to expose the user to the given experiment. If the
 * user is not enrolled in the experiment s/he will be enrolled.
 *
 * NB: The current implementation only supports exposing to a
 * single experiment per request.
 */
function _setRequestExposureCookie(experimentName: string, domain: string) {
    const cookieProperties = _getCookieProperties(domain);

    cookies.set(
        EXPOSURE_COOKIE_NAME,
        `v=1&${experimentName}=1`,
        cookieProperties,
    );
}

/**
 * Set a cookie to request that a checkpoint be logged.
 *
 * Janus middleware looks for this cookie on the next request, and
 * logs a checkpoint
 *
 * NB: The current implementation only supports logging a single
 * checkpoint
 */
function _setRequestCheckpointCookie(
    commaSeparatedCheckpointNames: string,
    domain: string,
) {
    const cookieProperties = _getCookieProperties(domain);
    const formattedcheckpointNames = _getUrlFormattedCheckpointNames(
        commaSeparatedCheckpointNames,
    );

    cookies.set(
        CHECKPOINT_COOKIE_NAME,
        `v=1&${formattedcheckpointNames}`,
        cookieProperties,
    );
}

/**
 * Expose user to the given experiment when s/he navigates to
 * the next page.
 */
export function exposeOnPageChange(
    experimentName: string,
    domain: string,
): void {
    if (_isCachedHit()) {
        return;
    }

    _setRequestExposureCookie(experimentName, domain);
}

/**
 * Logs a checkpoint when a user navigates to the next page.
 */
export function logCheckpointOnPageChange(
    commaSeparatedCheckpointNames: string,
    domain: string,
): void {
    if (_isCachedHit()) {
        return;
    }

    _setRequestCheckpointCookie(commaSeparatedCheckpointNames, domain);
}

/**
 * Expose user to the given experiment when not navigating to
 * another page.
 */
export function exposeViaAjax(
    experimentName: string,
    domain: string,
): Promise<Response | void> {
    if (_isCachedHit()) {
        return Promise.reject();
    }

    const data = {
        [experimentName]: '1',
    };

    _setRequestExposureCookie(experimentName, domain);

    return _postAjaxRequest(AJAX_EXPOSURE_URL, data);
}

/**
 * Logs a checkpoint when not navigating to another page.
 */
export function logCheckpointViaAjax<ExtendedAttributes extends object>(
    checkpointName: string,
    extendedAttributes: ExtendedAttributes,
): Promise<Response | void> {
    if (_isCachedHit()) {
        return Promise.reject();
    }

    return _postAjaxRequest(
        AJAX_CHECKPOINT_URL,
        assignIn({}, extendedAttributes, {
            checkpointName,
        }),
    );
}
