import * as React from 'react';

const VideoChunkySvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 24 24" {...props}>
        <path d="M19 4v1H5V4H3v16h2v-1h14v1h2V4h-2zm0 13H5V7h14v10z" />
        <path d="M10 15l5-3-5-3z" />
    </svg>
);

VideoChunkySvg.displayName = 'VideoChunkySvg';
export default VideoChunkySvg;
