import React from 'react';

// author: Aidan L.
import PropTypes from 'prop-types';

import pick from 'lodash/pick';
import classNames from 'classnames';
import { translationPropType } from '@eb/i18n';
import { TYPE_BUTTON } from './constants';
import Button from './Button';

import './toggleButton.scss';

const getTogglableValue = (pressedState, defaultValue, alternateValue) => {
    let value = defaultValue;

    if (pressedState && alternateValue) {
        value = alternateValue;
    }

    return value;
};

const getAriaPressedAttribute = (isPressed, childrenAlt) => {
    let attribute;

    // Sets the aria-pressed attribute in the absense of alternate children
    if (!childrenAlt) {
        attribute = isPressed;
    }
    // For instances where we pass in children and childrenAlt
    // Do not include aria-pressed attribute
    return attribute;
};

export default class ToggleButton extends React.PureComponent {
    static propTypes = {
        /**
         * All props from Button
         */
        ...Button.propTypes,
        /**
         * Required
         * Provides the default content of the button
         */
        children: PropTypes.node.isRequired,
        /**
         * Aria-label which corresponds to children for use in cases
         * where a text label is not visible on the screen
         */
        ariaLabel: translationPropType,
        /**
         * Default for whether or not button is pressed
         */
        isPressed: PropTypes.bool,
        /**
         * Callback function invoked when the button is pressed
         * (isPressed) => { }
         */
        onPress: PropTypes.func,
        /**
         * Optional
         * Provides a second child for alternating pressed states
         * For use as a button whose contents change when pressed
         * An example would be a play/pause button on a video player
         * childrenAlt could be some text or an icon
         */
        childrenAlt: PropTypes.node,
        /**
         * Optional
         * Aria-label which corresponds to childrenAlt for use in cases
         * where a text label is not visible on the screen
         */
        childrenAltAriaLabel: translationPropType,
    };

    static defaultProps = {
        isPressed: false,
    };

    constructor(props) {
        super(props);

        const { isPressed } = props;

        this.state = { isPressed };
    }

    componentWillReceiveProps({ isPressed: nextIsPressed }) {
        if (nextIsPressed === this.props.isPressed) {
            return;
        }

        this.setState({ isPressed: nextIsPressed });
    }

    _handlePress() {
        const { isPressed } = this.state;
        const { onPress } = this.props;

        this.setState({ isPressed: !isPressed });

        if (onPress) {
            onPress(!isPressed);
        }
    }

    render() {
        const {
            children,
            childrenAlt,
            ariaLabel,
            childrenAltAriaLabel,
        } = this.props;
        const { isPressed } = this.state;

        const buttonProps = pick(this.props, ...Object.keys(Button.propTypes));
        const className = classNames('eds-btn-toggle', {
            'eds-btn-toggle--is-pressed': isPressed,
        });
        const buttonBody = getTogglableValue(isPressed, children, childrenAlt);

        return (
            <div className={className} data-spec="eds-btn-toggle">
                <Button
                    {...buttonProps}
                    type={TYPE_BUTTON}
                    aria-pressed={getAriaPressedAttribute(
                        isPressed,
                        childrenAlt,
                    )}
                    aria-label={getTogglableValue(
                        isPressed,
                        ariaLabel,
                        childrenAltAriaLabel,
                    )}
                    onClick={this._handlePress.bind(this)}
                >
                    {buttonBody}
                </Button>
            </div>
        );
    }
}
