/* eslint-disable */
import Cookies from 'cookies-js';

import { getUrlPath } from '@eb/path-utils';
import { getWindowObject, HAS_WINDOW } from '@eb/feature-detection';

const COOKIE_NAME = 'ebUserAction';
const COOKIE_EXPIRATION_IN_MINUTES = 10;
const COOKIE_EXPIRATION = 60 * COOKIE_EXPIRATION_IN_MINUTES;
const COOKIE_DEFAULTS = {
    expires: COOKIE_EXPIRATION,
    secure: true,
};

/**
 * @typedef UserActionParameters
 * @type {Object}
 * @property {String} action      Action name (required)
 * @property {Number} yPos        YPosition of the user when they called the action
 * @property {String} origin      Origin URL
 * @property {Object} payload     Payload information
 *
 * @example
 * {
 *     action: 'setEventReminder',
 *     origin: '/e/my-first-event-in-music-tickets-55709092395',
 *     payload: {
 *         info: 'extra information',
 *     },
 *     yPos: 200,
 * }
 */

const getYPos = () =>
    getWindowObject(pageYOffset) || document.documentElement.scrollTop;

/**
 * Sets the user action cookie
 * @param  {UserActionParameters} parameters Parameters to set on the cookie
 * @return void
 */
export const setAction = ({
    action,
    payload = '',
    origin = getUrlPath(),
    yPos = getYPos(),
}) => {
    if (HAS_WINDOW) {
        Cookies.set(
            COOKIE_NAME,
            JSON.stringify({ action, origin, yPos, payload }),
            COOKIE_DEFAULTS,
        );
    }
};

/**
 * Gets the user action from the cookie
 * @return {UserActionParameters} Parameters extracted from the cookie
 */
export const getAction = () => {
    let rawValue = HAS_WINDOW ? Cookies.get(COOKIE_NAME) : false;

    return rawValue ? JSON.parse(rawValue) : false;
};

/**
 * Removes the user action cookie
 * @return void
 */
export const cleanAction = () => {
    if (HAS_WINDOW) {
        Cookies.expire(COOKIE_NAME);
    }
};
