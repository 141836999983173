import React from 'react';
import PropTypes from 'prop-types';
import { translationPropType } from '@eb/i18n';

import _ from 'lodash';

import EmailShare from './EmailShare';
import FacebookShare from './FacebookShare';
import FacebookMessengerShare from './FacebookMessengerShare';
import TwitterShare from './TwitterShare';
import {
    FACEBOOK_PROP_TYPE_SHAPE,
    UTM_OPTION_PROPTYPE,
    SHARE_ON_EMAIL,
    SHARE_ON_FACEBOOK,
    SHARE_ON_FACEBOOK_MESSENGER,
    SHARE_ON_TWITTER,
    SHARE_TYPES,
    DEFAULT_UTM_OPTIONS,
    UTM_SHARE_TYPE_MAP,
} from './constants';

const SHARE_TYPE_COMPONENTS_MAP = {
    [SHARE_ON_EMAIL]: EmailShare,
    [SHARE_ON_FACEBOOK]: FacebookShare,
    [SHARE_ON_FACEBOOK_MESSENGER]: FacebookMessengerShare,
    [SHARE_ON_TWITTER]: TwitterShare,
};

const _getShareComponent = (
    shareTypes,
    mergedUtmOptions,
    options,
    shareType,
) => {
    let component = null;

    // if current share type is not contained on the wanted share types
    // we will skip it and not build it
    if (!shareTypes || shareTypes.includes(shareType)) {
        let boundOnClick;
        const ShareComponent = SHARE_TYPE_COMPONENTS_MAP[shareType];

        const { onClick, ...shareOptions } = options;

        const typeSpecificUtmOptions = {
            ...mergedUtmOptions,
            ...UTM_SHARE_TYPE_MAP[shareType],
        };

        if (onClick) {
            boundOnClick = onClick.bind(null, shareType);
        }

        component = (
            <ShareComponent
                key={shareType}
                onClick={boundOnClick}
                utmOptions={typeSpecificUtmOptions}
                {...shareOptions}
            />
        );
    }

    return component;
};

const _generateShareComponents = (shareTypes, mergedUtmOptions, options = {}) =>
    _.chain(SHARE_TYPES)
        // transform the share type to a share component
        .map((shareType) =>
            _getShareComponent(
                shareTypes,
                mergedUtmOptions,
                options,
                shareType,
            ),
        )
        // eliminate the null values
        .compact()
        .value();

export default class ShareBox extends React.Component {
    static propTypes = {
        /**
         * Event's id
         */
        eventId: PropTypes.string.isRequired,

        /**
         * Event's name
         */
        eventName: PropTypes.string.isRequired,

        /**
         * Event's url
         */
        eventUrl: PropTypes.string.isRequired,

        /**
         * Facebook options
         */
        facebookOptions: FACEBOOK_PROP_TYPE_SHAPE.isRequired,

        /**
         * Server's url
         */
        serverUrl: PropTypes.string.isRequired,

        /**
         * Array containig all the shares type to show. If not provided, it will default to all
         */
        shareTypes: PropTypes.arrayOf(PropTypes.oneOf(SHARE_TYPES)),

        /**
         * Twitter handle
         */
        twitterHandle: PropTypes.string.isRequired,

        /**
         * Boolean prop to know if we're on a mobile device or not
         */
        isMobile: PropTypes.bool,

        /**
         * Function that onClick of one of the share options, passes
         * back an id of the clicked option
         */
        onClick: PropTypes.func,

        /**
         * Object that allows you to customize elements
         * of the UTM parameters passed via share
         */
        utmOptions: UTM_OPTION_PROPTYPE,

        /**
         * Configurable copy options
         * for prefilling the email
         */
        emailOptions: PropTypes.shape({
            //Defaults to the formatted url of the shared entity
            bodyCopy: translationPropType,
            //Defaults to "You're invited to {eventName}"
            subjectCopy: translationPropType,
        }),
    };

    render() {
        const { shareTypes, utmOptions, ...options } = this.props;

        const mergedUtmOptions = {
            ...DEFAULT_UTM_OPTIONS,
            ...utmOptions,
        };

        const shareComponents = _generateShareComponents(
            shareTypes,
            mergedUtmOptions,
            options,
        );

        return <div data-spec="share-box-container">{shareComponents}</div>;
    }
}
