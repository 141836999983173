import capitalize from 'lodash/capitalize';
import first from 'lodash/first';
import last from 'lodash/last';

const X_PERCENTILE = 0.5;
const Y_PERCENTILE = 0.6;
const FONT_SIZE_PERCENTILE = 0.3;

export const calculateTextProperties = (size) => ({
    x: size * X_PERCENTILE,
    y: size * Y_PERCENTILE,
    fontSize: size * FONT_SIZE_PERCENTILE,
});

/**
 * Generate the proper value of initials given text and the maxChars.
 *
 * If there's a value for text that is not a string or undefined, we'll return an error.
 *
 * @param  {string}  text               The text by which we generate the initials
 * @param  {integer} maxChars           The maximum number of characters to return
 *
 * If the string passed to `text` has a space delimiter we display first character
 * of first part of the string and the first character of the last part of the string,
 * ignoring any other parts if more that two are passed ex `John Smith` returns “JS”
 * and `John Von Smith` returns “JS” as well.
 * If only one continuous string is passed to `text` such as in the event of an email address,
 * we will display the first two characters of the string as Upper and Lowercase characters.
 * Ex: `aidan@eventbrite.com` returns “Ai” or `Admin` as “Ad”
 * We also offer the ability to set a limit on the number of characters we want to display as initials.
 * `maxChars` will display the first character of each word in a multi word, space delimited string up
 * until the `maxChars` is reached.
 * More examples:
 *              'ryan', 1 => R
 *              'aidan, 2 => Ai
 *              'kaylie kwon', 2 => KK
 *              'extra long name from elsewhere', 3 => ELN
 *
 * @return {string}
 */
export const generateInitials = (text, maxChars = 2) => {
    if (typeof text !== 'string') {
        throw new Error(
            `Invalid argument. Expected type of **${text}** to be 'string'. Instead got ${typeof text}.`,
        );
    }

    let initials = '';
    const tokens = text.split(' ');

    // If we get a single token, then take up to maxChars from the begining of that token and capitalize first character only
    if (tokens.length === 1) {
        initials = capitalize(text.slice(0, maxChars));
        // If we get multiple tokens and maxChars is 2 then take then pick the first and last tokens and uppercase both
    } else if (tokens.length > 1 && maxChars === 2) {
        initials = (
            first(tokens).charAt(0) + last(tokens).charAt(0)
        ).toLocaleUpperCase();
        // For cases where we have multiple tokens and maxChars is greater or less than 2
        // Take the first character from up to (n) tokens where (n) is maxChars
    } else if (tokens.length > 1 && maxChars) {
        tokens.forEach((segment, index) => {
            if (index <= maxChars - 1) {
                initials += segment.charAt(0);
            }
        });
        initials.toLocaleUpperCase();
    }

    return initials;
};
