import filter from 'lodash/filter';
import find from 'lodash/find';
import get from 'lodash/get';

import { deepKeysToCamel } from '@eb/transformation-utils';

import {
    getUserCollections as getUserCollectionsApi,
    createCollection as createCollectionApi,
    editCollection as editCollectionApi,
    addToCollection as addToCollectionApi,
    removeFromCollection as removeFromCollectionApi,
    deleteCollection as deleteCollectionApi,
    getFollowedCollections as getFollowedCollectionsApi,
    followCollection as followCollectionApi,
    unfollowCollection as unfollowCollectionApi,
    getCollectionsForPlace as getCollectionsForPlaceApi,
    getCollectionsForOnline as getCollectionsForOnlineApi,
} from './api';

import {
    SET_COLLECTIONS,
    UPDATE_COLLECTION,
    UPDATE_COLLECTION_EVENTS,
    DELETE_COLLECTION,
    CLEAR_COLLECTIONS,
} from './actionTypes';

import {
    trackAddToCollection,
    trackCreateCollection,
    trackMaxEventAdded,
    trackRemoveFromCollection,
} from './analytics';

import { transformCollectionResponse } from './utils';

export const clearCollections = () => ({
    type: CLEAR_COLLECTIONS,
});

export const setCollections = (data, customActionType) => ({
    type: customActionType || SET_COLLECTIONS,
    payload: data,
});

export const updateCollection = (data) => ({
    type: UPDATE_COLLECTION,
    payload: data,
});

export const updateCollectionEvents = (data) => ({
    type: UPDATE_COLLECTION_EVENTS,
    payload: data,
});

export const deleteCollection = (id) => ({
    type: DELETE_COLLECTION,
    payload: id,
});

export const getCollections = () => (dispatch, getState) => {
    const {
        user: { publicId },
    } = getState();

    return getUserCollectionsApi(publicId).then((response) =>
        dispatch(setCollections(transformCollectionResponse(response))),
    );
};

export const getCollectionsForPlace = ({
    placeId,
    collectionsToShow,
    customActionType,
}) => (dispatch) => {
    const queryCallback = (response) => {
        let filteredEntities = [];

        filteredEntities = filter(response.collections, (collection) => {
            return find(
                collectionsToShow,
                (collectionToShow) => collectionToShow === collection.id,
            );
        });
        response.collections = filteredEntities;

        const {
            entities,
            hasMoreItems,
            continuation,
        } = transformCollectionResponse(response);

        dispatch(
            setCollections(
                {
                    entities,
                    hasMoreForPlace: hasMoreItems,
                    continuationForPlace: continuation,
                },
                customActionType,
            ),
        );
    };
    if (collectionsToShow) {
        if (placeId) {
            return getCollectionsForPlaceApi(placeId).then(
                queryCallback,
                /**
                 * None calls were catching the errors and we don't have something to
                 * do when the api fails right now besides failing so we silence the
                 * error.
                 */
                () => {},
            );
        } else {
            return getCollectionsForOnlineApi().then(
                queryCallback,
                /**
                 * None calls were catching the errors and we don't have something to
                 * do when the api fails right now besides failing so we silence the
                 * error.
                 */
                () => {},
            );
        }
    }
};

export const getFollowedCollections = (userId, continuation = null) => (
    dispatch,
) =>
    getFollowedCollectionsApi(userId, continuation).then((response) => {
        const {
            entities,
            hasMoreItems,
            continuation,
        } = transformCollectionResponse(response);

        dispatch(
            setCollections({
                entities,
                hasMoreFollowed: hasMoreItems,
                continuationFollowed: continuation,
            }),
        );
    });

// At the moment client side only supports a single event to be hydrated
// into a created collection at a time. Backend supports multiple, but
// update will be needed to reducer code to support that.
export const createCollection = (category) => (
    collectionName,
    eventIds = [],
) => (dispatch, getState) => {
    const { gaSettings } = getState();

    return createCollectionApi(collectionName, eventIds).then(
        ({ id, ...collectionData }) => {
            dispatch(
                setCollections({
                    entities: {
                        [id]: {
                            id,
                            ...deepKeysToCamel(collectionData),
                        },
                    },
                }),
            );

            if (eventIds.length === 1) {
                dispatch(
                    updateCollectionEvents({
                        eventId: eventIds[0],
                        shouldAdd: true,
                        collectionId: id,
                    }),
                );
            }
            if (category && gaSettings) {
                dispatch(trackCreateCollection(id, category));
            }
            return id;
        },
    );
};

export const editCollection = (id, name) => (dispatch) =>
    editCollectionApi(id, name).then((response) =>
        dispatch(updateCollection(deepKeysToCamel(response))),
    );

export const deleteCollectionThunk = (id) => (dispatch) =>
    deleteCollectionApi(id).then(() => dispatch(deleteCollection(id)));

export const addOrRemoveEventToCollection = (category) => ({
    eventId,
    onSave,
    isChecked,
    value,
}) => (dispatch, getState) => {
    const { gaSettings } = getState();
    //TODO: Depending on how we handle the save/unsave event in relation to collections
    //this could change. For now, maintaining an abstracted version of the previous
    //behavior where this action will call the associated onSave action when appropriate
    //If approach varies we will need to

    if (value === 'saved') {
        return dispatch(onSave(eventId, isChecked));
    }
    let apiCall = removeFromCollectionApi;

    if (isChecked) {
        apiCall = addToCollectionApi;
    }

    //Optimistic state update
    dispatch(
        updateCollectionEvents({
            eventId,
            collectionId: value,
            shouldAdd: isChecked,
        }),
    );

    return apiCall(eventId, value)
        .catch((error) => {
            if (error === 'REACH_MAX_EVENTS') {
                dispatch(trackMaxEventAdded(value, category));
            }

            dispatch(
                updateCollectionEvents({
                    eventId,
                    collectionId: value,
                    shouldAdd: !isChecked,
                }),
            );

            throw error;
        })
        .then(() => {
            if (category && gaSettings) {
                if (isChecked) {
                    dispatch(trackAddToCollection(eventId, category));
                } else {
                    dispatch(trackRemoveFromCollection(eventId, category));
                }
            }
        });
};

export const followCollection = (collectionId) => (dispatch, getState) => {
    const { collections: { entities = {} } = {} } = getState();

    return followCollectionApi(collectionId).then(() =>
        dispatch(
            updateCollection({
                id: collectionId,
                followStatus: {
                    ...get(entities, [collectionId, 'followStatus'], {}),
                    followedByYou: true,
                    /* Chance may be incorrect relative to server but
                            updating to provide user with immediate feedback on action.*/
                    numFollowers:
                        get(
                            entities,
                            [collectionId, 'followStatus', 'numFollowers'],
                            0,
                        ) + 1,
                },
            }),
        ),
    );
};

export const unfollowCollection = (collectionId) => (dispatch, getState) => {
    const { collections: { entities = {} } = {} } = getState();

    return unfollowCollectionApi(collectionId).then(() =>
        dispatch(
            updateCollection({
                id: collectionId,
                followStatus: {
                    ...get(entities, [collectionId, 'followStatus'], {}),
                    followedByYou: false,
                    /* Chance may be incorrect relative to server but
                            updating to provide user with immediate feedback on action.*/
                    numFollowers:
                        get(
                            entities,
                            [collectionId, 'followStatus', 'numFollowers'],
                            1,
                        ) - 1,
                },
            }),
        ),
    );
};
